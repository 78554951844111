import { Component, OnInit, Input } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { CopyText } from 'src/app/lib/copy/copy';

export enum MemoType {
  Memo,
  Content,
}

@Component({
  selector: 'app-memo-popup',
  templateUrl: './memo-popup.component.html',
  styleUrls: ['./memo-popup.component.scss']
})
export class MemoPopupComponent implements OnInit, Popup {

  constructor() { }
  @Input() data;
  title: string;
  message: string;

  ngOnInit() {
    this.title = this.data.title === MemoType.Memo ? 'Memo' : 'Content';
    this.message = this.data.message;
  }

  copy() {
    CopyText.copy(this.message);
  }

  close() {
    this.data.close();
  }
}
