import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd, Event } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { ExamCommentResponse, ExamComment, ExamSeries } from 'src/app/type/exam';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/service/user/user.service';
import { combineLatest, Subscription } from 'rxjs';
import { ExamService } from 'src/app/core/service/exam/exam.service';
import { ShareExam } from 'src/app/type/share';
import { zoomInAnimation } from 'src/app/animation/common';
import { map, filter, take, tap } from 'rxjs/operators';
import { ShareLinkPopupComponent, ShareType } from 'src/app/popup/share-link-popup/share-link-popup.component';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';
import { UserData } from 'src/app/type/user';
import { debounce, debounceTime } from 'rxjs/operators';
import { OrganizationResponse, Organization, Pacs } from 'src/app/type/organization';
import { Validators,  FormBuilder, FormArray} from '@angular/forms';
import { CustomValidator } from 'src/app/core/validator/customValidator';
import { exception } from 'console';

interface ExamSeriesItem extends ExamSeries {
  img: string;
}

interface CommentData {
  name: string;
  comment: string;
  dateTime: string;
  status: string;
}

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  animations: [zoomInAnimation],
})
export class DetailComponent implements OnInit, OnDestroy {
  archiveId: number;
  seriesId: string;
  examId: string;
  comment: string;
  info = {
    examDate: '-',
    id: '-',
    sex: '-',
    name: '-',
    birthday: '-',
  };
  series = {
    date: '-',
    physician: '-'
  };
  organizForm = this.formBuild.group({   
    pacsList: this.formBuild.array([]),
   
  });
  examTData: CommentData[] = [];
  imageData: CommentData[] = [];
  seriesList: ExamSeriesItem[] = [];
  overlayList: string[];
  filename: string;
  seriesType: string;
  imagesString = '';
  showTab = 'exam';
  previewSeriesId: string;
  getDataSubscription: Subscription;
  getContentSubscription: Subscription;
  backUrl: string;
  userData: UserData;
  organizationID : number; 
  get commentTData(): CommentData[] {
    return this.showTab === 'exam' ? this.examTData : this.imageData;
  }

  get seriesIndex() {
    return this.seriesList.findIndex(series => this.seriesId === series.seriesId) + 1;
  }
  get pacsList(): FormArray {
    return this.organizForm.get('pacsList') as FormArray;
  }

  
  private userSubject: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private userService: UserService,
    private examService: ExamService,
    private router: Router,
    private popupService: PopupService,
    private alertSerivce: AlertService,
    private formBuild: FormBuilder,
  ) {
    
    const getUserData = this.userService.getUserData();
    const getActiveRoute = this.activatedRoute.params;
    const getSeries = this.examService.getSeries();
    this.getDataSubscription = combineLatest([getSeries, getUserData]).subscribe(([examSeries, userData]) => {
      if (examSeries && userData.token) {
        this.getSeries(examSeries, userData.token);
      }
    });
    
    getActiveRoute.subscribe((params) => {
      this.archiveId = parseInt(params.archiveId, 10);
      this.examId = params.examId;
      this.getContent();
    });

    this.userSubject = this.userService.getUserData()
      .pipe(
        debounceTime(1000),
      )
      .subscribe((userData: UserData) => {
        if (userData.organizationId) {
          this.organizationID = userData.organizationId;
          console.log("Sean check this.organizationID",this.organizationID);
          //this.userData = userData;
          //this.getData(this.organizationID);
        }
      });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.getDataSubscription.unsubscribe();
    this.getContentSubscription.unsubscribe();
  }

  sendComment() {
    if (this.comment) {
      if (this.showTab === 'exam') {
        this.sendExamComment(this.comment);
      } else {
        this.sendSeriesComment(this.comment);
      }
    }
  }

  getComment() {
    if (this.showTab === 'exam') {
      this.getExamComment(this.archiveId, this.examId);
    } else {
      this.getSeriesComment(this.archiveId, this.seriesId);
    }
  }

  preview(seriesId: string) {
    if (this.seriesId) {
      this.previewSeriesId = undefined;
      this.navigateTo(seriesId);
    } else {
      if (this.previewSeriesId === seriesId) {
        this.navigateTo(seriesId);
      } else {
        this.showTab = 'image';
        this.previewSeriesId = seriesId;
        this.getSeriesComment(this.archiveId, seriesId);
      }
    }
  }

  shareExam() {
    this.httpService.getShareExamKey(this.archiveId, this.examId).subscribe((res: ShareExam) => {
      this.popupService.open(
        ShareLinkPopupComponent,
        {
          link: location.protocol + '//' + location.hostname + '/s/' + res.key,
          type: ShareType.Exam,
        }
      );
    });
  }

  shareImage() {
    this.httpService.getShareExamKey(this.archiveId, this.examId).subscribe((res: ShareExam) => {
      this.popupService.open(
        ShareLinkPopupComponent,
        {
          link: location.protocol + '//' + location.hostname + '/si/' + res.key + '/' + this.seriesId,
          type: ShareType.Image,
        }
      );
    });
  }

  navigateTo(seriesId: string) {
    if (this.seriesId) {
      this.querySeries(seriesId);
    } else {
      const link = this.backUrl + '/' + this.archiveId + '/' + this.examId + '/seriesId';
      this.examService.setSeriesId(seriesId);
      this.router.navigate([link]);
    }
  }

  setTab(tabIndex: string) {
    this.showTab = tabIndex;
    this.getComment();
  }

  toList() {
    this.router.navigateByUrl(this.backUrl + '/' + this.archiveId + '/' + this.examId);
  }

  private getContent() {
    const getSeriesId = this.examService.getSeriesId().pipe(take(1));
    const getSeriesStatus = this.router.events.pipe(
      filter((event: Event) => event instanceof ActivationEnd && event.snapshot.children.length === 0),
      tap((event: ActivatedRoute) => this.backUrl = event.snapshot.data.backTo),
      map((event: ActivationEnd): boolean => event.snapshot.data.getSeries),
      take(1)
    );
    this.getContentSubscription = combineLatest([getSeriesId, getSeriesStatus]).subscribe(([seriesId, getSeriesResponse]) => {
      this.examService.querySeriesList(this.archiveId, this.examId);
      if (getSeriesResponse && seriesId) {
        this.showTab = 'image';
        this.querySeries(seriesId);
      } else {
        this.showTab = 'exam';
        this.getExamComment(this.archiveId, this.examId);
      }
      this.getSeriesList();
    });
  }

  private sendExamComment(comment: string) {
    this.httpService.sendExamComment(this.archiveId, this.examId, { comment }).subscribe((res) => {
      this.comment = '';
      this.getComment();
    });
  }

  private sendSeriesComment(comment: string) {
    this.httpService.sendExamSeriesComment(this.archiveId, this.examId, this.seriesId, { comment }).subscribe(() => {
      this.comment = '';
      this.getComment();
    });
  }

  querySeries(seriesId: string) {
    if (this.seriesId !== seriesId) {
      this.seriesId = seriesId;
      this.examService.querySeries(this.archiveId, seriesId);
      this.getSeriesComment(this.archiveId, seriesId);
    }
  }

  private getSeries(res: ExamSeries, token: string) {
    this.series = {
      date: res.dateTime.format(environment.dateTime),
      physician: res.physician,
    };
    this.filename = res.combinedPath;
    this.seriesType = res.imageType;
    this.overlayList = [environment.apiUrl + 'overlays/' + res.seriesPath + '?token=' + token];
    this.overlayList.push(environment.apiUrl + 'overlays/' + res.combinedPath + '?token=' + token);
  }

  private getExamComment(archiveId: number, examId: string) {
    this.httpService.getExamComment(archiveId, examId).subscribe((res: ExamCommentResponse) => {
      this.examTData = res.list
        .sort((a, b) => a.dateTime.isAfter(b.dateTime) ? 1 : -1)
        .map((comment: ExamComment): CommentData => ({
          dateTime: comment.dateTime.format(environment.dateTime),
          comment: comment.comment,
          name: comment.userId,
          status: comment.status,
        }));
    });
  }

  private getSeriesComment(archiveId: number, seriesId: string) {
    this.httpService.getExamSeriesComment(archiveId, seriesId)
      .subscribe((res: ExamCommentResponse) => {
        this.imageData = res.list
          .sort((a, b) => a.dateTime.isAfter(b.dateTime) ? 1 : -1)
          .map((comment: ExamComment): CommentData => ({
            dateTime: comment.dateTime.format(environment.dateTime),
            comment: comment.comment,
            name: comment.userId,
            status: comment.status,
          }));

      });
  }

  private getSeriesList() {
    this.examService.getSeriesList()
      .subscribe((seriesList: ExamSeriesItem[]) => {
        this.seriesList = seriesList || [];
      });
  }

  // private AlertDICOMInfor()
  // {
  //   const data: Alert = {
      
  //     message: 'The function is in implementing ......... ',
  //     clickText: 'OK',
  //     click: () => {
  //       this.alertSerivce.cleanAlert();
  //     },
      
  //   };
  //   this.alertSerivce.setAlert(data, true);
  // }

  // private getData(organizationId: number = this.organizationID) {
  //   const search = { organizationId };
  //   this.httpService.getOrganizations(search)
  //     .subscribe((res: OrganizationResponse) => {
  //       const data = res.list[0];


        
  //       //this.getPacsData(organizationId, data.pacsId1, data.pacsId2);
  //     });
  // }
  

  
  
}
