import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { UserService } from 'src/app/core/service/user/user.service';
import { Router } from '@angular/router';
import { RouterConfig, RouteConfig, getHome } from 'src/app/router/router-config';
import { UserLevel, UserData } from 'src/app/type/user';
import { environment } from 'src/environments/environment';

interface Menu extends RouteConfig {
  subMenu?: Menu[];
  onClick?: () => void;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  level: UserLevel;
  userData: UserData;
  // username = '';
  // version = environment.version;
  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private router: Router,
  ) {
    userService.getUserData().subscribe((userData: UserData) => {
      this.level = userData.level || UserLevel.Normal;
      this.userData = userData;
      // this.username = userData.username;
    });
  }
  active: Menu;

  readonly menuList: Menu[] = [
    RouterConfig.Organization,
    RouterConfig.MobileApp,
    RouterConfig.Firmware,
    RouterConfig.Feedback,
    // RouterConfig.BackupAndRestore,
    RouterConfig.Archive,
    RouterConfig.User,
    RouterConfig.Device,
    RouterConfig.OrganizationSetting,
    RouterConfig.Exam,
    RouterConfig.MessageCenter,
    // {
    //   name: 'User',
    //   uri: '/m/user',
    //   subMenu: [
    //     {
    //       uri: '/m/user',
    //       name: 'user',
    //     },
    //   ],
    // },
  ];
  ngOnInit() {
  }

  // menuOnClick(menu: Menu) {
  //   this.active = (this.active === menu) ? null : menu;
  //   // if (menu.onClick) {
  //   //   menu.onClick();
  //   // }
  //   // console.log(menu)
  //   if (menu.totalPath) {
  //     this.router.navigate([menu.totalPath]);
  //   }
  // }

  canShow(menu: Menu) {
    if (menu.data.acccessLevel) {
      return menu.data.acccessLevel === this.level;
    } else {
      return true;
    }
  }

  toHomePage() {
    const homeRoute = getHome(this.userData);
    this.router.navigate([homeRoute]);
  }

  // logout() {
  //   this.httpService.logout().subscribe();
  //   this.userService.clean();
  //   this.router.navigate(['/login']);
  // }
}
