import { Component, OnInit, forwardRef, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileUploadInputComponent),
  multi: true
};

export interface FileData {
  preview: string | ArrayBuffer;
  file: File;
}

@Component({
  selector: 'app-file-upload-input',
  templateUrl: './file-upload-input.component.html',
  styleUrls: ['./file-upload-input.component.scss'],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FileUploadInputComponent implements ControlValueAccessor {

  @Input() placeholder = '';
  @Input() title = 'File';
  @Input() min = 0;
  @Input() error?: string;
  @Input() accept: string;
  @Input() autocomplete = 'off';
  @Input() disabled: boolean;
  @Output() changeFile = new EventEmitter<FileData>();

  myValue: any;
  focus = false;
  onChange: (value) => {};
  onTouched: (e) => {};
  fileName = '';

  @HostListener('click', ['$event']) onblur(e: MouseEvent) {
    this.onTouched(e);
  }

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const value = event && event.item(0);
    this.onChange(value);
  }

  get value() {
    return this.myValue;
  }

  set value(value: string) {
    this.myValue = value;
    this.notifyValueChange();
  }


  constructor() {
    this.myValue = '';
  }

  notifyValueChange() {
    if (this.onChange) {
      this.onChange(this.myValue);
    }
  }

  change(event) {
    const files = event.target.files as File[];
    const reader = new FileReader();
    if (files && files.length) {
      this.fileName = files[0].name;
      reader.readAsDataURL(files[0]);
      reader.onload = (file: Event): any => {
        this.changeFile.emit({ preview: reader.result, file: files[0] });
      };
    } else {
      this.fileName = '';
      this.changeFile.emit({ preview: undefined, file: undefined });
    }
  }

  writeValue(value: any): void {
    this.myValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
