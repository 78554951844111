import { Injectable } from '@angular/core';
import { Storage } from 'src/app/lib/storage/storage';
import { BehaviorSubject } from 'rxjs';

interface SearchHistory {
  [keyName: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class SearchHistoryService {
  private key = 'search';
  private searchHistoryStorage = new Storage<SearchHistory>(this.key);
  private searchHistory: SearchHistory = {};
  constructor() {
    this.searchHistory = this.getHistory() || {};
  }

  getHistory(): SearchHistory {
    return this.searchHistoryStorage.get();
  }

  getParam(): any {
    const path = window.document.location.href;
    return this.searchHistory[path];
  }

  setHistory(param: any): SearchHistory {
    const path = window.document.location.href;
    this.searchHistory[path] = param;
    this.searchHistoryStorage.set(this.searchHistory);
    return this.searchHistory;
  }

  setHoverItem(searchItem: string) {
    const path = window.document.location.href;
    this.searchHistory[path].searchItem = searchItem;
    this.searchHistoryStorage.set(this.searchHistory);
    return this.searchHistory;
  }

  getHoverItem() {
    const path = window.document.location.href;
    const param = this.searchHistory[path];
    return param.searchItem;
  }

  cleamParamByPath(path: string): SearchHistory {
    delete this.searchHistory[path];
    this.searchHistoryStorage.set(this.searchHistory);
    return this.searchHistory;
  }

  cleanAll() {
    this.searchHistory = [];
    this.searchHistoryStorage.remove();
  }
}
