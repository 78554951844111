import { Component, Output, EventEmitter, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Output() onclick = new EventEmitter();
  @Input() disable = false;
  @Input() type = 'button';

  @HostBinding('attr.disable')
  get isDisable() {
    return this.disable;
  }

  click(event) {
    this.onclick.emit(event);
  }
}
