import { trigger, transition, animate, style, query, group } from '@angular/animations';

export const routeAnimation =
  trigger('routeAnimation', [
    transition('* => *', [
      query(':leave',
        style({
          transform: 'translateY(0)',
          position: 'absolute',
          opacity: '1'
        }),
        { optional: true }
      ),
      query(':enter',
        style({
          transform: 'translateY(1%)',
          position: 'absolute',
          opacity: '0'
        }),
        { optional: true }
      ),

      group([
        query(':leave',
          animate('.3s ease-in-out',
            style({
              transform: 'translateY(1%)',
              opacity: '0'
            })
          ),
          { optional: true }
        ),
        query(':enter',
          animate('.3s ease-in-out',
            style({
              transform: 'translateY(0)',
              opacity: '1'
            })
          ),
          { optional: true }
        )
      ])
    ])
  ]);

export const routeLogAnimation =
  trigger('routeLogAnimation', [
    transition('* => *', [
      query(':leave',
        style({
          position: 'absolute',
          opacity: '1'
        }),
        { optional: true }
      ),
      query(':enter',
        style({
          position: 'absolute',
          opacity: '0'
        }),
        { optional: true }
      ),

      group([
        query(':leave',
          animate('.3s ease-in-out',
            style({
              opacity: '0'
            })
          ),
          { optional: true }
        ),
        query(':enter',
          animate('.3s ease-in-out',
            style({
              opacity: '1'
            })
          ),
          { optional: true }
        )
      ])
    ])
  ]);

