import { Component, OnInit, Input } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { CopyText } from 'src/app/lib/copy/copy';
import { UserService } from '../../core/service/user/user.service';

export enum ShareType {
  Image = 'image/video',
  Exam = 'exam',
}

@Component({
  selector: 'app-share-link-popup',
  templateUrl: './share-link-popup.component.html',
  styleUrls: ['./share-link-popup.component.scss']
})
export class ShareLinkPopupComponent implements Popup, OnInit {

  constructor(
    private userService: UserService,
  ) { }
  @Input() data;
  link: string;
  type: ShareType;
  examShareLinkTimeout: number;

  ngOnInit() {
    this.link = this.data.link;
    this.type = this.data.type;
    this.userService.getUserData().subscribe(userData => {
      this.examShareLinkTimeout = userData.examShareLinkTimeout;
    });
  }

  copy() {
    CopyText.copy(this.link);
  }

  close() {
    this.data.close();
  }

}
