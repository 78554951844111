import { Component, OnInit, Input } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { SelectOption } from 'src/app/component/select/select.component';
import { DeviceStatus, DeviceResponse } from 'src/app/type/device';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { DeviceLogResponse, DeviceLog, DeviceLogStatus } from 'src/app/type/deviceLog';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/service/user/user.service';
import { UserData } from 'src/app/type/user';
import { ReloadServiceService } from '../../core/service/reload-service/reload-service.service';

interface LogTData {
  id: number;
  type: string;
  content: string;
  createdAt: string;
  name: string;
}

@Component({
  selector: 'app-device-editor-popup',
  templateUrl: './device-editor-popup.component.html',
  styleUrls: ['./device-editor-popup.component.scss']
})
export class DeviceEditorPopupComponent implements OnInit, Popup {
  @Input() data;
  readonly statusOption: SelectOption[] = [
    {
      text: DeviceStatus.Enable.text,
      value: DeviceStatus.Enable.value,
    },
    {
      text: DeviceStatus.Disable.text,
      value: DeviceStatus.Disable.value,
    },
  ];
  id: number;
  deviceForm = this.formBuild.group({
    firmwareVersion: [''],
    hardwareVersion: [''],
    macAddress: [''],
    modelName: ['', Validators.required],
    serialNumber: ['', Validators.required],
    status: [this.statusOption[0], Validators.required],
  });
  logTData: LogTData[];
  isSubmit = false;
  readonly apiPath = environment.apiUrl;
  readonly DeviceLogStatusEnum = DeviceLogStatus;
  token: string;
  sort = 'serialNumber,DESC';
  constructor(
    private formBuild: FormBuilder,
    private httpService: HttpService,
    private router: Router,
    private userDataService: UserService,
    private reloadService: ReloadServiceService,
  ) { }

  ngOnInit() {
    this.id = this.data.id;
    this.getData(this.id);
    this.getDeviceLog(this.id);
    this.getUserData();
  }

  getData(deviceId) {
    const search = {
      deviceId,
    };
    this.httpService.getDevice(search)
      .subscribe((res: DeviceResponse) => {
        const device = res.list[0];
        const status = this.statusOption.find(statusItem => statusItem.value === device.status.value);
        this.deviceForm.patchValue({
          firmwareVersion: device.firmwareVersion,
          hardwareVersion: device.hardwareVersion,
          macAddress: device.macAddress,
          serialNumber: device.serialNumber,
          status,
          modelName: device.model.modelName,
        });
      });
  }

  getDeviceLog(deviceId: number) {
    this.httpService.getDeviceLog({ deviceId, all: true, sort: this.sort })
      .subscribe((res: DeviceLogResponse) => {
        this.logTData = res.list.map((listItem: DeviceLog): LogTData => ({
          id: listItem.id,
          content: listItem.content,
          createdAt: listItem.createdAt.format(environment.dateTime),
          type: listItem.type,
          name: listItem.name,
        }));
      });
  }

  getErrorStatus(column: string): boolean {
    const controls = this.deviceForm.get(column);
    return (this.isSubmit || controls.touched) && !controls.valid;
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.getDeviceLog(this.id);
  }

  save() {
    this.isSubmit = true;
    if (this.deviceForm.valid) {
      const data = {
        ...this.deviceForm.value,
        status: this.deviceForm.value.status.value,
      };
      this.httpService.updateDevice(data, this.id)
        .subscribe((res) => {
          this.reloadService.setReload();
          this.close();
        });
    }
  }

  close() {
    this.data.close();
  }

  private getUserData() {
    this.userDataService.getUserData().subscribe((userData: UserData) => {
      this.token = userData.token;
    });
  }
}
