import { Component, OnInit, Input } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SelectOption } from 'src/app/component/select/select.component';
import { DeviceStatus } from 'src/app/type/device';
import { HttpService } from 'src/app/core/http/http.service';
import { Model, ModelStatus } from 'src/app/type/model';
import { Router } from '@angular/router';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';
import { ModelStatusService } from 'src/app/core/service/model/model-status.service';

@Component({
  selector: 'app-model-create-popup',
  templateUrl: './model-create-popup.component.html',
  styleUrls: ['./model-create-popup.component.scss']
})
export class ModelCreatePopupComponent implements OnInit, Popup {

  @Input() data;
  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private router: Router,
    private modelStatusService: ModelStatusService,
  ) { }
  readonly statusOption: SelectOption[] = [
    {
      text: ModelStatus.Enable.text,
      value: ModelStatus.Enable.value,
    },
    {
      text: ModelStatus.Disable.text,
      value: ModelStatus.Disable.value,
    },
  ];
  modelForm = this.formBuilder.group({
    modelName: ['', Validators.required],
    memo: [''],
    status: [this.statusOption[0]],
  });
  isSubmit = false;
  model: Model;

  ngOnInit() {
    this.model = this.data.model;
    if (this.model) {
      const status = this.model.status ? this.statusOption[0] : this.statusOption[1];
      this.modelForm.patchValue({
        modelName: this.model.modelName,
        memo: this.model.memo,
        status,
      });
    }
  }

  getErrorStatus(column: string): boolean {
    const controls = this.modelForm.get(column);
    return (this.isSubmit || controls.touched) && !controls.valid;
  }

  create() {
    const form = {
      modelName: this.modelForm.value.modelName,
      memo: this.modelForm.value.memo,
      status: this.modelForm.value.status.value,
    };
    this.httpService.createModel(form)
      .subscribe((res: Model) => {
        this.modelStatusService.setReloadList();
        this.router.navigateByUrl('/m/firmware/' + res.modelId);
        this.close();
      });
  }

  save() {
    const form = {
      modelName: this.modelForm.value.modelName,
      memo: this.modelForm.value.memo,
      status: this.modelForm.value.status.value,
    };
    this.httpService.updateModel(this.model.modelId, form)
      .subscribe((res: Model) => {
        this.modelStatusService.setReloadList();
        this.close();
      });
  }

  close() {
    this.data.close();
  }

}
