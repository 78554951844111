import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { Router } from '@angular/router';
import { SelectOption } from 'src/app/component/select/select.component';
import { OrganizationStatus, Organization } from 'src/app/type/organization';
import { LoadingService } from 'src/app/core/service/loading/loading.service';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';
import { CustomValidator } from 'src/app/core/validator/customValidator';
import { FileData } from '../../../component/file-upload-input/file-upload-input.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EmailPopupComponent } from '../../../popup/email-popup/email-popup.component';
import { PopupService } from '../../../component/popup-window/popup.service';

@Component({
  selector: 'app-create-organiz',
  templateUrl: './create-organiz.component.html',
  styleUrls: ['./create-organiz.component.scss']
})
export class CreateOrganizComponent implements OnInit {

  constructor(
    private formBuild: FormBuilder,
    private httpService: HttpService,
    private router: Router,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private domSanitizer: DomSanitizer,
    private popupService: PopupService,
  ) { }

  @ViewChild('image', { static: true })
  ImageDom: ElementRef;
  smtpShouldShow = false
  image: File;
  readonly IPRegular = /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))*$/;
  isSubmit = false;
  previewImage: string | SafeUrl = 'assets/images/upload.svg';
  organizForm = this.formBuild.group({
    image: [''],
    organizationName: ['', [Validators.required]],
    sessionTimeout: [300, [Validators.required, Validators.pattern(/^\d*$/)]],
    examShareLinkTimeout: ['', [Validators.required, Validators.pattern(/^\d*$/)]],
    maxUserCount: [3, [Validators.required, Validators.pattern(/^\d*$/)]],
    status: [true, Validators.required],
    displayName: ['', Validators.required],
    username: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });
  workList = this.formBuild.group({
    workListIp: ['', [Validators.pattern(this.IPRegular)]],
    workListPort: ['', [Validators.max(65535), CustomValidator.number]],
    workListAeTitle: ['']
  }, { validator: CustomValidator.allOrNone });
  pacsList = this.formBuild.array([
    this.getPacs(),
    this.getPacs(),
  ]);
  smtpForm = this.formBuild.group({
    smtpHost: ['', [Validators.pattern(this.IPRegular)]],
    smtpPort: ['', [Validators.max(65535), CustomValidator.number]],
    smtpUsername: [''],
    smtpPassword: [''],
    isTLS: [false],
  }, { validator: CustomValidator.requiredWithout(['isTLS']) });
  ngOnInit() {
  }

  getErrorStatus(column: string, form: FormGroup = this.organizForm): boolean {
    const controls = form.get(column);
    const hasToCheck = this.isSubmit || controls.touched;
    return hasToCheck && !controls.valid;
  }

  getFormArrayErrorStatus(form: FormGroup, column: string): boolean {
    const controls = form.get(column);
    const hasToCheck = this.isSubmit || controls.touched;
    return hasToCheck && !controls.valid;
  }

  getPacsStatus(form: FormGroup): boolean {
    if (form.value.ip && form.value.port) {
      return (this.isSubmit || form.touched) && !form.valid;
    }
  }

  getPacs() {
    return this.formBuild.group({
      ip: ['', [Validators.pattern(this.IPRegular)]],
      aeTitle: [''],
      organizationAeTitle: [''],
      port: ['', [Validators.max(65535), CustomValidator.number]],
      description: [''],
    }, { validator: CustomValidator.requiredWithout(['description']) });
  }

  addPacs() {
    this.pacsList.push(this.getPacs());
  }

  deletePacs(index: number) {
    this.pacsList.removeAt(index);
  }

  create() {
    this.isSubmit = true;
    if (this.organizForm.valid && this.workList.valid && this.pacsList.valid && this.smtpForm.valid) {
      this.loadingService.setLoading();
      const value = Object.assign({}, this.organizForm.value, this.smtpForm.value, this.workList.value, this.getFormValue(this.pacsList));
      value.status = +value.status;
      value.workListPort = value.workListPort || 0;
      value.smtpPort = value.smtpPort || 0;
      value.smtpAuth = value.isTLS ? 'TLS' : 'NONE';
      this.httpService.createOrganizations(value)
        .subscribe((res: Organization) => {
          if (this.workList.valid && this.workList.value.workListIp) {
            this.sendWorklistToAco(res.organizationId);
          }
          if (this.image) {
            this.uploadImage(res.organizationId);
          } else {
            this.finishCreate();
          }
        });
    }
  }

  private getFormValue(...arg: AbstractControl[]) {
    let value = {};
    arg.forEach((item: AbstractControl) => {
      if (!Object.values(item.value).includes('')) {
        value = { ...value, ...item.value };
      }
    });
    return value;
  }

  uploadImage(id: number) {
    const formData = new FormData();
    formData.append('file', this.image);
    this.httpService.updateOrganizationImage(formData, id)
      .subscribe((res) => {
        switch (res.type) {
          case 1:
            // 進度條
            const alert: Alert = {
              message: res.loaded + ' / ' + res.total,
              disableClick: true,
            };
            this.alertService.setAlert(alert);
            break;
          case 3:
          case 4:
            this.finishCreate();
            this.alertService.cleanAlert();
            break;
        }
      });
  }

  finishCreate() {
    this.back();
    this.alertService.cleanAlert();
  }

  changeImage(fileData: FileData) {
    this.image = fileData.file;
    if (fileData.preview) {
      this.previewImage = this.domSanitizer.bypassSecurityTrustUrl(fileData.preview.toString());
    }
  }

  testWorkList() {
    if (this.workList.value.workListIp && this.workList.value.workListPort) {
      this.httpService.checkWorklist(this.workList.value.workListIp, this.workList.value.workListPort)
        .subscribe(() => {
          this.testSuccess();
        });
    } else {
      this.showTestInvalid();
    }
  }

  testPasc(pacsForm: FormGroup) {
    if (pacsForm.value.ip && pacsForm.value.port) {
      this.httpService.checkPacs(pacsForm.value).subscribe(() => {
        this.testSuccess();
      });
    } else {
      this.showTestInvalid();
    }
  }

  testSmtp() {
    if (this.smtpForm.valid && this.smtpForm.value.smtpPort) {
      const popupRef = this.popupService.open(
        EmailPopupComponent,
        {
          send: (from: string, to: string) => {
            const data = {
              password: this.smtpForm.value.smtpPassword,
              port: this.smtpForm.value.smtpPort,
              host: this.smtpForm.value.smtpHost,
              to,
              from,
              username: this.smtpForm.value.smtpUsername,
              auth: this.smtpForm.value.isTLS ? 'TLS' : 'NONE',
            };
            this.httpService.organizationSmtpTest(data)
              .subscribe((res) => {
                const alert: Alert = {
                  message: 'Is Sending an email'
                };
                this.alertService.setAlert(alert);
              });
            popupRef.destroy();
          }
        }
      );
    } else {
      const alert: Alert = {
        message: 'Missing required SMTP parameters',
      };
      this.alertService.setAlert(alert);
    }
  }

  back() {
    this.loadingService.stopLoading();
    this.router.navigate(['/m/organ']);
  }

  private testSuccess() {
    const alert: Alert = {
      message: 'Send Success',
    };
    this.alertService.setAlert(alert);
  }

  private showTestInvalid() {
    const alert: Alert = {
      message: 'IP or Port is incurrect'
    };
    this.alertService.setAlert(alert);
  }

  private sendWorklistToAco(id: number) {
    const data = {
      aeTitle: this.workList.value.workListAeTitle,
      description: '',
      ip: this.workList.value.workListIp,
      organizationAeTitle: '',
      port: this.workList.value.workListPort,
    };
    this.httpService.updateWorklist(id, data).subscribe(() => {

    });
  }
}
