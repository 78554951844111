import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { environment } from 'src/environments/environment';
import { UserResponse, User, UserStatus, UserData } from 'src/app/type/user';
import { Router } from '@angular/router';
import { PageRequest } from 'src/app/component/pagination/pagination.component';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { UserPopupComponent } from 'src/app/popup/user-popup/user-popup.component';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';
import { UserService } from '../../core/service/user/user.service';
import { DownloadRangePopupComponent } from 'src/app/popup/download-range-popup/download-range-popup.component';

interface TData {
  createdAt: string;
  displayName: string;
  status: boolean;
  userId: number;
  username: string;
  appVersion: number;
  isActivated: boolean;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal: number;
  tData: TData[];
  sort: string;
  userData: UserData;
  constructor(
    private httpService: HttpService,
    private popupService: PopupService,
    private reloadService: ReloadServiceService,
    private userService: UserService,
  ) {
    reloadService.reloadService.subscribe(() => {
      this.getUserList();
    });
    userService.getUserData().subscribe((userData) => {
      this.userData = userData;
    });
  }

  ngOnInit() {
    this.sortBy('userId');
  }

  edit(user: User) {
    this.popupService.open(
      UserPopupComponent,
      {
        id: user.userId,
      }
    );
  }

  getUserList(page = this.currentPage) {
    const search: PageRequest = {
      page,
      size: this.pageSize,
      sort: this.sort,
    };
    this.httpService.getUserList(search).subscribe((res: UserResponse) => {
      this.tData = res.list.map((item: User): TData => ({
        createdAt: item.createdAt.format(environment.dateTime),
        displayName: item.displayName,
        status: item.status === UserStatus.Enable,
        userId: item.userId,
        username: item.username,
        appVersion: item.appVersion,
        isActivated: !!item.loggedInAt,
      }));
      this.currentPage = res.page.currentPage;
      this.pageTotal = res.page.pageTotal;
    });
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.getUserList();
  }

  toggleStatus(status: boolean, itemId: number) {
    if (itemId !== this.userData.userId) {
      this.httpService.updateUser({ status: status ? 1 : 0 }, itemId)
        .subscribe((res) => {
          this.getUserList();
        });
    }
  }

  downloadLog(item: TData) {
    this.popupService.open(
      DownloadRangePopupComponent,
      {
        url: 'users/' + item.userId + '/log',
      }
    );
  }

}
