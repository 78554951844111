import { Component, OnInit, Input } from '@angular/core';

export interface Carousel {
  path: string;
  type: string;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  constructor() { }
  @Input() list: Carousel[];

  ngOnInit() {
  }

}
