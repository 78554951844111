import { Component, HostBinding } from '@angular/core';
import { routeLogAnimation } from './router/router-animation';
import { Router, NavigationEnd } from '@angular/router';
import { RouterConfig } from './router/router-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routeLogAnimation
  ]
})
export class AppComponent {
  title = 'medical';

  @HostBinding('@routeLogAnimation')
  get animation() {
    return this.routerStateCode;
  }

  // router跳转动画所需参数
  routerState = true;
  routerStateCode = 'active';
  lastUrl: string;
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/login' || this.lastUrl === '/login') {
          this.setRouterState();
        }
        this.lastUrl = event.url;
      }
    });
  }

  setRouterState() {
    this.routerState = !this.routerState;
    this.routerStateCode = this.routerState ? 'active' : 'inactive';
  }
}
