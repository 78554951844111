import { Component, HostListener, ChangeDetectorRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fadeInOutAnimation } from 'src/app/animation/common';
import { MessageService, MessageInfo } from 'src/app/core/service/message/message.service';
import { Message, MessageResponse } from 'src/app/type/message';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { ExamService } from 'src/app/core/service/exam/exam.service';
import { UserService } from '../../core/service/user/user.service';
import { UserData, UserLevel } from '../../type/user';
import { FeedbackResponse, Feedback } from '../../type/feedback';
import * as dayjs from 'dayjs';

interface NoteList {
  username: string;
  title: string;
  createAt: dayjs.Dayjs;
  message: string;
  archiveId?: number;
  examId?: string;
  seriesId?: string;
}

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
  animations: [fadeInOutAnimation]
})
export class NotificationButtonComponent implements OnInit {
  isOpen: boolean;
  newCount: number;
  innerNewCount: number;
  unread: string;
  noteList: NoteList[] = [];
  dateTimeFormate = environment.dateTime;
  pageSize = environment.pageSize;
  currentPage = environment.firstPage;
  totalPage: number;
  private isSend = false;
  userData: UserData;
  @ViewChild('option', { static: false }) option;
  @HostListener('document:click', ['$event']) close(event: MouseEvent) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.toggleOpen();
    }
  }
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private messageService: MessageService,
    private router: Router,
    private examService: ExamService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.messageService.getMessageUnreadCount().subscribe((unreadCount: number) => {
      this.newCount = unreadCount > 99 ? 99 : unreadCount;
    });
    this.userService.getUserData().subscribe((userData: UserData) => {
      if (!this.userData) {
        this.messageService.startTimer(userData);
      }
      this.userData = userData;
    });
  }

  toggleOpen() {
    this.noteList = [];
    this.currentPage = environment.firstPage;
    if (!this.isOpen) {
      this.getNote();
      // 自動判斷是feedback還是message
      this.messageService.markAsRead(this.userData);
      this.innerNewCount = this.newCount;
      this.unread = this.newCount && `Unread (${this.newCount})`;
    }
    this.isOpen = !this.isOpen;
    this.changeDetectorRef.detectChanges();
  }

  private getNote() {
    if (this.userData.level === UserLevel.SuperUser) {
      this.getFeedback();
    } else {
      this.getMessage();
    }
  }

  private getMessage() {
    const data = {
      page: this.currentPage,
      size: this.pageSize,
    };
    this.messageService.getMessage(data).subscribe((res: MessageResponse) => {
      const noteList = res.list.map((message: Message): NoteList => ({
        username: message.username,
        title: message.username,
        createAt: message.createAt,
        message: message.message,
        archiveId: message.archiveId,
        examId: message.examId,
        seriesId: message.seriesId,
      }));
      this.noteList = this.noteList.concat(noteList);
      this.isSend = false;
      this.totalPage = res.page.pageTotal;
    });
  }

  private getFeedback() {
    const data = {
      page: this.currentPage,
      size: this.pageSize,
      sort: 'createdAt,DESC',
    };
    this.messageService.getFeedBack(data).subscribe((res: FeedbackResponse) => {
      const noteList = res.list.map((feedback: Feedback) => ({
        username: feedback.user.displayName,
        title: feedback.type.text,
        createAt: feedback.createdAt,
        message: feedback.content,
      }));
      this.noteList = this.noteList.concat(noteList);
      this.isSend = false;
      this.totalPage = res.page.pageTotal;
    });
  }

  toMessage(message: Message) {
    let url: string;
    if (this.userData.level === UserLevel.SuperUser) {
      url = '/m/feedback';
    } else {
      url = '/m/exam/' + message.archiveId + '/' + message.examId;
      if (message.seriesId) {
        url += '/seriesId';
        this.examService.setSeriesId(message.seriesId);
      }
    }
    this.router.navigateByUrl(url);
    this.toggleOpen();
  }

  getNextPage(e) {
    if (this.totalPage > this.currentPage && !this.isSend && e.target.scrollTop > e.target.clientHeight * 0.7) {
      this.isSend = true;
      this.currentPage = this.currentPage + 1;
      this.getNote();
    }
  }
}
