import { Component, OnInit } from '@angular/core';
import { UserPopupComponent } from 'src/app/popup/user-popup/user-popup.component';
import { PopupService } from 'src/app/component/popup-window/popup.service';

@Component({
  selector: 'app-create-user-button',
  templateUrl: './create-user-button.component.html',
  styleUrls: ['./create-user-button.component.scss']
})
export class CreateUserButtonComponent implements OnInit {

  constructor(
    private popupService: PopupService,
  ) { }

  ngOnInit() {
  }

  openPopup() {
    this.popupService.open(
      UserPopupComponent,
      {}
    );
  }
}
