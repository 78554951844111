import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { HttpService } from '../../http/http.service';
import { AlertService, Alert } from '../alert/alert.service';
import * as dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { UserData, UserLevel } from '../../../type/user';

@Injectable({
  providedIn: 'root'
})
export class BackupRestoreService {
  private resendTime = 1 * 60 * 1000;
  private timer: NodeJS.Timer;
  private backupSubject = new BehaviorSubject(false);
  getBackupStatus = this.backupSubject.asObservable();

  private restoreSubject = new BehaviorSubject(false);
  getRestoreStatus = this.restoreSubject.asObservable();
  private userData: UserData;

  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
    private userService: UserService,
  ) {
    userService.getUserData().subscribe(userData => {
      this.userData = userData;
      this.query();
    });
  }

  query() {
    if (this.userData.level === UserLevel.SuperUser) {
      const getBackupStatus = this.httpService.getBackupStatus();
      const getRestoreStatus = this.httpService.getRestoreStatus();
      forkJoin([getBackupStatus, getRestoreStatus]).subscribe((res) => {
        this.backupSubject.next(res[0].isProcessing);
        this.restoreSubject.next(res[1].isProcessing);
        if (res[0].isProcessing || res[1].isProcessing) {
          this.setAlert();
          this.timer = setTimeout(this.query.bind(this), this.resendTime);
        } else {
          this.alertService.cleanAlert();
          clearTimeout(this.timer);
        }
      });
    }
  }

  private setAlert() {
    const data: Alert = {
      title: 'Backup / Restore is in processing....',
      message: 'Start at ' + dayjs().format(environment.dateTime),
      disableClick: true,
    };
    this.alertService.setAlert(data, true);
  }
}
