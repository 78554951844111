import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AppRoutingModule } from './router/app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './view/layout/main-layout/main-layout.component';
import { HeaderComponent } from './view/layout/header/header.component';
import { MenuComponent } from './view/layout/menu/menu.component';
import { LoginComponent } from './view/login/login.component';
import { ButtonComponent } from './component/button/button.component';
import { InputComponent } from './component/input/input.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { OrganizationComponent } from './view/organization/organization.component';
import { CreateOrganizComponent } from './view/organization/create-organiz/create-organiz.component';
import { SelectComponent } from './component/select/select.component';
import { EditOrganizComponent } from './view/organization/edit-organiz/edit-organiz.component';
import { UserComponent } from './view/user/user.component';
import { ArchiveComponent } from './view/archive/archive.component';
import { DeviceComponent } from './view/device/device.component';
import { AlertComponent } from './component/alert/alert.component';
import { ErrorService } from './core/error/error.service';
import { FeedbackComponent } from './view/feedback/feedback.component';
import { CheckboxComponent } from './component/checkbox/checkbox.component';
import { OrganizationSettingComponent } from './view/organization-setting/organization-setting.component';
import { ExamComponent } from './view/exam/exam.component';
import { FirmwareComponent } from './view/firmware/firmware.component';
import { BackupAndRestoreComponent } from './view/backup-and-restore/backup-and-restore.component';
import { MobileAppComponent } from './view/mobile-app/mobile-app.component';
import { LoadingComponent } from './component/loading/loading.component';
import { HttpInterceptorService } from './core/http/http-interceptor.service';
import { ResponseInterceptorService } from './core/http/response-interceptor.service';
import { DetailComponent } from './view/exam/detail/detail.component';
import { MessageComponent } from './view/message/message.component';
import { CarouselComponent } from './component/carousel/carousel.component';
import { SeriesComponent } from './view/exam/series/series.component';
import { PageTitleComponent } from './component/page-title/page-title.component';
import { SwitchButtonComponent } from './component/switch-button/switch-button.component';
import { ExamMenuComponent } from './component/exam-menu/exam-menu.component';
import { ExamDetailMenuComponent } from './component/exam-detail-menu/exam-detail-menu.component';
import { TextareaComponent } from './component/textarea/textarea.component';
import { HeadImagesNameComponent } from './component/head-images-name/head-images-name.component';
import { UserButtonComponent } from './component/user-button/user-button.component';
import { HelpButtonComponent } from './component/help-button/help-button.component';
import { NotificationButtonComponent } from './component/notification-button/notification-button.component';
import { DatePickerComponent } from './component/date-picker/date-picker.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ShareExamComponent } from './view/share-exam/share-exam.component';
import { MenuLogoComponent } from './component/menu-logo/menu-logo.component';
import { OverlayComponent } from './component/popup-window/overlay/overlay.component';
import { AccountEditorPopupComponent } from './popup/account-editor-popup/account-editor-popup.component';
import { SuggestionPopuupComponent } from './popup/suggestion-popuup/suggestion-popuup.component';
import { RadioInputComponent } from './component/radio-input/radio-input.component';
import { ButtonOrganizComponent } from './view/organization/button-organiz/button-organiz.component';
import { ButtonMobileComponent } from './view/mobile-app/button-mobile/button-mobile.component';
import { UploadMobilePopupComponent } from './popup/upload-mobile-popup/upload-mobile-popup.component';
import { ButtonArchiveComponent } from './view/archive/button-archive/button-archive.component';
import { ArchiveCreatePopupComponent } from './popup/archive-create-popup/archive-create-popup.component';
import { CreateDeviceButtonComponent } from './view/device/create-device-button/create-device-button.component';
import { DeviceCreatePopupComponent } from './popup/device-create-popup/device-create-popup.component';
import { CreateUserButtonComponent } from './view/user/create-user-button/create-user-button.component';
import { CreateFirmwareButtonComponent } from './view/firmware/create-firmware-button/create-firmware-button.component';
import { UserPopupComponent } from './popup/user-popup/user-popup.component';
import { SuggestionComponent } from './view/suggestion/suggestion.component';
import { DeviceEditorPopupComponent } from './popup/device-editor-popup/device-editor-popup.component';
import { ArchiveMenuComponent } from './component/archive-menu/archive-menu.component';
import { RouterModule } from '@angular/router';
import { AdminMenuComponent } from './component/admin-menu/admin-menu.component';
import { FirmwareListComponent } from './view/firmware/firmware-list/firmware-list.component';
import { ModelCreatePopupComponent } from './popup/model-create-popup/model-create-popup.component';
import { CreateButtonComponent } from './view/firmware/firmware-list/create-button/create-button.component';
import { FirmwarePopupComponent } from './popup/firmware-popup/firmware-popup.component';
import { MemoPopupComponent } from './popup/memo-popup/memo-popup.component';
import { DownloadRangePopupComponent } from './popup/download-range-popup/download-range-popup.component';
import { ShareLinkPopupComponent } from './popup/share-link-popup/share-link-popup.component';
import { FileUploadInputComponent } from './component/file-upload-input/file-upload-input.component';
import { EmailPopupComponent } from './popup/email-popup/email-popup.component';
import { ShareContentComponent } from './view/share-content/share-content.component';
import { NgbModule, NgbDatepickerModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HeaderComponent,
    MenuComponent,
    LoginComponent,
    ButtonComponent,
    InputComponent,
    PaginationComponent,
    OrganizationComponent,
    CreateOrganizComponent,
    SelectComponent,
    EditOrganizComponent,
    UserComponent,
    ArchiveComponent,
    DeviceComponent,
    AlertComponent,
    FeedbackComponent,
    CheckboxComponent,
    OrganizationSettingComponent,
    ExamComponent,
    FirmwareComponent,
    BackupAndRestoreComponent,
    MobileAppComponent,
    LoadingComponent,
    DetailComponent,
    MessageComponent,
    CarouselComponent,
    SeriesComponent,
    PageTitleComponent,
    SwitchButtonComponent,
    ExamMenuComponent,
    ExamDetailMenuComponent,
    TextareaComponent,
    HeadImagesNameComponent,
    UserButtonComponent,
    HelpButtonComponent,
    NotificationButtonComponent,
    DatePickerComponent,
    ShareExamComponent,
    MenuLogoComponent,
    OverlayComponent,
    AccountEditorPopupComponent,
    SuggestionPopuupComponent,
    RadioInputComponent,
    ButtonOrganizComponent,
    ButtonMobileComponent,
    UploadMobilePopupComponent,
    ButtonArchiveComponent,
    ArchiveCreatePopupComponent,
    CreateDeviceButtonComponent,
    DeviceCreatePopupComponent,
    CreateUserButtonComponent,
    CreateFirmwareButtonComponent,
    UserPopupComponent,
    SuggestionComponent,
    DeviceEditorPopupComponent,
    ArchiveMenuComponent,
    AdminMenuComponent,
    FirmwareListComponent,
    ModelCreatePopupComponent,
    CreateButtonComponent,
    FirmwarePopupComponent,
    MemoPopupComponent,
    DownloadRangePopupComponent,
    ShareLinkPopupComponent,
    FileUploadInputComponent,
    EmailPopupComponent,
    ShareContentComponent,
  ],
  entryComponents: [
    OverlayComponent,
    AccountEditorPopupComponent,
    SuggestionPopuupComponent,
    UploadMobilePopupComponent,
    ArchiveCreatePopupComponent,
    DeviceCreatePopupComponent,
    UserPopupComponent,
    DeviceEditorPopupComponent,
    ModelCreatePopupComponent,
    FirmwarePopupComponent,
    MemoPopupComponent,
    DownloadRangePopupComponent,
    ShareLinkPopupComponent,
    EmailPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule,
    // NgbModule,
    NgbDatepickerModule,
    NgbCarouselModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
