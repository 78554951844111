import { Dayjs } from 'dayjs';
import { Page } from '../component/pagination/pagination.component';
import { Firmware } from './firmware';

export interface Model {
  createdAt: Dayjs;
  modelId: number;
  modelName: string;
  updatedAt: Dayjs;
  lastVersion?: Firmware;
  status: ModelStatus;
  memo: string;
}

export interface ModelResponse {
  list: Model[];
  page: Page;
}

export class ModelStatus {
  static Enable = new ModelStatus(1, 'Enable');
  static Disable = new ModelStatus(0, 'Disable');
  static list = [
    ModelStatus.Enable,
    ModelStatus.Disable,
  ];

  static findByCode(code: number | string) {
    return ModelStatus.list.find((item: ModelStatus) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}
