import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../service/user/user.service';
import { UserData } from 'src/app/type/user';
import { getHome } from 'src/app/router/router-config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.isLogin(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.isLogin(route, state);
  }

  isLogin(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.getUserData()
      .pipe(
        map((userData: UserData) => {
          const isLogin = !!userData.token;
          if (!isLogin) {
            userData.lastUrl = state.url;
            this.userService.setUserData(userData);
            this.router.navigate(['/login']);
          } else if (state.url === '/m') {
            //   // 如果已經登入並且沒有對應到的路由  導向RouterConfig第一個符合項目
            const homeRoute = getHome(userData);
            this.router.navigate([homeRoute]);
          }
          return isLogin;
        })
      );
  }
}
