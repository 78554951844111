import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/http/http.service';
import { ArchiveResponse, Archive, ArchiveStatus } from 'src/app/type/archive';
import { PageRequest } from '../../component/pagination/pagination.component';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { ArchiveCreatePopupComponent } from 'src/app/popup/archive-create-popup/archive-create-popup.component';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';
import { MemoPopupComponent, MemoType } from '../../popup/memo-popup/memo-popup.component';

interface TData {
  archiveId: number;
  archiveName: string;
  organizationId: number;
  description: string;
  status: boolean;
}

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal: number;
  tData: TData[];
  sort: string;
  constructor(
    private httpService: HttpService,
    private popupService: PopupService,
    private reloadService: ReloadServiceService,
  ) {
    reloadService.reloadService.subscribe(() => {
      this.getData();
    });
  }

  ngOnInit() {
    this.getData();
  }

  edit(item: TData) {
    this.popupService.open(
      ArchiveCreatePopupComponent,
      {
        id: item.archiveId,
        edit: true,
      }
    );
  }

  getData(page = this.currentPage) {
    const search: PageRequest = {
      page,
      size: this.pageSize,
      sort: this.sort,
    };
    this.httpService.getArchive(search)
      .subscribe((res: ArchiveResponse) => {
        this.tData = res.list.map((item: Archive): TData => ({
          archiveId: item.archiveId,
          archiveName: item.archiveName,
          organizationId: item.organizationId,
          description: item.description,
          status: item.status === ArchiveStatus.Enable,
        }));
      });
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.getData();
  }

  toggleStatus(status: boolean, item: TData) {
    const data = {
      status: status ? 1 : 0,
      archiveName: item.archiveName,
      description: item.description,
    };
    this.httpService.updateArchive(data, item.archiveId)
      .subscribe((res) => {
        this.getData();
      });
  }

  openDescriptionContent(item: TData) {
    this.popupService.open(
      MemoPopupComponent,
      {
        type: MemoType.Content,
        message: item.description,
      }
    );
  }
}
