import { Dayjs } from 'dayjs';
import { Page } from '../component/pagination/pagination.component';

export interface Firmware {
  createdAt: Dayjs;
  firmwareId: number;
  memo: string;
  modelId: number;
  modelName: string;
  nextVersion: number;
  status: FirmwareStatus;
  updatedAt: Dayjs;
  version: number;
  file?: string;
}

export interface FirmwareResponse {
  list: Firmware[];
  page: Page;
}

export class FirmwareStatus {
  static Enable = new FirmwareStatus(1, 'Enable');
  static Disable = new FirmwareStatus(0, 'Disable');
  static list = [
    FirmwareStatus.Enable,
    FirmwareStatus.Disable,
  ];

  static findByCode(code: number | string) {
    return FirmwareStatus.list.find((item: FirmwareStatus) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}
