import { Component, Input, forwardRef, Output, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true
};

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputComponent implements ControlValueAccessor {
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() min = 0;
  @Input() max: number;
  @Input() maxLength?: number;
  @Input() error?: string;
  @Input() accept: string;
  @Input() autocomplete = 'off';
  @Input() disabled: boolean;

  myValue: any;
  focus = false;
  timer: NodeJS.Timeout;
  onChange: (value) => {};
  onTouched: (e) => {};

  @HostListener('click', ['$event']) onblur(e: MouseEvent) {
    this.onTouched(e);
  }

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const value = (this.type === 'file') ? event && event.item(0) : this.myValue;
    this.onChange(value);
  }

  get value() {
    return this.myValue;
  }

  set value(value: string) {
    const oldValue = this.myValue;
    this.myValue = value;
    if (this.type === 'number') {
      this.myValue = parseInt(value, 10);
    }
    this.notifyValueChange();
    if (this.max && this.myValue > this.max && !this.timer) {
      this.timer = setTimeout(() => {
        this.myValue = oldValue;
        this.notifyValueChange();
        this.timer = null;
      }, 300);
    }
  }


  constructor() {
    this.myValue = '';
  }

  notifyValueChange() {
    if (this.onChange) {
      this.onChange(this.myValue);
    }
  }

  writeValue(value: any): void {
    // if (this.type === 'file') {
    //   // this.value = '';
    //   // this.myValue = null;
    // } else {
    this.myValue = value;
    // }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
