import { Component, OnInit } from '@angular/core';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { ArchiveCreatePopupComponent } from 'src/app/popup/archive-create-popup/archive-create-popup.component';

@Component({
  selector: 'app-button-archive',
  templateUrl: './button-archive.component.html',
  styleUrls: ['./button-archive.component.scss']
})
export class ButtonArchiveComponent implements OnInit {

  constructor(
    private popupService: PopupService,
  ) { }

  ngOnInit() {
  }

  openCreateArchive() {
    this.popupService.open(
      ArchiveCreatePopupComponent,
      {}
    );
  }
}
