import { Component, OnInit, Input } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { CustomValidator } from 'src/app/core/validator/customValidator';
import { UserData, UserLevel } from 'src/app/type/user';
import { UserService } from 'src/app/core/service/user/user.service';

@Component({
  selector: 'app-account-editor-popup',
  templateUrl: './account-editor-popup.component.html',
  styleUrls: ['./account-editor-popup.component.scss']
})
export class AccountEditorPopupComponent implements OnInit, Popup {

  @Input() data;
  isSubmit = false;
  isShowOldPassword = false;
  isShowNewPassword = false;
  userId: number;
  level: UserLevel;
  superUser: UserLevel = UserLevel.SuperUser;
  private userData: UserData;
  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private userService: UserService,
  ) { }

  form = this.formBuilder.group({
    username: [''],
    oldPassword: ['', []],
    newPassword: [''],
  });

  ngOnInit() {
    this.userService.getUserData().subscribe((userData: UserData) => {
      this.userData = { ...userData };
      this.userId = userData.userId;
      this.level = userData.level;
      this.form.patchValue({ username: userData.displayName });
    });
  }

  getErrorStatus(column: string): boolean {
    const controls = this.form.get(column);
    return !controls.valid;
  }

  save() {
    if (this.form.valid && this.userId) {
      this.isSubmit = true;
      const data = {
        oldPassword: this.form.value.oldPassword,
        password: this.form.value.newPassword,
        displayName: this.form.value.username,
      };
      if (!this.form.value.newPassword) {
        delete data.password;
        delete data.oldPassword;
      }
      if (!this.form.value.username) {
        delete data.displayName;
      }
      this.httpService.updateUser(data, this.userId).subscribe(() => {
        if (data.displayName) {
          this.updateUserData(data.displayName);
        }
        this.close();
      });
    }
  }

  private updateUserData(displayName: string) {
    this.userData.displayName = displayName;
    this.userService.setUserData(this.userData);
  }

  close() {
    this.data.close();
  }
}
