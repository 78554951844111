import { Injectable } from '@angular/core';
import { Observable, Subscriber, of, pipe } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { UserData, UserResponse, User, UserLevel, UserStatus } from 'src/app/type/user';
import { Organization, OrganizationResponse, OrganizationStatus, Pacs, PacsResponse } from 'src/app/type/organization';
import * as dayjs from 'dayjs';
import { Page } from 'src/app/component/pagination/pagination.component';
import { ArchiveResponse, Archive, ArchiveStatus } from 'src/app/type/archive';
import { DeviceResponse, Device, DeviceStatus } from 'src/app/type/device';
import { Feedback, FeedbackResponse, FeedbackType } from 'src/app/type/feedback';
import { DeviceLogResponse, DeviceLog, DeviceLogStatus } from 'src/app/type/deviceLog';
import { AclResponse, Acl } from 'src/app/type/acl';
import { FirmwareResponse, Firmware, FirmwareStatus } from 'src/app/type/firmware';
import { MobileAppResponse, MobileApp, MobileAppStatus } from 'src/app/type/mobileApp';
import { ModelResponse, Model, ModelStatus } from 'src/app/type/model';
import { filter, map, catchError } from 'rxjs/operators';
import {
  ExamListResponse,
  Exam,
  ExamSeries,
  ExamPatient,
  ExamResponse,
  ExamOverlay,
  ExamCommentResponse,
  ExamComment,
  PacsStoreStatus,
} from 'src/app/type/exam';
import { Message, MessageResponse, MessageStatus, MessageType } from 'src/app/type/message';
import { Share, ShareExam, ShareSeries } from 'src/app/type/share';
import { BackupRestoreStatus, BackupRestoreResponse } from 'src/app/type/backupRestore';

export interface ApiResponse {
  error?: number;
  data: any;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) {
  }

  public login(data: { account: string, password: string }): Observable<UserData> {
    return this.sendPost('login', data)
      .pipe(
        map((response): UserData => ({
          level: UserLevel.findByCode(response.level),
          organizationId: response.organizationId,
          status: UserStatus.findByCode(response.status),
          token: response.token,
          userId: response.userId,
          username: response.username,
          displayName: response.displayName,
          lastUrl: response.lastUrl,
          sessionTimeout: response.sessionTimeout,
          examShareLinkTimeout: response.examShareLinkTimeout,
        })),
      );
  }

  public logout(): Observable<any> {
    return this.sendPost('logout', {});
  }

  public getOrganizations(search?: any): Observable<OrganizationResponse> {
    return this.sendGet('organizations', search)
      .pipe(
        map((response: any): OrganizationResponse => {
          const list: Organization[] = response.content.map((listItem): Organization => ({
            createdAt: dayjs(listItem.createdAt),
            updateAt: dayjs(listItem.updateAt),
            examShareLinkTimeout: listItem.examShareLinkTimeout,
            maxUserCount: listItem.maxUserCount,
            organizationId: listItem.organizationId,
            organizationName: listItem.organizationName,
            sessionTimeout: listItem.sessionTimeout,
            status: listItem.status === 1,
            smtpHost: listItem.smtpHost,
            smtpPassword: listItem.smtpPassword,
            smtpPort: listItem.smtpPort,
            smtpUsername: listItem.smtpUsername,
            isTLS: listItem.smtpAuth === 'TLS',
            pacsId1: listItem.pacsId1,
            pacsId2: listItem.pacsId2,
            workListAeTitle: listItem.workListAeTitle,
            workListIp: listItem.workListIp,
            workListPort: listItem.workListPort,
            administrator: {
              appVersion: listItem.administrator.appVersion,
              createdAt: dayjs(listItem.administrator.createdAt),
              displayName: listItem.administrator.displayName,
              email: listItem.administrator.email,
              level: UserLevel.findByCode(listItem.administrator.level),
              loggedInAt: dayjs(listItem.administrator.loggedInAt),
              organizationId: listItem.administrator.organizationId,
              status: UserStatus.findByCode(listItem.administrator.status),
              updatedAt: dayjs(listItem.administrator.updatedAt),
              userId: listItem.administrator.userId,
              username: listItem.administrator.username,
            }
          }));
          return { list, page: this.getPage(response) };
        }),
      );
  }

  public createOrganizations(data): Observable<any> {
    data.sessionTimeout = data.sessionTimeout * 60 * 60;
    return this.sendPost('organizations', data);
  }

  public updateOrganizations(data, id): Observable<any> {
    data.sessionTimeout = data.sessionTimeout * 60 * 60;
    return this.sendPut('organizations/' + id, data);
  }

  public updateOrganizationImage(data: FormData, id: number): Observable<any> {
    const req = new HttpRequest('PUT', this.apiUrl + 'organizations/' + id + '/logo', data, {
      reportProgress: true,
    });
    return this.http.request(req)
      .pipe(
        filter(response => response.type !== 0)
      );
  }

  public organizationSmtpTest(data: any): Observable<any> {
    return this.sendPost('organizations/smtp/test/', data);
  }

  public getOrganizationPacs(organizationId: number): Observable<PacsResponse> {
    return this.sendGet('organizations/' + organizationId +'/pacs')
      .pipe(
        map((response: any): PacsResponse => {
          const list: Pacs[] = response.map((listItem): Pacs => ({
          aeTitle: listItem.pacsAeTitle,
          description: listItem.description,
          ip: listItem.ip,
          organizationAETitle: listItem.organizationAeTitle,
          id: listItem.pacsId,
          port: listItem.port,
        }));
        return { list };
        })
      );
  }

  // Pacs
  public getPacs(organizationId: number, pacsId: number): Observable<Pacs> {
	  return this.sendGet('pacs/' + pacsId)
      .pipe(
        map((response: any): Pacs => ({
          aeTitle: response.pacsAeTitle,
          description: response.description,
          ip: response.ip,
          organizationAETitle: response.organizationAeTitle,
          id: response.pacsId,
          port: response.port,
        }))
      );
  }

  public createPacs(data: any): Observable<any> {
    return this.sendPost('pacs', data);
  }

  public updateWorklist(organizationId: number, data: any) {
    return this.sendPut('mwl/' + organizationId, data);
  }

  public udpatePacs(id: number, data: any): Observable<any> {
    return this.sendPut('pacs/' + id, data);
  }

  public checkPacs(data: any) {
    const checkData = {
      ip: data.ip,
      port: data.port,
    };
    return this.sendPost('pacs/check', checkData);
  }

  public checkWorklist(ip: string, port: number) {
    const checkData = {
      ip,
      port,
    };
    return this.sendPost('mwl/check', checkData);
  }

  // User
  public getUserList(search?: any): Observable<UserResponse> {
    return this.sendGet('users', search)
      .pipe(
        map((response: any): UserResponse => {
          const list: User[] = response.content.map((listItem): User => ({
            appVersion: listItem.appVersion,
            createdAt: dayjs(listItem.createdAt),
            displayName: listItem.displayName,
            email: listItem.email,
            level: UserLevel.findByCode(listItem.level),
            loggedInAt: listItem.loggedInAt && dayjs(listItem.loggedInAt),
            organizationId: listItem.organizationId,
            status: UserStatus.findByCode(listItem.status),
            updatedAt: dayjs(listItem.updatedAt),
            userId: listItem.userId,
            username: listItem.username,
          }));
          return { list, page: this.getPage(response) };
        })
      );
  }

  public createUser(data): Observable<User> {
    return this.sendPost('users', data)
      .pipe(
        map((response: any): User => ({
          appVersion: response.appVersion,
          createdAt: dayjs(response.createdAt),
          displayName: response.displayName,
          email: response.email,
          level: UserLevel.findByCode(response.level),
          loggedInAt: dayjs(response.loggedInAt),
          organizationId: response.organizationId,
          status: UserStatus.findByCode(response.status),
          updatedAt: dayjs(response.updatedAt),
          userId: response.userId,
          username: response.username,
        }))
      );
  }

  public updateUser(data, id: number): Observable<any> {
    return this.sendPut('users/' + id, data);
  }

  public resendEmail(userId: number): Observable<string> {
    return this.sendPost(`users/${userId}/creation/send`, { userId })
      .pipe(
        map(response => response.password)
      );
  }

  // archive
  public getArchive(search?: any): Observable<ArchiveResponse> {
    return this.sendGet('archives', search)
      .pipe(
        map((response: any): ArchiveResponse => {
          const list: Archive[] = response.map((listItem): Archive => ({
            archiveId: listItem.archiveId,
            archiveName: listItem.archiveName,
            organizationId: listItem.organizationId,
            description: listItem.description,
            status: ArchiveStatus.findByCode(listItem.status),
          }));
          return { list };
        })
      );
  }

  public getArchiveById(id: number): Observable<Archive> {
    return this.sendGet('archives/' + id)
      .pipe(
        map((response): Archive => ({
          archiveId: response.archiveId,
          archiveName: response.archiveName,
          organizationId: response.organizationId,
          description: response.description,
          status: ArchiveStatus.findByCode(response.status),
        }))
      );
  }

  public createArchive(data: any): Observable<any> {
    return this.sendPost('archives', data);
  }

  public updateArchive(data, id: number): Observable<any> {
    return this.sendPut('archives/' + id, data);
  }

  public getShareKey(filename: string): Observable<Share> {
    return this.sendPost('overlays/' + filename + '/share', { filename })
      .pipe(
        map((res) => {
          return {
            expired: res.expired,
            expiresAt: dayjs(res.expiresAt),
            key: res.key,
            shared: res.shared,
          };
        })
      );
  }

  public getSharaSeriesKey(archiveId: number, seriesId: string): Observable<ShareSeries> {
    return this.sendPost(`/archives/${archiveId}/series/${seriesId}/share`, {})
      .pipe(
        map(response => ({
          archiveId: response.archiveId,
          examId: response.examId,
          seriesId: response.seriesId,
          expired: response.expired,
          expiresAt: dayjs(response.expiresAt),
          key: response.key,
          timeout: response.timeout,
          userId: response.userId,
        }))
      );
  }

  public getShareExamKey(archiveId: number, examId: string): Observable<ShareExam> {
    return this.sendPost(`archives/${archiveId}/exams/${examId}/share`, {})
      .pipe(
        map(response => ({
          archiveId: response.archiveId,
          examId: response.examId,
          expired: response.expired,
          expiresAt: dayjs(response.expiresAt),
          key: response.key,
          timeout: response.timeout,
          userId: response.userId,
        }))
      );
  }

  public getShareExam(key: string): Observable<ExamResponse> {
    return this.sendGet('shared/exams/' + key)
      .pipe(
        map((response: any) => {
          const seriesList: ExamSeries[] = response.series.map((seriesItem): ExamSeries => {
            const overlayList: ExamOverlay[] = seriesItem.overlayImages
              .map((overlayItem): ExamOverlay => ({
                overlayId: overlayItem.overlayId,
                pointList: overlayItem.data.points,
                path: overlayItem.overlayPath,
                type: overlayItem.data.type,
                value: overlayItem.data.value,
              }));
            return {
              dateTime: seriesItem.serieDateTime && dayjs(seriesItem.serieDateTime),
              imageType: seriesItem.imageType,
              modality: seriesItem.modality,
              seriesId: seriesItem.seriesId,
              seriesPath: seriesItem.seriesPath,
              thumbnail: seriesItem.thumbnailPath,
              overlayList,
              mi: seriesItem.mi,
              tib: seriesItem.tib,
              tis: seriesItem.tis,
              combinedPath: seriesItem.combinedPath,
            };
          });
          return {
            id: response.examId,
            seriesList,
            dateTime: response.examDateTime && dayjs(response.examDateTime),
            physician: response.physician,
            accessionNumber: response.accessionNumber,
            probe: {
              modelName: response.probe.model,
              preset: response.probe.preset,
              sn: response.probe.probeSN,
            }
          };
        })
      );

  }

  public getShareExamOverlay(key: string, seriesId: string): Observable<ExamSeries> {
    return this.sendGet(`shared/exams/${key}/series/${seriesId}`)
      .pipe(
        map(response => {
          const overlayList: ExamOverlay[] = response.overlayImages.map((overlay): ExamOverlay => ({
            overlayId: overlay.overlayId,
            path: overlay.overlayPath,
            pointList: overlay.data.points,
            type: overlay.data.type,
            value: overlay.data.value,
          }));
          return {
            dateTime: dayjs(response.seriesDateTime),
            imageType: response.imageType,
            modality: response.modality,
            overlayList,
            seriesPath: response.seriesPath,
            seriesDateTime: dayjs(response.seriesDateTime),
            seriesId: response.seriesId,
            thumbnail: response.thumbnailPath,
            physician: response.relatedExam.physician,
            preset: response.preset,
            mi: response.mi,
            tib: response.tib,
            tis: response.tis,
            combinedPath: response.combinedPath,
          };
        })
      );
  }

  public getShareSeries(key: string): Observable<ExamSeries> {
    return this.sendGet(`shared/series/${key}`)
      .pipe(
        map(response => {
          const overlayList: ExamOverlay[] = response.overlayImages.map((overlay): ExamOverlay => ({
            overlayId: overlay.overlayId,
            path: overlay.overlayPath,
            pointList: overlay.data.points,
            type: overlay.data.type,
            value: overlay.data.value,
          }));
          return {
            dateTime: dayjs(response.seriesDateTime),
            imageType: response.imageType,
            modality: response.modality,
            overlayList,
            seriesPath: response.seriesPath,
            seriesDateTime: dayjs(response.seriesDateTime),
            seriesId: response.seriesId,
            thumbnail: response.thumbnailPath,
            physician: response.relatedExam.physician,
            preset: response.preset,
            mi: response.mi,
            tib: response.tib,
            tis: response.tis,
            combinedPath: response.combinedPath,
          };
        })
      );
  }

  public getExamEarliestDate(archiveId: number): Observable<dayjs.Dayjs> {
    return this.sendGet(`archives/${archiveId}/earliest-date`)
      .pipe(
        map(response => response.examDate ? dayjs(response.examDate) : dayjs('2000-01-01', 'YYYY-mm-DD'))
      );
  }

  // device

  public getDevice(search?: any): Observable<DeviceResponse> {
    return this.sendGet('devices', search)
      .pipe(
        map((response: any) => {
          const list: Device[] = response.content.map((listItem): Device => ({
            createdAt: dayjs(listItem.createdAt),
            deviceId: listItem.deviceId,
            firmwareVersion: listItem.firmwareVersion,
            hardwareVersion: listItem.hardwareVersion,
            macAddress: listItem.macAddress,
            model: {
              createdAt: dayjs(listItem.model.createdAt),
              modelId: listItem.model.modelId,
              modelName: listItem.model.modelName,
              updatedAt: dayjs(listItem.model.updatedAt),
              status: ModelStatus.findByCode(listItem.status),
              memo: listItem.memo,
            },
            organizationId: listItem.organizationId,
            serialNumber: listItem.serialNumber,
            status: DeviceStatus.findByCode(listItem.status),
            updatedAt: dayjs(listItem.updatedAt),
          }));
          return { list, page: this.getPage(response) };
        })
      );
  }

  public createDevice(data: any): Observable<any> {
    return this.sendPost('devices', data);
  }

  public updateDevice(data: any, id: number): Observable<any> {
    return this.sendPut('devices/' + id, this.getValue(data));
  }

  public getDeviceLog(search?): Observable<DeviceLogResponse> {
    return this.sendGet('devices/log', search)
      .pipe(
        map((response: any) => {
          const list: DeviceLog[] = response.content.map((listItem): DeviceLog => ({
            content: listItem.content,
            createdAt: dayjs(listItem.createdAt),
            deviceId: listItem.deviceId,
            id: listItem.id,
            organizationId: listItem.organizationId,
            type: listItem.type,
            userId: listItem.userId,
            name: listItem.displayName,
          }));
          return { list, page: this.getPage(response) };
        })
      );
  }

  public createDeviceLog(data): Observable<any> {
    return this.sendPost('device-log', data);
  }

  // feedback

  public getFeedback(search?: any): Observable<FeedbackResponse> {
    return this.sendGet('feedback', search)
      .pipe(
        map((response: any): FeedbackResponse => {
          const list: Feedback[] = response.content.map((listItem): Feedback => ({
            content: listItem.content,
            createdAt: dayjs(listItem.createdAt).add(8, 'hour'),
            id: listItem.id,
            type: FeedbackType.findByCode(listItem.type),
            user: listItem.user,
            userId: listItem.userId,
            serialNumber: listItem.serialNumber,
            organizationName: listItem.organization && listItem.organization.organizationName,
          }));
          return { list, page: this.getPage(response) };
        })
      );
  }

  public getFeedbackUnreadCount(): Observable<number> {
    return this.sendGet('feedback/unread/count')
      .pipe(
        map(response => response.unreadCount)
      );
  }

  public markFeedBackAsRead(): Observable<any> {
    return this.sendPut('feedback/read', {});
  }

  public sendFeedback(form: { content: string, type: FeedbackType }) {
    const data = {
      content: form.content,
      type: form.type.value,
    };
    return this.sendPost('feedback', data);
  }

  public sendFeedbackWithToken(form: { content: string, type: FeedbackType, serialNumber: string }, token: string) {
    const data = {
      content: form.content,
      type: form.type.value,
      serialNumber: form.serialNumber,
    };
    return this.sendPost('feedback?token=' + token, data);
  }

  // acl
  public createAcl(data: Acl, userId: number): Observable<any> {
    return this.sendPut('users/' + userId + '/permissions', { permissionList: data });
  }

  public getAcl(userId: number): Observable<AclResponse> {
    return this.sendGet('users/' + userId + '/permissions')
      .pipe(
        map((response: any): AclResponse => {
          const list: Acl[] = response.map((listItem): Acl => ({
            archiveId: listItem.archiveId,
            archiveName: listItem.archiveName,
            canRead: listItem.read,
            canWrite: listItem.write,
          }));
          return { list };
        })
      );
  }

  // firmware
  public getFirmware(search?): Observable<FirmwareResponse> {
    return this.sendGet('firmwares', search)
      .pipe(
        map((response: any) => {
          const list: Firmware[] = response.content.map((listItem): Firmware => ({
            createdAt: dayjs(listItem.createdAt),
            firmwareId: listItem.firmwareId,
            memo: listItem.memo,
            modelId: listItem.modelId,
            modelName: listItem.modelName,
            nextVersion: listItem.nextVersion,
            status: FirmwareStatus.findByCode(listItem.status),
            updatedAt: dayjs(listItem.updatedAt),
            version: listItem.version,
          }));
          return { list, page: this.getPage(response) };
        })
      );
  }

  public createFirmware(data): Observable<Firmware> {
    return this.sendPost('firmwares', data)
      .pipe(
        map((response: any): Firmware => ({
          createdAt: dayjs(response.createdAt),
          firmwareId: response.firmwareId,
          memo: response.memo,
          modelId: response.modelId,
          modelName: response.modelName,
          nextVersion: response.nextVersion,
          status: FirmwareStatus.findByCode(response.status),
          updatedAt: dayjs(response.updatedAt),
          version: response.version,
        }))
      );
  }

  public uploadFirewarm(file: FormData, id: number): Observable<any> {
    const req = new HttpRequest('PUT', this.apiUrl + 'firmwares/' + id + '/image', file, {
      reportProgress: true,
    });
    return this.http.request(req)
      .pipe(
        filter(response => response.type !== 0)
      );
  }

  public sendExamToPacs(archiveId: number, examId: string) : Observable<any> {
	  const req = new HttpRequest('PUT', this.apiUrl + 'pacs/archives/' + archiveId + '/exams/' + examId, null);
    return this.http.request(req)
      .pipe(
        filter(response => response.type !== 0)
      )
  }

  public sendSeriesToPacs(archiveId: number, seriesId: string) : Observable<any> {
    const req = new HttpRequest('PUT', this.apiUrl + 'pacs/archives/' + archiveId + '/exams/' + seriesId, null);
    return this.http.request(req)
      .pipe(
        filter(response => response.type !== 0)
      )
  }

  public editFirmware(data: any): Observable<any> {
    return this.sendPut('firmwares', data);
  }

  public editFirmwareById(id: number, data: any): Observable<any> {
    return this.sendPut('firmwares/' + id, data);
  }

  // mobile app
  public getMobileApp(data?: any): Observable<MobileAppResponse> {
    return this.sendGet('apps', data)
      .pipe(
        map((response: any): MobileAppResponse => {
          const list: MobileApp[] = response.content.map((listItem): MobileApp => ({
            appId: listItem.appId,
            createdAt: dayjs(listItem.createdAt),
            memo: listItem.memo,
            status: MobileAppStatus.findByCode(listItem.status),
            updatedAt: listItem.updatedAt ? dayjs(listItem.updatedAt) : dayjs(listItem.createdAt),
            version: listItem.version,
            versionName: listItem.versionName,
          }));
          return { list, page: this.getPage(response) };
        })
      );
  }

  public createMobileApp(data): Observable<MobileApp> {
    return this.sendPost('apps', data)
      .pipe(
        map((response): MobileApp => ({
          appId: response.appId,
          createdAt: dayjs(response.createdAt),
          memo: response.memo,
          status: MobileAppStatus.findByCode(response.status),
          updatedAt: dayjs(response.updatedAt),
          version: response.version,
          versionName: response.versionName,
        })),
      );
  }

  public editMobileApp(appId: number, data): Observable<any> {
    return this.sendPut('apps/' + appId, data);
  }

  public updateMobileAppFile(formdata: FormData, id: number): Observable<any> {
    const req = new HttpRequest('PUT', this.apiUrl + 'apps/' + id + '/apk', formdata, {
      reportProgress: true,
    });
    return this.http.request(req)
      .pipe(
        filter(response => response.type !== 0)
      );
  }

  public updateMobileApp(id, data) {
    return this.sendPut('apps/' + id, data);
  }

  // model
  public getModel(search?): Observable<ModelResponse> {
    return this.sendGet('models', search)
      .pipe(
        map((response: any): ModelResponse => {
          const list: Model[] = response.content.map((listItem): Model => {
            const lastVersion = (listItem.lastVersion) ? {
              createdAt: dayjs(listItem.lastVersion.createdAt),
              firmwareId: listItem.lastVersion.firmwareId,
              memo: listItem.lastVersion.memo,
              modelId: listItem.lastVersion.modelId,
              modelName: listItem.lastVersion.modelName,
              nextVersion: listItem.lastVersion.nextVersion,
              status: FirmwareStatus.findByCode(listItem.lastVersion.status),
              updatedAt: dayjs(listItem.lastVersion.updatedAt),
              version: listItem.lastVersion.version,
            } : null;
            return {
              modelId: listItem.modelId,
              modelName: listItem.modelName,
              createdAt: dayjs(listItem.createdAt),
              updatedAt: dayjs(listItem.updatedAt),
              lastVersion,
              status: ModelStatus.findByCode(listItem.status),
              memo: listItem.memo,
            };
          });
          return { list, page: this.getPage(response) };
        })
      );
  }

  public createModel(data): Observable<Model> {
    return this.sendPost('models', data)
      .pipe(
        map((response: any): Model => ({
          createdAt: dayjs(response.createdAt),
          modelId: response.modelId,
          modelName: response.modelName,
          updatedAt: dayjs(response.updatedAt),
          status: ModelStatus.findByCode(response.status),
          memo: response.memo,
        }))
      );
  }

  public updateModel(id, data): Observable<any> {
    return this.sendPut('models/' + id, data)
  }

  // Exam
  public getExamList(archiveId: number, search: any): Observable<ExamListResponse> {
    return this.sendGet('archives/' + archiveId + '/exams', search)
      .pipe(
        map((response: any): ExamListResponse => {
          const list = response.content.map((listItem): Exam => {
            const sex = listItem.patient.sex && (listItem.patient.sex[0].toUpperCase() + listItem.patient.sex.substring(1));
            const patient: ExamPatient = {
              birthday: listItem.patient.birthdate ? dayjs(listItem.patient.birthdate) : '',
              id: listItem.patient.patientId,
              name: listItem.patient.patientName,
              sex,
            };
            const series: ExamSeries[] = listItem.series.map((seriesItem): ExamSeries => ({
              archiveId: seriesItem.seriesId,
              dateTime: dayjs(seriesItem.seriesDateTime),
              imageType: seriesItem.imageType,
              modality: seriesItem.modality,
              seriesId: seriesItem.seriesId,
              preset: seriesItem.preset,
              combinedPath: seriesItem.combinedPath,
              pacsStoreStatus: seriesItem.storeStatus.map((item) : PacsStoreStatus => {
                return {
                  ip: item.pacsIp,
                  port: item.pacsPort,
                  stored: item.stored
                }
              })
            }));
            return {
              date: dayjs(listItem.examDateTime),
              id: listItem.examId,
              patient,
              seriesList: series,
            };
          });
          return { list, page: this.getPage(response) };
        })
      );
  }

  public getExam(archiveId: number, examId: string): Observable<ExamResponse> {
    return this.sendGet('archives/' + archiveId + '/exams/' + examId)
      .pipe(
        map((response: any): ExamResponse => {
          const seriesList: ExamSeries[] = response.series.map((seriesItem): ExamSeries => {
            const overlayList: ExamOverlay[] = seriesItem.overlayImages
              .map((overlayItem): ExamOverlay => ({
                overlayId: overlayItem.overlayId,
                pointList: overlayItem.data.points,
                path: overlayItem.overlayPath,
                type: overlayItem.data.type,
                value: overlayItem.data.value,
              }));
            return {
              dateTime: seriesItem.serieDateTime && dayjs(seriesItem.serieDateTime),
              imageType: seriesItem.imageType,
              modality: seriesItem.modality,
              seriesId: seriesItem.seriesId,
              seriesPath: seriesItem.seriesPath,
              thumbnail: seriesItem.thumbnailPath,
              overlayList,
              mi: seriesItem.mi,
              tib: seriesItem.tib,
              tis: seriesItem.tis,
              combinedPath: seriesItem.combinedPath
            };
          });
          return {
            id: response.examId,
            seriesList,
            accessionNumber: response.accessionNumber,
            dateTime: response.examDateTime && dayjs(response.examDateTime),
            physician: response.physician,
            patient: {
              birthday: response.patient.birthdate && dayjs(response.patient.birthdate),
              id: response.patient.patientId,
              name: response.patient.patientName,
              sex: response.patient.sex && (response.patient.sex[0].toUpperCase() + response.patient.sex.substring(1)),
            },
            probe: {
              modelName: response.probe.model,
              preset: response.probe.preset,
              sn: response.probe.probeSN,
            }
          };
        })
      );
  }

  public getExamComment(archiveId: number, examId: string): Observable<ExamCommentResponse> {
    return this.sendGet('archives/' + archiveId + '/exams/' + examId + '/comments')
      .pipe(
        map((response: any): ExamCommentResponse => {
          const list: ExamComment[] = response.content.map((commentItem): ExamComment => ({
            archiveId: commentItem.archiveId,
            comment: commentItem.comment,
            dateTime: dayjs(commentItem.dateTime).add(8, 'hour'),
            userId: commentItem.userId,
            status: commentItem.status,
          }));
          return { list };
        })
      );
  }

  public sendExamComment(archiveId: number, examId: string, data: { comment: string }) {
    return this.sendPost('archives/' + archiveId + '/exams/' + examId + '/comments', data);
  }

  public getExamSeries(archiveId: number, seriesId: string): Observable<ExamSeries> {
    return this.sendGet('archives/' + archiveId + '/series/' + seriesId)
      .pipe(
        map((response: any): ExamSeries => {
          const overlayList: ExamOverlay[] = response.overlayImages.map((overlay): ExamOverlay => ({
            overlayId: overlay.overlayId,
            path: overlay.overlayPath,
            pointList: overlay.data.points,
            type: overlay.data.type,
            value: overlay.data.value,
          }));
          const patient = {
            birthday: dayjs(response.patient.birthdate),
            id: response.patient.patientId,
            name: response.patient.patientName,
            sex: response.patient.sex && (response.patient.sex[0].toUpperCase() + response.patient.sex.substring(1)),
          };
          return {
            dateTime: dayjs(response.seriesDateTime),
            imageType: response.imageType,
            modality: response.modality,
            overlayList,
            seriesPath: response.seriesPath,
            seriesDateTime: dayjs(response.seriesDateTime),
            seriesId: response.seriesId,
            thumbnail: response.thumbnailPath,
            physician: response.relatedExam.physician,
            patient,
            mi: response.mi,
            tib: response.tib,
            tis: response.tis,
            preset: response.preset,
            combinedPath: response.combinedPath,
          };
        })
      );
  }

  public getExamSeriesComment(archiveId: number, seriesId: string): Observable<ExamCommentResponse> {
    return this.sendGet('archives/' + archiveId + '/series/' + seriesId + '/comments')
      .pipe(
        map((response: any): ExamCommentResponse => {
          const list: ExamComment[] = response.content.map((commentItem): ExamComment => ({
            examId: commentItem.commentId,
            comment: commentItem.comment,
            dateTime: dayjs(commentItem.dateTime).add(8, 'hour'),
            userId: commentItem.uerId,
          }));
          return { list };
        })
      );
  }

  public sendExamSeriesComment(
    archiveId: number,
    examId: string,
    seriesId: string,
    data: { comment: string }
  ): Observable<any> {
    return this.sendPost('archives/' + archiveId + '/exams/' + examId + '/series/' + seriesId + '/comments', data);
  }

  // message
  public getMessageUnreadCount(): Observable<number> {
    return this.sendGet('messages/unread/count')
      .pipe(
        map(response => response.unreadCount)
      );
  }

  public getMessage(search?): Observable<MessageResponse> {
    return this.sendGet('messages', search)
      .pipe(
        map(response => {
          const list: Message[] = response.content.map((item: any): Message => ({
            archiveId: item.archiveId,
            createAt: dayjs(item.createAt),
            updateAt: dayjs(item.updateAt),
            examId: item.examId,
            seriesId: item.seriesId,
            id: item.id,
            status: MessageStatus.findByCode(item.status),
            type: MessageType.findByCode(item.type),
            userId: item.userId,
            username: item.username,
            message: item.message,
          }));
          const page = this.getPage(response);
          return { page, list };
        })
      );
  }

  public markMessageRead(): Observable<any> {
    return this.sendPut('messages/read', {});
  }

  public getBackupStatus(): Observable<BackupRestoreResponse> {
    return this.sendGet('backup')
      .pipe(
        map((response): BackupRestoreResponse => ({
          isProcessing: !!response.processing,
          date: response.date && dayjs(response.date),
          status: response.stage && BackupRestoreStatus.findByText(response.stage),
        }))
      );
  }

  public getBackupOption(): Observable<string[]> {
    return this.sendGet('restore/destinations')
      .pipe(
        map(response => response.map(path => path))
      );
  }

  public startBackup(destination: string): Observable<any> {
    return this.sendPut('backup', { destination });
  }

  public getRestoreStatus(): Observable<BackupRestoreResponse> {
    return this.sendGet('restore')
      .pipe(
        map((response): BackupRestoreResponse => ({
          isProcessing: !!response.processing,
          date: response.date && dayjs(response.date),
          status: response.stage && BackupRestoreStatus.findByText(response.stage),
        }))
      );
  }

  public startRestore(source: string): Observable<any> {
    return this.sendPut('restore', { source });
  }

  private getValue(data: any) {
    const value = {};
    Object.keys(data).forEach((index) => {
      if (data[index]) {
        value[index] = data[index];
      }
    });
    return value;
  }

  private sendPost(path: string, data: object): Observable<any> {
    return this.http.post(this.apiUrl + path, data)
      .pipe(
        map((res: ApiResponse) => res.data)
      );
  }

  private sendGet(path: string, search?: object): Observable<any> {
    let searchString = '';
    if (search) {
      for (const key in search) {
        if (key) {
          searchString += (searchString) ? '&' + key + '=' + encodeURIComponent(search[key]) : key + '=' + encodeURIComponent(search[key]);
        }
      }
    }
    return this.http.get(this.apiUrl + path + '?' + searchString)
      .pipe(
        map((res: ApiResponse) => res.data)
      );
  }

  private sendPut(path: string, data: object, option?): Observable<any> {
    return this.http.put(this.apiUrl + path, data, option);
  }

  private sendPutFile(path: string, file: File): Observable<any> {
    const req = new HttpRequest('PUT', this.apiUrl + path, file, {
      reportProgress: true,
    });
    return this.http.request(req)
      .pipe(
        filter(response => response.type !== 0)
      );
  }

  private sendDelete(path: string): Observable<any> {
    return this.http.delete(this.apiUrl + path);
  }

  private getPage(response: any): Page {
    return {
      currentPage: response.number,
      pageSize: response.size,
      pageTotal: response.totalPages,
    };
  }
}
