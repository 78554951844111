import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Alert {
  title?: string;
  message?: string;
  click?: () => void;
  clickText?: string;
  disableClick?: boolean;
  showCancel?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alertSubject = new BehaviorSubject<Alert>({});
  getAlert = this.alertSubject.asObservable();
  isFreeze: boolean;
  constructor() { }

  setAlert(data: Alert, isFreeze?: boolean) {
    if (!this.isFreeze) {
      this.alertSubject.next(data);
      this.isFreeze = isFreeze;
    }
  }

  cleanAlert() {
    this.alertSubject.next({});
    this.isFreeze = false;
  }
}
