import { Component, OnInit } from '@angular/core';
import { ExamSeriesItem } from 'src/app/core/service/exam/exam.service';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { ExamResponse, ExamOverlay, ExamProbe } from 'src/app/type/exam';
import { environment } from 'src/environments/environment';
import { zoomInAnimation } from 'src/app/animation/common';

interface Params {
  key: string;
}

@Component({
  selector: 'app-share-exam',
  templateUrl: './share-exam.component.html',
  styleUrls: ['./share-exam.component.scss'],
  animations: [zoomInAnimation],
})
export class ShareExamComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
  ) { }
  seriesList: ExamSeriesItem[] = [];
  showSeries: string;
  seriesType: string;
  overlayList: string[];
  key: string;
  seriesDate = '-';
  preset = '-';
  examDate = '-';
  physician = '-';
  probe: ExamProbe;
  accession = '-';
  seriesIndex: number;
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.key) {
        this.httpService.getShareExam(params.key).subscribe((response: ExamResponse) => {
          this.seriesList = response.seriesList.map((item): ExamSeriesItem => {
            const img = item.imageType === 'Image'
              ? environment.apiUrl + 'shared/exams/' + params.key + '/overlays/' + item.combinedPath
              : environment.apiUrl + 'shared/exams/' + params.key + '/overlays/' + item.thumbnail;
            return {
              ...item,
              img,
              combinedPath: environment.apiUrl + 'shared/exams/' + params.key + '/overlays/' + item.combinedPath,
            };
          });
          this.examDate = response.dateTime.format(environment.dateTime);
          this.physician = response.physician;
          this.accession = response.accessionNumber;
          this.probe = response.probe;
          this.key = params.key;
          this.getOverlay(params.key, this.seriesList[0].seriesId);
        });
      }
    });
  }

  getOverlay(key: string, seriesId: string) {
    this.showSeries = seriesId;
    this.seriesIndex = this.seriesList.findIndex(series => this.showSeries === series.seriesId) + 1;
    this.httpService.getShareExamOverlay(key, seriesId).subscribe((res) => {
      this.seriesType = res.imageType;
      this.overlayList = [environment.apiUrl + 'shared/exams/' + key + '/overlays/' + res.seriesPath];
      this.overlayList.push(environment.apiUrl + 'shared/exams/' + key + '/overlays/' + res.combinedPath);
      this.seriesDate = res ? res.seriesDateTime.format(environment.dateTime) : '-';
      this.preset = res ? res.preset : '-';
    });
  }

}
