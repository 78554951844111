import { Component, OnInit, Input } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { Radio } from 'src/app/component/radio-input/radio-input.component';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { FeedbackType } from 'src/app/type/feedback';
import { Alert, AlertService } from 'src/app/core/service/alert/alert.service';

@Component({
  selector: 'app-suggestion-popuup',
  templateUrl: './suggestion-popuup.component.html',
  styleUrls: ['./suggestion-popuup.component.scss']
})
export class SuggestionPopuupComponent implements OnInit, Popup {

  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private alertService: AlertService,
  ) { }
  @Input() data;

  readonly optionList: Radio[] = FeedbackType.list.map((type: FeedbackType) => ({
    text: type.text,
    value: type,
  }));
  form = this.formBuilder.group({
    type: [this.optionList[0]],
    content: ['', Validators.required],
  });

  ngOnInit() {
  }

  send() {
    if (this.form.valid) {
      const data = {
        content: this.form.value.content,
        type: this.form.value.type.value,
      };
      this.httpService.sendFeedback(data).subscribe(() => {
        const alert: Alert = {
          message: 'Send Success',
        };
        this.alertService.setAlert(alert);
        this.close();
      });
    }
  }

  close() {
    this.data.close();
  }

}
