import { Component, OnInit, Input } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { SelectOption } from 'src/app/component/select/select.component';
import { MobileAppStatus } from 'src/app/type/mobileApp';
import { Validators, FormBuilder } from '@angular/forms';
import { LoadingService } from 'src/app/core/service/loading/loading.service';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';
import { HttpService } from 'src/app/core/http/http.service';
import { Firmware } from 'src/app/type/firmware';
import { ModelStatusService } from 'src/app/core/service/model/model-status.service';
import { HttpProgressEvent } from '@angular/common/http/http';
import { FileData } from '../../component/file-upload-input/file-upload-input.component';

@Component({
  selector: 'app-firmware-popup',
  templateUrl: './firmware-popup.component.html',
  styleUrls: ['./firmware-popup.component.scss']
})
export class FirmwarePopupComponent implements OnInit, Popup {

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private httpService: HttpService,
    private modelStatusService: ModelStatusService,
  ) {
    modelStatusService.getCurrentModelId().subscribe((modelId: number) => {
      this.modelId = modelId;
      this.uploadForm.patchValue({ modelId });
    });
  }

  @Input() data;
  firmware: Firmware;
  readonly statusOption: SelectOption[] = [
    { text: MobileAppStatus.Enable.text, value: MobileAppStatus.Enable.value },
    { text: MobileAppStatus.Disable.text, value: MobileAppStatus.Disable.value },
  ];
  file: File;
  modelId: number;
  isSubmit = false;
  lastVersion: number;
  uploadForm = this.formBuilder.group({
    file: ['', Validators.required],
    version: ['', Validators.required],
    memo: [''],
    status: [this.statusOption[0], Validators.required],
    modelId: ['', Validators.required],
  });

  ngOnInit() {
    this.firmware = this.data.firmware;
    console.log(this.firmware)
    if (this.firmware) {
      const status = this.firmware.status.value === MobileAppStatus.Enable.value ? this.statusOption[0] : this.statusOption[1];
      this.uploadForm.patchValue({
        memo: this.firmware.memo,
        version: this.firmware.version,
        status,
      });
      this.uploadForm.get('file').clearValidators();
    }
  }

  getErrorStatus(column: string): boolean {
    const controls = this.uploadForm.get(column);
    return (this.isSubmit || controls.touched) && !controls.valid;
  }

  changeFile(fileData: FileData) {
    this.file = fileData.file;
  }

  create() {
    this.isSubmit = true;
    if (this.uploadForm.valid) {
      this.loadingService.setLoading();
      const data = {
        ...this.uploadForm.value,
        status: this.uploadForm.value.status.value,
      };
      this.httpService.createFirmware(data)
        .subscribe(this.uploadFile.bind(this));
    }
  }

  save() {
    this.isSubmit = true;
    if (this.uploadForm.valid) {
      this.loadingService.setLoading();
      const data = {
        ...this.uploadForm.value,
        status: this.uploadForm.value.status.value,
      };
      this.httpService.editFirmwareById(this.firmware.firmwareId, data)
        .subscribe((firmware: Firmware) => {
          if (this.file) {
            this.uploadFile(this.firmware);
          } else {
            this.modelStatusService.setFirmwareReload();
            this.loadingService.stopLoading();
            this.alertService.cleanAlert();
            this.close();
          }
        });
    }
  }

  private uploadFile(firmware: Firmware) {
    const formData = new FormData();
    formData.append('file', this.file);
    this.httpService.uploadFirewarm(formData, firmware.firmwareId)
      .subscribe((res: HttpProgressEvent) => {
        if (res.type === 1) {
          const alert: Alert = {
            message: res.loaded + ' / ' + res.total,
            disableClick: true,
          };
          this.alertService.setAlert(alert);
        } else {
          this.modelStatusService.setFirmwareReload();
          this.loadingService.stopLoading();
          this.alertService.cleanAlert();
          this.close();
        }
      });
  }

  close() {
    this.data.close();
  }

}
