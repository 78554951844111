import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { DeviceCreatePopupComponent } from 'src/app/popup/device-create-popup/device-create-popup.component';

@Component({
  selector: 'app-create-device-button',
  templateUrl: './create-device-button.component.html',
  styleUrls: ['./create-device-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDeviceButtonComponent implements OnInit {

  constructor(
    private popupService: PopupService,
  ) { }

  ngOnInit() {
  }

  openCreateDevice() {
    this.popupService.open(
      DeviceCreatePopupComponent,
      {}
    );
  }
}
