import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/http/http.service';
import { FirmwareResponse, Firmware, FirmwareStatus } from 'src/app/type/firmware';
import { SelectOption } from 'src/app/component/select/select.component';
import { ModelStatusService } from 'src/app/core/service/model/model-status.service';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { FirmwarePopupComponent } from 'src/app/popup/firmware-popup/firmware-popup.component';
import { MemoType, MemoPopupComponent } from 'src/app/popup/memo-popup/memo-popup.component';

interface TData extends Firmware {
  isEnable: boolean;
}

@Component({
  selector: 'app-firmware-list',
  templateUrl: './firmware-list.component.html',
  styleUrls: ['./firmware-list.component.scss']
})
export class FirmwareListComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private modelStatusService: ModelStatusService,
    private popupService: PopupService,
  ) { }
  modelId: number;
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal;

  firmwareList: TData[];
  versionOptionList: SelectOption[];
  sort = 'version,DESC';

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.modelId = parseInt(params.modelId, 10);
      this.modelStatusService.setCurrentModelId(this.modelId);
      this.getFirmware();
    });
    this.modelStatusService.hasReloadFirmware.subscribe(() => {
      this.getFirmware();
    });
  }

  getFirmware(page: number = this.currentPage) {
    const search = {
      modelId: this.modelId,
      page,
      size: this.pageSize,
      sort: this.sort,
    };
    this.httpService.getFirmware(search)
      .subscribe((res: FirmwareResponse) => {
        this.firmwareList = res.list.map((item): TData => ({
          ...item,
          isEnable: item.status === FirmwareStatus.Enable,
        }));
        this.versionOptionList = this.firmwareList.map((firmware: Firmware) => ({
          text: firmware.version.toString(),
          value: firmware.firmwareId,
        }));
        this.currentPage = res.page.currentPage;
        this.pageTotal = res.page.pageTotal;
      });
  }

  toggleStatus(status: boolean, itemId: number) {
    this.httpService.editFirmwareById(itemId, { status: status ? 1 : 0 })
      .subscribe((res) => {
        this.getFirmware();
      });
  }

  updateVersion(selected: SelectOption, item: Firmware) {
    const data = {
      nextVersion: selected.value,
    };
    this.httpService.editFirmwareById(item.firmwareId, data).subscribe((res) => {
      this.getFirmware();
    });
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.getFirmware(environment.firstPage);
  }

  edit(firmware: Firmware) {
    this.popupService.open(
      FirmwarePopupComponent,
      {
        firmware,
      }
    );
  }

  openMemoPopup(firmware: Firmware) {
    this.popupService.open(
      MemoPopupComponent,
      {
        title: MemoType.Memo,
        message: firmware.memo,
      }
    );
  }
}
