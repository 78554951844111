import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../service/user/user.service';
import { UserData } from 'src/app/type/user';
import { getHome } from 'src/app/router/router-config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.userService.getUserData()
      .pipe(
        map((userData: UserData) => {
          if (userData.token) {
            const homeRoute = getHome(userData);
            this.router.navigate([userData.lastUrl || homeRoute]);
            return false;
          } else {
            return true;
          }
        })
      );
  }

}
