import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReloadServiceService {
  private isReload = new Subject();
  reloadService = this.isReload.asObservable();
  constructor() { }

  setReload() {
    this.isReload.next(1);
  }
}
