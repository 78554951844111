import { Component, OnInit, Input } from '@angular/core';
import { SelectOption } from 'src/app/component/select/select.component';
import { MobileAppStatus, MobileApp, MobileAppResponse } from 'src/app/type/mobileApp';
import { Validators, FormBuilder } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { LoadingService } from 'src/app/core/service/loading/loading.service';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';
import * as  ApkParser from 'app-info-parser/src/apk';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';
import { FileData } from 'src/app/component/file-upload-input/file-upload-input.component';

@Component({
  selector: 'app-upload-mobile-popup',
  templateUrl: './upload-mobile-popup.component.html',
  styleUrls: ['./upload-mobile-popup.component.scss']
})
export class UploadMobilePopupComponent implements OnInit, Popup {
  @Input() data;
  mobelApp: MobileApp;
  isEdit: boolean;
  readonly statusOption: SelectOption[] = [
    { text: MobileAppStatus.Enable.text, value: MobileAppStatus.Enable.value },
    { text: MobileAppStatus.Disable.text, value: MobileAppStatus.Disable.value },
  ];
  file: File;
  isSubmit = false;
  lastVersion: number;
  uploadForm = this.formBuilder.group({
    file: ['', [Validators.required]],
    memo: [''],
    status: [this.statusOption[0]],
    version: ['-', [Validators.required, Validators.pattern(/^[\d.]*$/)]],
    versionName: ['-', [Validators.required]],
  });
  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private reloadService: ReloadServiceService,
  ) { }
  ngOnInit() {
    this.mobelApp = this.data.mobelApp;
    this.isEdit = this.data.isEdit;
    if (this.mobelApp) {
      this.setDefault();
      this.uploadForm.get('file').clearValidators();
    }
    this.getLastApp();
  }

  getErrorStatus(column: string): boolean {
    const controls = this.uploadForm.get(column);
    return (this.isSubmit || controls.touched) && !controls.valid;
  }

  changeFile(fileData: FileData) {
    if (fileData.file) {
      this.loadingService.setLoading();
      const parser = new ApkParser(fileData.file);
      parser.parse().then((res) => {
        if (this.isEdit) {
          this.changeInEdit(res, fileData);
        } else {
          this.changeInNew(res, fileData);
        }
      }).catch(this.showFileError.bind(this))
        .finally(() => {
          this.loadingService.stopLoading();
        });
    }
  }

  private changeInEdit(res, fileData) {
    // 是編輯模式 要上船相同等級
    if (res.versionCode === this.mobelApp.version) {
      this.setFile(res, fileData);
    } else {
      throw 'Can\'t use different version';
    }
  }

  private changeInNew(res, fileData) {
    // 是新增模式 要大於最新等級
    if (res.versionCode > this.lastVersion) {
      this.setFile(res, fileData);
    } else {
      throw 'Can\'t use old version';
    }
  }

  private setFile(res, fileData) {
    this.file = fileData.file;
    this.uploadForm.patchValue({
      version: res.versionCode,
      versionName: res.versionName,
    });
  }

  showFileError(message: string) {
    this.alertService.setAlert({ message });
    this.file = null;
    this.uploadForm.controls.file.reset();
  }

  create() {
    this.isSubmit = true;
    if (this.uploadForm.valid) {
      this.loadingService.setLoading();
      const data = this.uploadForm.value;
      data.status = data.status.value;
      this.httpService.createMobileApp(this.uploadForm.value)
        .subscribe((res: MobileApp) => {
          this.uploadFile(res.appId);
        });
    }
  }

  save() {
    this.isSubmit = true;
    if (this.uploadForm.valid) {
      this.loadingService.setLoading();
      const data = this.uploadForm.value;
      data.status = data.status.value;
      this.httpService.editMobileApp(this.mobelApp.appId, this.uploadForm.value)
        .subscribe((res: MobileApp) => {
          if (this.file) {
            this.uploadFile();
          } else {
            this.loadingService.stopLoading();
            this.reloadService.setReload();
            this.alertService.cleanAlert();
            this.close();
          }
        });
    }
  }

  uploadFile(id: number = this.mobelApp.appId) {
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);
      this.httpService.updateMobileAppFile(formData, id)
        .subscribe((res) => {
          if (res.type === 1) {
            const alert: Alert = {
              message: res.loaded + ' / ' + res.total,
              disableClick: true,
            };
            this.alertService.setAlert(alert);
          } else {
            this.loadingService.stopLoading();
            this.reloadService.setReload();
            this.close();
            this.alertService.cleanAlert();
          }
        });
    }
  }

  getLastApp() {
    this.httpService.getMobileApp()
      .subscribe((res: MobileAppResponse) => {
        this.lastVersion = res.list.length > 0 ? res.list[0].version : 0;
      });
  }

  close() {
    this.data.close();
  }

  private setDefault() {
    const status = this.statusOption.find(item => item.value === this.mobelApp.status.value);
    this.uploadForm.patchValue({
      version: this.mobelApp.version,
      versionName: this.mobelApp.versionName,
      memo: this.mobelApp.memo,
      status,
    });
  }
}
