export class Storage<T> {
  constructor(
    private key: string
  ) { }

  set(data: T) {
    const dataString = JSON.stringify(data);
    localStorage.setItem(this.key, dataString);
  }

  get(): T | null {
    const dataString = localStorage.getItem(this.key);
    return dataString ? JSON.parse(dataString) : null;
  }

  remove() {
    localStorage.removeItem(this.key);
  }

}
