import { Component, AfterContentInit, AfterContentChecked, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements AfterContentInit, AfterContentChecked {
  constructor(
    private titleService: Title,
  ) { }

  webTitle = environment.title;

  @ViewChild('title', { static: false }) h1Content: ElementRef;

  ngAfterContentInit() {
    // this.setTitle();
  }

  ngAfterContentChecked() {
    // this.setTitle();
  }

  // private setTitle() {
  //   if (this.h1Content) {
  //     this.titleService.setTitle(this.webTitle + ' - ' + this.h1Content.nativeElement.textContent);
  //   }
  // }

}
