import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { MessageResponse, Message } from 'src/app/type/message';
import { UserData, UserLevel } from '../../../type/user';
import { FeedbackResponse } from '../../../type/feedback';
import { BackupRestoreService } from '../backup-restore/backup-restore.service';
import { UserService } from '../user/user.service';

export interface MessageInfo {
  data: Message[];
  newCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private time = 60 * 1000;
  private unreadCountSubject = new BehaviorSubject<number>(0);
  private timer: NodeJS.Timer;
  private isProcessing = true;
  private userData: UserData;
  constructor(
    private httpService: HttpService,
    private backupRestoreService: BackupRestoreService,
    private userService: UserService,
  ) {
    userService.getUserData().subscribe(userData => {
      this.userData = userData;
      this.getBackupRestoreStatus();
    });
  }

  getBackupRestoreStatus(userData: UserData = this.userData) {
    const getBackupStatus = this.backupRestoreService.getBackupStatus;
    const getRestoreStatus = this.backupRestoreService.getRestoreStatus;
    combineLatest([getBackupStatus, getRestoreStatus]).subscribe((res) => {
      const status = res[0] || res[1];
      if (this.isProcessing !== status) {
        if (status) {
          clearInterval(this.timer);
        } else {
          this.startTimer(userData);
        }
        this.isProcessing = status;
      }
    });
  }

  startTimer(userData: UserData) {
    if (!this.isProcessing) {
      // 每隔 time 觸發一次
      if (userData.level === UserLevel.SuperUser) {
        this.queryUnreadFeedbackCount();
        this.timer = setInterval(() => {
          this.queryUnreadFeedbackCount();
        }, this.time);
      } else {
        this.queryUnreadCount();
        this.timer = setInterval(() => {
          this.queryUnreadCount();
        }, this.time);
      }
    }
  }

  getMessageUnreadCount(): BehaviorSubject<number> {
    return this.unreadCountSubject;
  }

  getMessage(param): Observable<MessageResponse> {
    return this.httpService.getMessage(param);
  }

  getFeedBack(param): Observable<FeedbackResponse> {
    return this.httpService.getFeedback(param);
  }

  markAsRead(userData: UserData) {
    if (userData.level === UserLevel.SuperUser) {
      this.markFeedbackAsRead(userData);
    } else {
      this.markMessageAsRead(userData);
    }
  }

  private markMessageAsRead(userData: UserData) {
    this.httpService.markMessageRead().subscribe((res) => {
      clearInterval(this.timer);
      this.startTimer(userData);
    });
  }

  private markFeedbackAsRead(userData: UserData) {
    this.httpService.markFeedBackAsRead().subscribe(() => {
      clearInterval(this.timer);
      this.startTimer(userData);
    });
  }

  private queryUnreadCount() {
    this.httpService.getMessageUnreadCount().subscribe((unreadCount: number) => {
      this.unreadCountSubject.next(unreadCount);
    });
  }

  private queryUnreadFeedbackCount() {
    this.httpService.getFeedbackUnreadCount().subscribe((unreadCount: number) => {
      this.unreadCountSubject.next(unreadCount);
    });
  }
}
