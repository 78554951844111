import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/http/http.service';
import { Router } from '@angular/router';
import { ModelResponse, Model, ModelStatus } from 'src/app/type/model';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { ModelCreatePopupComponent } from 'src/app/popup/model-create-popup/model-create-popup.component';
import { ModelStatusService } from 'src/app/core/service/model/model-status.service';
import { MemoType, MemoPopupComponent } from 'src/app/popup/memo-popup/memo-popup.component';

interface TData {
  modelName: string;
  modelId: number;
  version: string | number;
  memo: string;
  status: boolean;
  updatedAt: string;
}

@Component({
  selector: 'app-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.scss']
})
export class FirmwareComponent implements OnInit {
  tData: TData[];
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal;
  sort = 'modelName,DESC';
  constructor(
    private httpSevice: HttpService,
    private router: Router,
    private popupService: PopupService,
    private modelStatusService: ModelStatusService,
    private httpService: HttpService,
  ) {
    modelStatusService.hasReloadList().subscribe(() => {
      this.getFirmware();
    });
  }

  ngOnInit() {
    this.getFirmware();
    this.modelStatusService.setCurrentModelId();
  }

  getFirmware(page = this.currentPage) {
    const search = {
      withLastVersionOfFirmware: true,
      page,
      size: this.pageSize,
      sort: this.sort,
    };
    this.httpSevice.getModel(search).subscribe((res: ModelResponse) => {
      this.tData = res.list.map((item: Model): TData => ({
        ...item,
        version: item.lastVersion ? item.lastVersion.version : '-',
        status: item.status === ModelStatus.Enable,
        updatedAt: item.updatedAt.format(environment.dateTime),
      }));
      this.currentPage = res.page.currentPage;
      this.pageTotal = res.page.pageTotal;
    });
  }

  changePage(page: number) {
    this.getFirmware(page);
  }

  edit(item: Model) {
    // this.router.navigate(['/m/firmware/edit/' + item.modelId]);
    this.popupService.open(
      ModelCreatePopupComponent,
      {
        model: item,
      }
    );
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.getFirmware(environment.firstPage);
  }

  toggleStatus(status: boolean, itemId: number) {
    this.httpService.updateModel(itemId, { status: status ? 1 : 0 })
      .subscribe((res) => {
        this.getFirmware();
      });
  }

  openMemoPopup(item: TData) {
    this.popupService.open(
      MemoPopupComponent,
      {
        title: MemoType.Memo,
        message: item.memo,
      }
    );
  }
}
