import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from '../service/user/user.service';
import { UserData } from 'src/app/type/user';
import { ErrorService } from '../error/error.service';


@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private errorService: ErrorService,
  ) {
    userService.getUserData().subscribe((userData: UserData) => {
      if (userData.token) {
        this.token = 'Bearer ' + userData.token;
      }
    });
  }
  token = '';

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const newRequest = req.clone({ setHeaders: { Authorization: this.token } });
    if (!environment.production) {
      console.log(newRequest);
    }
    return next.handle(newRequest);
  }
}
