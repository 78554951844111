import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from '../view/layout/main-layout/main-layout.component';
import { AuthGuard } from '../core/guard/auth/auth.guard';
import { RouterConfig } from './router-config';
import { LoginComponent } from '../view/login/login.component';
import { LoginGuard } from '../core/guard/login/login.guard';
import { ShareExamComponent } from '../view/share-exam/share-exam.component';
import { SuggestionComponent } from '../view/suggestion/suggestion.component';
import { ShareContentComponent } from '../view/share-content/share-content.component';


const routes: Routes = [
  {
    path: 'm',
    component: MainLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      RouterConfig.Organization,
      RouterConfig.MobileApp,
      RouterConfig.User,
      RouterConfig.Archive,
      RouterConfig.Device,
      RouterConfig.Feedback,
      RouterConfig.OrganizationSetting,
      RouterConfig.Exam,
      RouterConfig.Firmware,
      RouterConfig.MessageCenter,
      RouterConfig.BackupAndRestore,
      {
        path: '**',
        redirectTo: '',
      }
    ]
  },
  {
    path: 's/:key',
    component: ShareExamComponent,
  },
  {
    path: 'si/:key/:seriesId',
    component: ShareContentComponent,
  },
  {
    path: 'f',
    component: SuggestionComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: '**',
    redirectTo: 'm',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
