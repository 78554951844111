import { Component, OnInit } from '@angular/core';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  message;
  clickOK: () => void;
  clickText: string;
  disableClick: boolean;
  showCancel: boolean;
  title: string;

  constructor(
    private alertService: AlertService,
  ) {
    alertService.getAlert.subscribe((alertData: Alert) => {
      this.message = alertData.message;
      this.clickOK = alertData.click;
      this.clickText = alertData.clickText || 'OK';
      this.disableClick = alertData.disableClick;
      this.showCancel = alertData.showCancel;
      this.title = alertData.title;
    });
  }

  ngOnInit() {
  }

  click() {
    if (this.clickOK) {
      this.clickOK();
    }
    this.alertService.cleanAlert();
  }

  cancel() {
    this.alertService.cleanAlert();
  }

}
