import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ExamSeriesItem, ExamService } from 'src/app/core/service/exam/exam.service';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesComponent implements OnInit, OnChanges, OnDestroy {
  @Output() changeSeries = new EventEmitter<string>();
  @Input() overlayList: string[];
  @Input() type: string;
  @Input() seriesList: ExamSeriesItem[];
  @Input() seriesId: string;
  @Input() isHiddenListButton = false;
  @Output() toList = new EventEmitter();
  isShowOverlay = true;
  firstId: string;
  lastId: string;
  currentSeries: ExamSeriesItem;
  constructor(
    private examService: ExamService,
  ) { }

  ngOnInit(seriesList = this.seriesList) {
    if (this.seriesList) {
      this.firstId = seriesList[0].seriesId;
      this.currentSeries = this.seriesList.find(series => series.seriesId === this.seriesId);
      this.lastId = seriesList[this.seriesList.length - 1].seriesId;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isShowOverlay = true;
    if (changes.seriesList) {
      this.ngOnInit(changes.seriesList.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.examService.cleanSeriesData();
  }

  toggleOverlay() {
    this.isShowOverlay = !this.isShowOverlay;
  }

  lastClick() {
    if (this.firstId !== this.seriesId) {
      const index = this.seriesList.findIndex((item: ExamSeriesItem) => item.seriesId === this.seriesId);
      this.changeSeries.emit(this.seriesList[index - 1].seriesId);
    }
  }

  nastClick() {
    if (this.lastId !== this.seriesId) {
      const index = this.seriesList.findIndex((item: ExamSeriesItem) => item.seriesId === this.seriesId);
      this.changeSeries.emit(this.seriesList[index + 1].seriesId);
    }
  }
}
