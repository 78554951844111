import { Injectable, ErrorHandler, Inject, Injector, NgZone } from '@angular/core';
import { AlertService, Alert } from '../service/alert/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../service/loading/loading.service';
import { Router } from '@angular/router';
import { ApiResponse } from '../http/http.service';
import { UserService } from '../service/user/user.service';
import { SearchHistoryService } from '../service/search-history/search-history.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService extends ErrorHandler {
  private get alertService(): AlertService {
    return this.injector.get(AlertService);
  }
  private get loadingService(): LoadingService {
    return this.injector.get(LoadingService);
  }
  private get router(): Router {
    return this.injector.get(Router);
  }
  private get zone(): NgZone {
    return this.injector.get(NgZone);
  }
  private get userService(): UserService {
    return this.injector.get(UserService);
  }
  private get searchHistoryService(): SearchHistoryService {
    return this.injector.get(SearchHistoryService);
  }
  constructor(
    @Inject(Injector) private injector: Injector,
  ) {
    super();
  }

  handleError(error: HttpErrorResponse) {
    if (!environment.production) {
      console.error(error);
    }
    const alertData: Alert = this.errorMap(error.error);
    this.zone.run(() => {
      this.alertService.setAlert(alertData);
      if (error.status === 0) {
        this.router.navigate(['/login']);
      }
      this.loadingService.stopLoading();
    });
  }

  private errorMap(apiResponse: ApiResponse): Alert {
    if (!apiResponse.error) {
      return {
        title: 'Warning',
        message: 'Session Timeout.',
        click: () => {
          this.logout();
        },
      };
    }
    switch (apiResponse.error) {
      case 1:
        return {
          title: 'Warning',
          message: 'Your are already logged in. Please log out of your other session first.',
          click: () => {
            this.logout();
          },
        };
      case 5:
        return {
          title: 'Wait',
          message: 'Backup & Restore is in processing.'
        };
      case 21:
        throw apiResponse.error;
      default:
        return {
          message: (apiResponse.error) ? apiResponse.message : 'something error',
        };
    }
  }

  private logout() {
    this.userService.clean();
    this.searchHistoryService.cleanAll();
    document.location.reload();
  }
}
