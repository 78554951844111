import { Component, ChangeDetectorRef, HostListener, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { fadeInOutAnimation } from 'src/app/animation/common';
import { PopupService } from '../popup-window/popup.service';
import { SuggestionPopuupComponent } from 'src/app/popup/suggestion-popuup/suggestion-popuup.component';
import { UserService } from 'src/app/core/service/user/user.service';
import { Subscription } from 'rxjs';
import { UserLevel } from 'src/app/type/user';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
  animations: [fadeInOutAnimation]
})
export class HelpButtonComponent implements OnInit, OnDestroy {
  isOpen: boolean;
  private userSubscription: Subscription;
  level: UserLevel;
  readonly SU = UserLevel.SuperUser;
  @HostListener('document:click', ['$event']) close(event: MouseEvent) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private popupService: PopupService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.userSubscription = this.userService.getUserData().subscribe(userData => {
      this.level = userData.level;
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.changeDetectorRef.detectChanges();
  }

  openSuggestionPopup() {
    this.popupService.open(
      SuggestionPopuupComponent,
      {},
    );
    this.isOpen = false;
  }
}
