import { Component, HostListener, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as  dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

const INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DatePickerComponent {
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() min = 0;
  @Input() error?: string;
  @Input() accept: string;
  @Input() autocomplete = 'off';
  @Input() disabled: boolean;
  @Output() change = new EventEmitter();

  myValue: any;
  focus = false;
  readonly dateInputFormat = environment.date;
  date: NgbDate;
  onChange: (value: dayjs.Dayjs) => {};
  onTouched: (e) => {};

  @HostListener('click', ['$event']) onblur(e: MouseEvent) {
    if (this.onTouched) {
      this.onTouched(e);
    }
  }

  constructor(
    private ngbCalendar: NgbCalendar,
  ) { }

  notifyValueChange() {
    if (this.onChange) {
      const date = dayjs().year(this.date.year).month(this.date.month - 1).date(this.date.day);
      this.onChange(date);
      this.change.emit();
    }
  }

  writeValue(value: string): void {
    if (value) {
      this.date = this.ngbCalendar.getToday();
      const day = dayjs(value);
      this.date.year = day.get('year');
      this.date.month = day.get('month') + 1;
      this.date.day = day.get('date');
    } else {
      this.date = this.ngbCalendar.getToday();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
