import { Route } from '@angular/router';
import { OrganizationComponent } from '../view/organization/organization.component';
import { EditOrganizComponent } from '../view/organization/edit-organiz/edit-organiz.component';
import { UserComponent } from '../view/user/user.component';
import { ArchiveComponent } from '../view/archive/archive.component';
import { DeviceComponent } from '../view/device/device.component';
import { FeedbackComponent } from '../view/feedback/feedback.component';
import { UserLevel, UserData } from '../type/user';
import { OrganizationSettingComponent } from '../view/organization-setting/organization-setting.component';
import { ExamComponent } from '../view/exam/exam.component';
import { FirmwareComponent } from '../view/firmware/firmware.component';
import { BackupAndRestoreComponent } from '../view/backup-and-restore/backup-and-restore.component';
import { MobileAppComponent } from '../view/mobile-app/mobile-app.component';
import { DetailComponent as ExamDetailComponent } from '../view/exam/detail/detail.component';
import { MessageComponent } from '../view/message/message.component';
import { ExamMenuComponent } from '../component/exam-menu/exam-menu.component';
import { ExamDetailMenuComponent } from '../component/exam-detail-menu/exam-detail-menu.component';
import { Type } from '@angular/core';
import { ButtonOrganizComponent } from '../view/organization/button-organiz/button-organiz.component';
import { ButtonMobileComponent } from '../view/mobile-app/button-mobile/button-mobile.component';
import { ButtonArchiveComponent } from '../view/archive/button-archive/button-archive.component';
import { CreateDeviceButtonComponent } from '../view/device/create-device-button/create-device-button.component';
import { CreateUserButtonComponent } from '../view/user/create-user-button/create-user-button.component';
import { CreateFirmwareButtonComponent } from '../view/firmware/create-firmware-button/create-firmware-button.component';
import { CreateOrganizComponent } from '../view/organization/create-organiz/create-organiz.component';
import { ArchiveMenuComponent } from '../component/archive-menu/archive-menu.component';
import { AdminMenuComponent } from '../component/admin-menu/admin-menu.component';
import { FirmwareListComponent } from '../view/firmware/firmware-list/firmware-list.component';
import { CreateButtonComponent } from '../view/firmware/firmware-list/create-button/create-button.component';

export interface RouteConfig extends Route {
  totalPath?: string;
  data?: RouterData;
}

export interface RouterData {
  name?: string;
  menu?: Type<any>;
  acccessLevel?: UserLevel;
}

export const RouterConfig = {
  Organization: {
    path: 'organ',
    totalPath: '/m/organ',
    data: {
      name: 'Organization',
      acccessLevel: UserLevel.SuperUser,
      menu: AdminMenuComponent,
    },
    children: [
      {
        path: '',
        component: OrganizationComponent,
        data: {
          button: ButtonOrganizComponent,
        },
      },
      {
        path: 'create',
        component: CreateOrganizComponent,
      },
      {
        path: 'edit/:id',
        component: EditOrganizComponent,
      },
      {
        path: '**',
        redirectTo: '',
      },
    ]
  },
  MobileApp: {
    path: 'app',
    totalPath: '/m/app',
    data: {
      name: 'Android App',
      acccessLevel: UserLevel.SuperUser,
      menu: AdminMenuComponent,
    },
    children: [
      {
        path: '',
        component: MobileAppComponent,
        data: {
          button: ButtonMobileComponent,
        },
      },
      {
        path: '**',
        redirectTo: '',
      },
    ]
  },
  User: {
    path: 'user',
    totalPath: '/m/user',
    data: {
      name: 'User',
      acccessLevel: UserLevel.OrganizationAdmin,
      menu: ArchiveMenuComponent,
    },
    children: [
      {
        path: '',
        component: UserComponent,
        data: {
          button: CreateUserButtonComponent,
        }
      },
      {
        path: '**',
        redirectTo: '',
      }
    ]
  },
  Archive: {
    path: 'archive',
    totalPath: '/m/archive',
    data: {
      name: 'Archive',
      acccessLevel: UserLevel.OrganizationAdmin,
      menu: ArchiveMenuComponent,
    },
    children: [
      {
        path: '',
        component: ArchiveComponent,
        data: {
          button: ButtonArchiveComponent,
        }
      },
      {
        path: ':archiveId',
        component: ExamComponent,
        pathMatch: 'full',
        data: {
          url: '/m/archive/'
        }
      },
      {
        path: ':archiveId/:examId',
        component: ExamDetailComponent,
        pathMatch: 'full',
        data: {
          menu: ExamDetailMenuComponent,
          getSeries: false,
          backTo: '/m/archive',
        }
      },
      {
        path: ':archiveId/:examId/seriesId',
        component: ExamDetailComponent,
        pathMatch: 'full',
        data: {
          menu: ExamDetailMenuComponent,
          getSeries: true,
          backTo: '/m/archive',
        }
      },
      {
        path: '**',
        redirectTo: '',
      }
    ]
  },
  Device: {
    path: 'device',
    totalPath: '/m/device',
    data: {
      name: 'Device',
      acccessLevel: UserLevel.OrganizationAdmin,
      menu: ArchiveMenuComponent,
    },
    children: [
      {
        path: '',
        component: DeviceComponent,
        data: {
          button: CreateDeviceButtonComponent,
        }
      },
      {
        path: '**',
        redirectTo: '',
      }
    ]
  },
  Feedback: {
    path: 'feedback',
    totalPath: '/m/feedback',
    component: FeedbackComponent,
    data: {
      name: 'Feedback',
      acccessLevel: UserLevel.SuperUser,
      menu: AdminMenuComponent,
    }
  },
  OrganizationSetting: {
    path: 'organiz-setting',
    totalPath: '/m/organiz-setting',
    component: OrganizationSettingComponent,
    data: {
      name: 'Setting',
      acccessLevel: UserLevel.OrganizationAdmin,
      menu: ArchiveMenuComponent,
    },
  },
  Exam: {
    path: 'exam',
    totalPath: '/m/exam',
    data: {
      name: 'Exam',
      acccessLevel: UserLevel.Normal,
    },
    children: [
      {
        path: '',
        component: ExamComponent,
        data: {
          menu: ExamMenuComponent,
          url: '/m/exam/'
        }
      },
      {
        path: ':archiveId/:examId',
        component: ExamDetailComponent,
        pathMatch: 'full',
        data: {
          menu: ExamDetailMenuComponent,
          getSeries: false,
          backTo: '/m/exam',
        }
      },
      {
        path: ':archiveId/:examId/seriesId',
        component: ExamDetailComponent,
        pathMatch: 'full',
        data: {
          menu: ExamDetailMenuComponent,
          getSeries: true,
          backTo: '/m/exam',
        }
      },
      {
        path: '**',
        redirectTo: '',
      }
    ]
  },
  Firmware: {
    path: 'firmware',
    totalPath: '/m/firmware',
    data: {
      name: 'Model & Firmware',
      acccessLevel: UserLevel.SuperUser,
      menu: AdminMenuComponent,
    },
    children: [
      {
        path: '',
        component: FirmwareComponent,
        data: {
          button: CreateFirmwareButtonComponent,
        }
      },
      {
        path: ':modelId',
        component: FirmwareListComponent,
        data: {
          button: CreateButtonComponent
        }
      },
      {
        path: '**',
        redirectTo: '',
      }
    ],
  },
  MessageCenter: {
    path: 'message',
    totalPath: '/m/message',
    component: MessageComponent,
    data: {
      name: 'Message Center',
      acccessLevel: UserLevel.Normal,
    },
  },
  BackupAndRestore: {
    path: 'backupAndRestore',
    totalPath: '/m/backupAndRestore',
    component: BackupAndRestoreComponent,
    data: {
      name: 'Backup & Restore',
      acccessLevel: UserLevel.SuperUser,
      menu: AdminMenuComponent,
    }
  },

};

export const RouterConfigList: RouteConfig[] = [
  RouterConfig.Organization,
  RouterConfig.MobileApp,
  RouterConfig.Archive,
  RouterConfig.User,
  RouterConfig.Device,
  RouterConfig.Feedback,
  RouterConfig.OrganizationSetting,
  RouterConfig.Exam,
  RouterConfig.MessageCenter,
  RouterConfig.BackupAndRestore,
];

export function getHome(userData: UserData): string {
  return RouterConfigList.find((route: RouteConfig) => route.data.acccessLevel === userData.level).totalPath;
}
