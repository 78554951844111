import { Dayjs } from 'dayjs';
import { Page } from '../component/pagination/pagination.component';

export interface DeviceLog {
  content: string;
  createdAt: Dayjs;
  deviceId: number;
  id: number;
  organizationId: number;
  type: string;
  userId: number;
  name: string;
}

export interface DeviceLogResponse {
  list: DeviceLog[];
  page: Page;
}

export class DeviceLogStatus {
  static Connection = new DeviceLogStatus(1, 'Connection');
  static Test = new DeviceLogStatus(0, 'Test');
  static list = [
    DeviceLogStatus.Connection,
    DeviceLogStatus.Test,
  ];

  static findByCode(code: number | string) {
    return DeviceLogStatus.list.find((item: DeviceLogStatus) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}
