import { Component, OnInit } from '@angular/core';
import { getHome } from 'src/app/router/router-config';
import { Router } from '@angular/router';
import { UserData } from 'src/app/type/user';
import { UserService } from 'src/app/core/service/user/user.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-menu-logo',
  templateUrl: './menu-logo.component.html',
  styleUrls: ['./menu-logo.component.scss']
})
export class MenuLogoComponent implements OnInit {
  userData: UserData;
  imageUrl: SafeUrl = '/assets/images/logo.svg';
  constructor(
    private router: Router,
    private userService: UserService,
    private domSanitizer: DomSanitizer,
  ) {
    userService.getUserData().subscribe((userData: UserData) => {
      this.userData = userData;
      const url = environment.apiUrl + 'organizations/' + userData.organizationId + '/logo?token=' + userData.token + '&' + dayjs().unix();
      this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(url as string);
    });
  }

  ngOnInit() {
  }

  toHomePage() {
    const homeRoute = getHome(this.userData);
    this.router.navigate([homeRoute]);
  }

  showDefault() {
    const url = '/assets/images/logo.svg';
    this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(url as string);
  }

}
