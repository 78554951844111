import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { PageRequest } from 'src/app/component/pagination/pagination.component';
import { DeviceResponse, Device, DeviceStatus } from 'src/app/type/device';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { DeviceEditorPopupComponent } from 'src/app/popup/device-editor-popup/device-editor-popup.component';

interface TData {
  deviceId: number;
  organizationId: number;
  serialNumber: number;
  modelName: string;
  macAddress: string;
  firmwareVersion: number;
  hardwareVersion: number;
  createdAt: string;
  status: boolean;
  updatedAt: string;
}

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {
  tData: TData[];
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal: number;
  sort = 'serialNumber,DESC';
  constructor(
    private router: Router,
    private httpService: HttpService,
    private reloadService: ReloadServiceService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {
    this.getData();
    this.reloadService.reloadService.subscribe(() => {
      this.getData();
    });
  }

  edit(item: Device) {
    this.popupService.open(
      DeviceEditorPopupComponent,
      {
        id: item.deviceId,
      }
    );
    // this.router.navigate(['/m/device/edit/' + item.deviceId]);
  }

  getData(page = this.currentPage) {
    const search: PageRequest = {
      page,
      size: this.pageSize,
      sort: this.sort,
    };
    this.httpService.getDevice(search)
      .subscribe((res: DeviceResponse) => {
        this.tData = res.list.map((listItem: Device): TData => ({
          createdAt: listItem.createdAt.format(environment.dateTime),
          deviceId: listItem.deviceId,
          firmwareVersion: listItem.firmwareVersion,
          modelName: listItem.model.modelName,
          hardwareVersion: listItem.hardwareVersion,
          organizationId: listItem.organizationId,
          serialNumber: listItem.serialNumber,
          macAddress: listItem.macAddress,
          status: listItem.status === DeviceStatus.Enable,
          updatedAt: listItem.updatedAt.format(environment.dateTime),
        }));
        this.currentPage = res.page.currentPage;
        this.pageTotal = res.page.pageTotal;
      });
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.getData();
  }

  toggleStatus(status: boolean, itemId: number) {
    this.httpService.updateDevice({ status: status ? 1 : 0 }, itemId)
      .subscribe((res) => {
        this.getData();
      });
  }
}
