import { Injectable } from '@angular/core';
import { Storage } from 'src/app/lib/storage/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserData, UserLevel, UserStatus } from 'src/app/type/user';
import { HttpService } from '../../http/http.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private key = 'userData';
  private userStorage = new Storage<UserData>(this.key);
  private storage = this.userStorage.get();
  private initUserData: UserData = {
    level: this.storage && this.storage.level ? UserLevel.findByCode(this.storage.level.value) : undefined,
    organizationId: this.storage ? this.storage.organizationId : undefined,
    status: this.storage && this.storage.status ? UserStatus.findByCode(this.storage.status.value) : undefined,
    token: this.storage ? this.storage.token : undefined,
    userId: this.storage ? this.storage.userId : undefined,
    username: this.storage ? this.storage.username : 'guest',
    displayName: this.storage ? this.storage.displayName : 'guest',
    lastUrl: this.storage ? this.storage.lastUrl : '',
    sessionTimeout: 0,
    examShareLinkTimeout: 0,
  };
  private userSubject = new BehaviorSubject<UserData>(this.initUserData);
  private userData: UserData;
  constructor(
    private httpService: HttpService,
  ) {
    this.setUserData(this.initUserData)
  }

  getUserData(): Observable<UserData> {
    return this.userSubject.asObservable();
  }

  setUserData(data: UserData) {
    if (JSON.stringify(data) !== JSON.stringify(this.userData)) {
      this.userData = data;
      this.userStorage.set(this.userData);
      this.userSubject.next(this.userData);
    }
  }

  login(formData) {
    return this.httpService.login(formData)
      .pipe(
        tap((res: UserData) => {
          this.setUserData(res);
          if (formData.remember) {
            sessionStorage.setItem('username', formData.username);
          }
        })
      );
  }

  resetLogo() {
    this.userSubject.next(this.userData);
  }

  clean() {
    this.userData = this.initUserData;
    this.userStorage.remove();
    this.userSubject.next(this.userData);
  }
}
