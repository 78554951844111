import { Dayjs } from 'dayjs';
import { Page } from '../component/pagination/pagination.component';
import { Model } from './model';

export interface Device {
  createdAt: Dayjs;
  deviceId: number;
  firmwareVersion: number;
  hardwareVersion: number;
  macAddress: string;
  model: Model;
  organizationId: number;
  serialNumber: number;
  status: DeviceStatus;
  updatedAt: Dayjs;
}

export interface DeviceResponse {
  list: Device[];
  page: Page;
}

export class DeviceStatus {
  static Enable = new DeviceStatus(1, 'Enable');
  static Disable = new DeviceStatus(0, 'Disable');
  static list = [
    DeviceStatus.Enable,
    DeviceStatus.Disable,
  ];

  static findByCode(code: number | string) {
    return DeviceStatus.list.find((item: DeviceStatus) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}
