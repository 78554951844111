import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '../../core/http/http.service';
import { BackupRestoreService } from 'src/app/core/service/backup-restore/backup-restore.service';
import { SelectOption } from 'src/app/component/select/select.component';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';

@Component({
  selector: 'app-backup-and-restore',
  templateUrl: './backup-and-restore.component.html',
  styleUrls: ['./backup-and-restore.component.scss']
})
export class BackupAndRestoreComponent implements OnInit, OnDestroy {

  constructor(
    private httpService: HttpService,
    private backupRestoreService: BackupRestoreService,
    private alertService: AlertService,
  ) { }
  backupPath = '';
  isBackupProcessing: boolean;
  isRestoreProcessing: boolean;

  optionList: SelectOption[] = [];
  path: string;

  get isPathInvalid() {
    // 只能是數字、英文跟_-
    return !/^[a-zA-Z0-9\-_]*$/.test(this.backupPath);
  }

  ngOnInit() {
    this.getBackupOption();
    this.backupRestoreService.getBackupStatus.subscribe(isBackupProcessing => {
      // 剛備份好
      if (!this.isBackupProcessing && isBackupProcessing) {
        this.getBackupOption();
      }
      this.isBackupProcessing = isBackupProcessing;
    });

    this.backupRestoreService.getRestoreStatus.subscribe(isRestoreProcessing => {
      this.isRestoreProcessing = isRestoreProcessing;
    });
  }

  ngOnDestroy(): void {
  }

  startBackup() {
    if (this.checkExist()) {
      const alert: Alert = {
        message: 'Backup name already exist',
      };
      this.alertService.setAlert(alert);
    } else if (!this.isPathInvalid) {
      this.httpService.startBackup(this.backupPath).subscribe(() => {
        this.backupRestoreService.query();
      });
    } else {
      const alert: Alert = {
        title: 'error',
        message: 'Only letters(a-z,A-Z), numbers(0-9), -_ are allowed',
      };
      this.alertService.setAlert(alert);
    }
  }

  startRestore() {
    if (this.path) {
      this.httpService.startRestore(this.path).subscribe(() => {
        this.backupRestoreService.query();
      });
    }
  }

  private getBackupOption() {
    this.httpService.getBackupOption().subscribe((pathList: string[]) => {
      this.optionList = pathList.map(path => ({
        text: path,
        value: path,
      }));
      this.path = this.optionList[0].value;
    });
  }

  private checkExist(): boolean {
    return !!this.optionList.find(option => option.value === this.backupPath);
  }
}
