import { trigger, transition, style, animate, keyframes } from '@angular/animations';

export const fadeInOutAnimation =
  trigger('fadeInOutAnimation', [
    transition(':enter', [
      animate('.2s cubic-bezier(0.22, 0.73, 1, 1)',
        keyframes([
          style({ opacity: 0, offset: 0 }),
          style({ opacity: 1, offset: 1 })
        ]))
    ]),
    transition(':leave', [
      animate('.2s cubic-bezier(0.22, 0.73, 1, 1)',
        keyframes([
          style({ opacity: 1, offset: 0 }),
          style({ opacity: 0, offset: 1 })
        ]))
    ])
  ]);
// trigger('fadeInOutAnimation', [
//   transition(':enter', [
//     style({ opacity: '0' }),
//     animate('.5s ease-out', style({ opacity: '1' })),
//   ]),
// ])

export const zoomInAnimation =
  trigger('zoomInAnimation', [
    transition(':enter', [
      animate('.2s cubic-bezier(0.22, 0.73, 1, 1)',
        keyframes([
          style({ height: '100%', opacity: 0 }),
          style({ height: 0, opacity: 1 })
        ]))
    ]),
    transition(':leave', [
      animate('.2s cubic-bezier(0.22, 0.73, 1, 1)',
        keyframes([
          style({ height: 0, opacity: 1 }),
          style({ height: '100%', opacity: 0 })
        ]))
    ])
  ]);
