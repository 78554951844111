export class CopyText {
  static copy(content: any) {
    const copyArea = document.createElement('textarea');
    copyArea.value = content;
    copyArea.style.height = '0';
    copyArea.style.overflow = 'hidden';
    copyArea.style.position = 'fixed';
    document.body.appendChild(copyArea);
    copyArea.focus();
    copyArea.select();
    document.execCommand('copy');
    document.body.removeChild(copyArea);
  }
}
