export const environment = {
  production: true,
  apiUrl: 'http://104.199.167.212/apis/',
  dateTime: 'YYYY-MM-DD HH:mm:ss',
  date: 'YYYY-MM-DD',
  pageSize: 20,
  firstPage: 0,
  showRange: 5,
  title: 'Apache Cloud',
  version: 'v0.1.61',
};
