import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { environment } from 'src/environments/environment';
import { FeedbackResponse, Feedback } from 'src/app/type/feedback';
import { PageRequest } from 'src/app/component/pagination/pagination.component';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { MemoPopupComponent, MemoType } from 'src/app/popup/memo-popup/memo-popup.component';

interface TData {
  type: string;
  organizationName: string;
  content: string;
  date: string;
  username: string;
  sn: string;
}

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal;
  tData: TData[];
  sort = 'createdAt,DESC';
  constructor(
    private httpService: HttpService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {
    this.getFeedback();
  }

  getFeedback(page = this.currentPage) {
    const search: PageRequest = {
      page,
      size: this.pageSize,
      sort: this.sort,
    };
    this.httpService.getFeedback(search)
      .subscribe((res: FeedbackResponse) => {
        this.tData = res.list.map((feedback: Feedback): TData => ({
          type: feedback.type.text,
          content: feedback.content,
          date: feedback.createdAt.format(environment.dateTime),
          username: feedback.user.displayName,
          sn: feedback.serialNumber,
          organizationName: feedback.organizationName || '-',
        }));
        this.currentPage = res.page.currentPage;
        this.pageTotal = res.page.pageTotal;
      });
  }

  changePage(page: number) {
    this.getFeedback(page);
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.getFeedback(environment.firstPage);
  }

  openContentPopup(item: TData) {
    this.popupService.open(
      MemoPopupComponent,
      {
        title: MemoType.Content,
        message: item.content,
      }
    );
  }
}
