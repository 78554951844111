import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { ModelResponse, Model } from 'src/app/type/model';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';
import { ModelStatusService } from 'src/app/core/service/model/model-status.service';

interface ModelShow {
  id: number;
  name: string;
}

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminMenuComponent implements OnInit {

  constructor(
    private httpSevice: HttpService,
    private modelStatusService: ModelStatusService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }
  isOpenSubModel = false;
  modelList: ModelShow[];

  ngOnInit() {
    this.getFirmware();
    this.modelStatusService.hasReloadList().subscribe(() => {
      this.getFirmware();
    });
    this.modelStatusService.getCurrentModelId().subscribe((modelId: number) => {
      if (modelId) {
        this.isOpenSubModel = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  private getFirmware() {
    const search = {
      withLastVersionOfFirmware: true,
      all: true,
    };
    this.httpSevice.getModel(search).subscribe((res: ModelResponse) => {
      this.modelList = res.list.map((model: Model): ModelShow => ({
        name: model.modelName,
        id: model.modelId,
      }));
      this.changeDetectorRef.detectChanges();
    });
  }

  toggleModel() {
    this.isOpenSubModel = !this.isOpenSubModel;
    this.changeDetectorRef.detectChanges();
  }
}
