import { Component, HostListener, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { UserService } from 'src/app/core/service/user/user.service';
import { UserData } from 'src/app/type/user';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';
import { fadeInOutAnimation } from 'src/app/animation/common';
import { SearchHistoryService } from 'src/app/core/service/search-history/search-history.service';
import { PopupService } from '../popup-window/popup.service';
import { AccountEditorPopupComponent } from 'src/app/popup/account-editor-popup/account-editor-popup.component';

@Component({
  selector: 'app-user-button',
  templateUrl: './user-button.component.html',
  styleUrls: ['./user-button.component.scss'],
  animations: [fadeInOutAnimation]
})
export class UserButtonComponent {
  isOpen = false;
  name: string;
  @HostListener('document:click', ['$event']) close(event: MouseEvent) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private alertSerivce: AlertService,
    private elementRef: ElementRef,
    private searchHistoryService: SearchHistoryService,
    private popupService: PopupService,
  ) {
    userService.getUserData().subscribe((userData: UserData) => {
      this.name = userData.displayName;
    });
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.changeDetectorRef.detectChanges();
  }

  logout() {
    this.isOpen = false;
    const data: Alert = {
      title: 'Logout',
      message: 'Are you sure you want to logout this account?',
      clickText: 'Logout',
      click: () => {
        this.httpService.logout().subscribe(
          () => { this.redirectLogout(); },
          () => { this.redirectLogout(); },
        );
      },
      showCancel: true,
    };
    this.alertSerivce.setAlert(data);
  }

  openAccountEditor() {
    this.popupService.open(
      AccountEditorPopupComponent,
      {},
    );
    this.isOpen = false;
  }

  private redirectLogout() {
    this.userService.clean();
    this.searchHistoryService.cleanAll();
    const data: Alert = {
      title: 'Signing Out',
      message: 'please wait...',
      disableClick: true,
    };
    this.alertSerivce.setAlert(data, true);
    document.location.reload();
    // this.router.navigateByUrl('/login');
  }
}
