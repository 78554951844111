import { Component, OnInit } from '@angular/core';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { FirmwarePopupComponent } from 'src/app/popup/firmware-popup/firmware-popup.component';

@Component({
  selector: 'app-create-button',
  templateUrl: './create-button.component.html',
  styleUrls: ['./create-button.component.scss']
})
export class CreateButtonComponent implements OnInit {

  constructor(
    private popupService: PopupService
  ) { }

  ngOnInit() {
  }

  openCreatePopup() {
    this.popupService.open(
      FirmwarePopupComponent,
      {}
    );
  }
}
