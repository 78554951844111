import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { Organization, OrganizationResponse, Pacs, PacsResponse } from 'src/app/type/organization';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/service/user/user.service';
import { UserData, User } from 'src/app/type/user';
import { LoadingService } from 'src/app/core/service/loading/loading.service';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';
import { HttpProgressEvent } from '@angular/common/http';
import { CustomValidator } from 'src/app/core/validator/customValidator';
import { combineLatest, Observable } from 'rxjs';
import { FileData } from '../../../component/file-upload-input/file-upload-input.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PopupService } from '../../../component/popup-window/popup.service';
import { EmailPopupComponent } from '../../../popup/email-popup/email-popup.component';

@Component({
  selector: 'app-edit-organiz',
  templateUrl: './edit-organiz.component.html',
  styleUrls: ['./edit-organiz.component.scss']
})
export class EditOrganizComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuild: FormBuilder,
    private httpService: HttpService,
    private router: Router,
    private userService: UserService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private domSanitizer: DomSanitizer,
    private popupService: PopupService,
  ) { }
  smtpShouldShow = false;
  image: File;
  id: number;
  token: string;
  readonly IPRegular = /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))*$/;
  isSubmit = false;
  previewImage: string | SafeUrl = '';
  administrator: User;
  organizForm = this.formBuild.group({
    image: [''],
    organizationName: ['', [Validators.required]],
    sessionTimeout: ['', [Validators.pattern(/^\d*$/), Validators.required]],
    examShareLinkTimeout: ['', [Validators.pattern(/^\d*$/), Validators.required]],
    maxUserCount: ['', [Validators.required, Validators.pattern(/^\d*$/)]],
    status: [true, Validators.required],
    userId: [''],
  });
  workList = this.formBuild.group({
    workListIp: ['', [Validators.pattern(this.IPRegular)]],
    workListPort: ['', [Validators.max(65535), CustomValidator.number]],
    workListAeTitle: ['']
  }, { validator: CustomValidator.allOrNone });
  smtpForm = this.formBuild.group({
    smtpHost: ['', [Validators.pattern(this.IPRegular)]],
    smtpPort: ['', [Validators.max(65535), CustomValidator.number]],
    smtpUsername: [''],
    smtpPassword: [''],
    isTLS: [false],
  }, { validator: CustomValidator.requiredWithout(['isTLS']) });
  pacsList = this.formBuild.array([]);
  userForm = this.formBuild.group({
    displayName: ['', Validators.required],
    username: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: [''],
  });

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = parseInt(params['id'], 10);
      this.getData(this.id);
      this.setImage();
    });
    this.userService.getUserData().subscribe((userData: UserData) => {
      this.token = userData.token;
      this.setImage();
    });
  }

  setImage() {
    if (this.id && this.token) {
      this.previewImage = environment.apiUrl + 'organizations/' + this.id + '/logo?token=' + this.token;
    }
  }

  getErrorStatus(column: string, form: FormGroup = this.organizForm): boolean {
    const controls = form.get(column);
    const hasToCheck = this.isSubmit || controls.touched;
    return hasToCheck && !controls.valid;
  }

  getFormArrayErrorStatus(form: FormGroup, column: string): boolean {
    const controls = form.get(column);
    const hasToCheck = this.isSubmit || controls.touched;
    const valid = controls.valid;
    return hasToCheck && !valid;
  }

  getPacs(pacs?: Pacs) {
    return this.formBuild.group({
      id: [pacs ? pacs.id : ''],
      ip: [pacs ? pacs.ip : '', [Validators.pattern(this.IPRegular)]],
      aeTitle: [pacs ? pacs.aeTitle : ''],
      organizationAeTitle: [pacs ? pacs.organizationAETitle : ''],
      port: [pacs ? pacs.port : '', [Validators.max(65535), CustomValidator.number]],
      description: [pacs ? pacs.description : ''],
    }, { validator: CustomValidator.requiredWithout(['description', 'id']) });
  }

  deletePacs(index: number) {
    this.pacsList.removeAt(index);
  }

  getData(organizationId: number) {
    const serach = { organizationId };
    this.httpService.getOrganizations(serach)
      .subscribe((res: OrganizationResponse) => {
        const data = res.list[0];
        this.administrator = data.administrator;
        this.organizForm.patchValue({
          organizationName: data.organizationName,
          sessionTimeout: Math.floor(data.sessionTimeout / 60 / 60),
          examShareLinkTimeout: data.examShareLinkTimeout,
          maxUserCount: data.maxUserCount,
          status: data.status,
          userId: data.administrator.userId,
        });
        this.workList.patchValue({
          workListIp: data.workListIp,
          workListPort: data.workListPort,
          workListAeTitle: data.workListAeTitle,
        });
        this.smtpForm.patchValue({
          smtpHost: data.smtpHost,
          smtpPort: data.smtpPort,
          smtpUsername: data.smtpUsername,
          smtpPassword: data.smtpPassword,
          isTLS: data.isTLS,
        });
        this.userForm.patchValue({
          displayName: data.administrator.displayName,
          username: data.administrator.username,
          email: data.administrator.email,
        });
        this.getPacsData(organizationId);
      });
  }

  changeImage(fileData: FileData) {
    this.image = fileData.file;
    if (fileData.preview) {
      this.previewImage = this.domSanitizer.bypassSecurityTrustUrl(fileData.preview.toString());
    } else {
      this.userService.resetLogo();
    }
  }

  logoOnError(e: Event) {
    const dom = e.target as HTMLImageElement;
    dom.src = 'assets/images/upload.svg';
  }

  testWorkList() {
    if (this.workList.value.workListIp && this.workList.value.workListPort) {
      this.httpService.checkWorklist(this.workList.value.workListIp, this.workList.value.workListPort)
        .subscribe(() => {
          this.testSuccess();
        });
    } else {
      this.showTestInvalid();
    }
  }

  testPasc(pacsForm: FormGroup) {
    if (pacsForm.value.ip && pacsForm.value.port) {
      this.httpService.checkPacs(pacsForm.value).subscribe(() => { });
    } else {
      this.showTestInvalid();
    }
  }

  testSmtp() {
    if (this.smtpForm.valid && this.smtpForm.value.smtpPort) {
      const popupRef = this.popupService.open(
        EmailPopupComponent,
        {
          send: (from: string, to: string) => {
            const data = {
              password: this.smtpForm.value.smtpPassword,
              port: this.smtpForm.value.smtpPort,
              host: this.smtpForm.value.smtpHost,
              to,
              from,
              username: this.smtpForm.value.smtpUsername,
              auth: this.smtpForm.value.isTLS ? 'TLS' : 'NONE',
            };
            this.httpService.organizationSmtpTest(data)
              .subscribe((res) => {
                const alert: Alert = {
                  message: 'Is Sending an email'
                };
                this.alertService.setAlert(alert);
              });
            popupRef.destroy();
          }
        }
      );
    } else {
      const alert: Alert = {
        message: 'Missing required SMTP parameters',
      };
      this.alertService.setAlert(alert);
    }
  }

  back() {
    this.loadingService.stopLoading();
    this.router.navigate(['/m/organ']);
  }

  save() {
    this.isSubmit = true;
    if (this.organizForm.valid && this.smtpForm.valid && this.workList.valid && this.userForm.valid && this.pacsList.valid) {
      this.loadingService.setLoading();
      const data = Object.assign({}, this.organizForm.value, this.workList.value, this.smtpForm.value);
      data.status = +data.status;
      data.workListPort = data.workListPort || 0;
      data.smtpPort = data.smtpPort || 0;
      data.smtpAuth = data.isTLS ? 'TLS' : 'NONE';
      this.httpService.updateOrganizations(data, this.id)
        .subscribe(this.updateUser.bind(this));
    }
    if (this.workList.valid && this.workList.value.workListIp) {
      this.sendWorklistToAco();
    }
  }

  private updateUser() {
    const data = this.userForm.value;
    if (!data.password) {
      delete data.password;
    }
    data.organizationId = this.id;
    this.httpService.updateUser(data, this.organizForm.value.userId).subscribe((res: Organization) => {
      if (this.image) {
        this.setLogo();
      } else {
        this.updatePacs();
      }
    });
  }

  private setLogo() {
    const formData = new FormData();
    formData.append('file', this.image);
    this.loadingService.stopLoading();
    this.httpService.updateOrganizationImage(formData, this.id)
      .subscribe((res: HttpProgressEvent) => {
        switch (res.type) {
          case 1:
            const alert: Alert = {
              message: res.loaded + ' / ' + res.total,
              disableClick: true,
            };
            this.alertService.setAlert(alert);
            break;
          case 3:
          case 4:
            this.updatePacs();
            this.alertService.cleanAlert();
            break;
        }
      });
  }

  private updatePacs() {
    const pacsList: Observable<any>[] = this.pacsList.value
      .filter((formData) => formData.ip)
      .map(formData => {
        if (formData.id) {
          formData.organizationId = this.id;
          return this.httpService.udpatePacs(formData.id, formData);
        } else {
          formData.organizationId = this.id;
          return this.httpService.createPacs(formData);
        }
      });
    if (pacsList.length) {
      combineLatest(pacsList).subscribe((res) => {
        this.back();
      });
    } else {
      this.back();
    }
  }

  private getPacsData(organizationId: number) {
    if (this.pacsList.length > 0) {
      this.pacsList.clear();
    }
    
    this.httpService.getOrganizationPacs(organizationId)
      .subscribe((res: PacsResponse) => {
        const maxPacsCount = 2;
        res.list.forEach((item) => {
            if(this.pacsList.length >= maxPacsCount) {
             return; 
            }

            this.addPacs(item);
        })

        for(let i = maxPacsCount - this.pacsList.length; i > 0 ; i--) {
          this.addPacs();
        }
      });
  }

  addPacs(pacs?: Pacs) {
    this.pacsList.push(this.getPacs(pacs));
  }

  private getFormValue(...arg: AbstractControl[]) {
    let value = {};
    arg.forEach((item: AbstractControl) => {
      if (!Object.values(item.value).includes('')) {
        value = { ...value, ...item.value };
      }
    });
    return value;
  }

  resend() {
    this.httpService.resendEmail(this.administrator.userId).subscribe((res) => {
      console.log(res);
    });
  }

  showDefault() {
    // this.previewImage = '/assets/images/logo.svg';
  }

  private testSuccess() {
    const alert: Alert = {
      message: 'Send Success',
    };
    this.alertService.setAlert(alert);
  }

  private showTestInvalid() {
    const alert: Alert = {
      message: 'IP or Port is incurrect'
    };
    this.alertService.setAlert(alert);
  }

  private sendWorklistToAco() {
    const data = {
      aeTitle: this.workList.value.workListAeTitle,
      description: '',
      ip: this.workList.value.workListIp,
      organizationAeTitle: '',
      port: this.workList.value.workListPort,
    };
    this.httpService.updateWorklist(this.id, data).subscribe(() => {

    });
  }
}
