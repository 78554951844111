import { Component, OnInit, Input } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { SelectOption } from 'src/app/component/select/select.component';
import { DeviceStatus } from 'src/app/type/device';
import { ModelResponse, Model } from 'src/app/type/model';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';

@Component({
  selector: 'app-device-create-popup',
  templateUrl: './device-create-popup.component.html',
  styleUrls: ['./device-create-popup.component.scss']
})
export class DeviceCreatePopupComponent implements OnInit, Popup {

  @Input() data;
  constructor(
    private formBuild: FormBuilder,
    private httpService: HttpService,
    private reloadService: ReloadServiceService,
  ) { }

  readonly statusOption: SelectOption[] = [
    {
      text: DeviceStatus.Enable.text,
      value: DeviceStatus.Enable.value,
    },
    {
      text: DeviceStatus.Disable.text,
      value: DeviceStatus.Disable.value,
    },
  ];
  modelOption: SelectOption[] = [];
  isSubmit = false;
  deviceForm = this.formBuild.group({
    modelId: ['', Validators.required],
    serialNumber: ['', Validators.required],
    status: [this.statusOption[0], Validators.required],
  });

  ngOnInit() {
    this.getModel();
  }

  create() {
    this.isSubmit = true;
    if (this.deviceForm.valid) {
      const data = this.deviceForm.value;
      data.status = data.status ? 1 : 0;
      this.httpService.createDevice(data)
        .subscribe((res) => {
          this.reloadService.setReload();
          this.close();
        });
    }
  }

  getErrorStatus(column: string): boolean {
    const controls = this.deviceForm.get(column);
    return (this.isSubmit || controls.touched) && !controls.valid;
  }

  getModel() {
    this.httpService.getModel().subscribe((res: ModelResponse) => {
      this.modelOption = res.list.map((item: Model) => ({
        text: item.modelName,
        value: item.modelId,
      }));
    });
  }

  close() {
    this.data.close();
  }
}
