import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-head-images-name',
  templateUrl: './head-images-name.component.html',
  styleUrls: ['./head-images-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadImagesNameComponent implements OnInit {
  @Input() name: string;
  constructor() { }

  ngOnInit() {
  }

}
