import { Component, Input } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import * as dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/service/user/user.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

export interface DateRange {
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'app-download-range-popup',
  templateUrl: './download-range-popup.component.html',
  styleUrls: ['./download-range-popup.component.scss']
})
export class DownloadRangePopupComponent implements Popup {

  constructor(
    private userService: UserService,
    private ngbCalendar: NgbCalendar,
  ) {
    userService.getUserData().subscribe(userData => {
      this.token = userData.token;
    });
  }
  @Input() data;
  token: string;
  fromDate: NgbDate = this.ngbCalendar.getToday();
  toDate: NgbDate = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 10);;
  hoveredDate: NgbDate;
  readonly apiUrl = environment.apiUrl;
  get downloadUrl(): string {
    const start = dayjs().year(this.fromDate.year).month(this.fromDate.month - 1).date(this.fromDate.day);
    const end = this.toDate ? dayjs().year(this.toDate.year).month(this.toDate.month - 1).date(this.toDate.day) : start;
    return this.apiUrl
      + this.data.url
      + '?fromDate='
      + start.format(environment.date)
      + '&toDate='
      + (end ? end.format(environment.date) : start.format(environment.date))
      + '&token=' + this.token;
  }

  close() {
    this.data.close();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

}
