import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscribable, Subscription } from 'rxjs';
import { HttpService } from '../../http/http.service';
import { ArchiveResponse, Archive } from 'src/app/type/archive';
import { ExamResponse, ExamSeries, ExamProbe } from 'src/app/type/exam';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { UserData } from 'src/app/type/user';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Storage } from 'src/app/lib/storage/storage';

export interface ExamSeriesItem extends ExamSeries {
  img: string;
}
export type ArchiveListData = Archive[] | null;
export type ArchiveData = Archive | null;
export type SeriesListData = Map<string, ExamSeriesItem[]>;
export interface PatientInfo {
  examDate: string;
  id: string;
  sex: string;
  name: string;
  birthday: string;
  physician: string;
  accessionNumber: string;
  probe: ExamProbe;
}

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  private archiveList: ArchiveListData = null;
  private archiveListSubject = new BehaviorSubject<ArchiveListData>(this.archiveList);
  private selectArchive: ArchiveData = null;
  private selectArchiveSubject = new BehaviorSubject<ArchiveData>(this.selectArchive);
  private seriesListSub = new BehaviorSubject<ExamSeriesItem[]>([]);
  private series: ExamSeries = null;
  private seriesSubject = new BehaviorSubject<ExamSeries>(this.series);
  private seriesId = '';
  private seriesIdSubject = new BehaviorSubject<string>(this.seriesId);
  private patient: PatientInfo = {
    examDate: '',
    id: '',
    sex: '',
    name: '',
    birthday: '',
    physician: '',
    accessionNumber: '',
    probe: {
      modelName: '',
      preset: '',
      sn: '',
    },
  };
  private patientSubject = new BehaviorSubject<PatientInfo>(this.patient);
  private token: string;
  private isLoadingSeries = false;
  private isGettingExam = false;
  private readonly seriesKey = 'seriesKey';
  private seriesStorage = new Storage<string>(this.seriesKey);
  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.userService.getUserData().subscribe((userData: UserData) => {
      this.token = userData.token;
    });
  }

  getArchiveList(isAutoSelect: boolean): BehaviorSubject<Archive[]> {
    if (!this.archiveList) {
      this.getArchive(isAutoSelect);
    }
    return this.archiveListSubject;
  }

  private getArchive(isAutoSelect: boolean) {
    const param = { all: true };
    this.httpService.getArchive(param)
      .subscribe((res: ArchiveResponse) => {
        this.archiveList = res.list;
        if (isAutoSelect) {
          this.setSelectArchive(res.list[0]);
        }
        this.archiveListSubject.next(this.archiveList);
      });
  }

  setSelectArchive(selectArchive) {
    this.selectArchive = selectArchive;
    this.selectArchiveSubject.next(this.selectArchive);
  }

  setSelectArchiveById(archiveId: number) {
    if (this.archiveList) {
      const selectArchive = this.archiveList.find((archive: Archive) => archive.archiveId === archiveId);
      if (selectArchive) {
        this.setSelectArchive(selectArchive);
      }
    }
  }

  getSelectArchive(): BehaviorSubject<Archive> {
    return this.selectArchiveSubject;
  }

  querySeriesList(archiveId: number, examId: string) {
    if (!this.isGettingExam) {
      this.isGettingExam = true;
      this.httpService.getExam(archiveId, examId)
        .subscribe((res: ExamResponse) => {
          const seriesList = res.seriesList.map((item): ExamSeriesItem => {
            const img = item.imageType === 'Image'
              ? environment.apiUrl + '/overlays/' + item.combinedPath + '?token=' + this.token
              : environment.apiUrl + '/overlays/' + item.thumbnail + '?token=' + this.token;
            return {
              ...item,
              img,
              combinedPath: environment.apiUrl + '/overlays/' + item.combinedPath + '?token=' + this.token,
            };
          });
          this.seriesListSub.next(seriesList);
          this.patient = {
            examDate: res.dateTime ? res.dateTime.format(environment.dateTime) : '',
            id: res.patient.id,
            sex: res.patient.sex,
            name: res.patient.name,
            birthday: res.patient.birthday ? res.patient.birthday.format(environment.date) : '',
            physician: res.physician,
            accessionNumber: res.accessionNumber,
            probe: res.probe,
          };
          this.patientSubject.next(this.patient);
          this.isGettingExam = false;
        });
    }
  }

  getSeriesList(): Observable<ExamSeriesItem[]> {
    return this.seriesListSub;
  }

  getPatient(): BehaviorSubject<PatientInfo> {
    return this.patientSubject;
  }

  querySeries(archiveId: number, seriesId: string) {
    if (!this.isLoadingSeries) {
      this.isLoadingSeries = true;
      this.httpService.getExamSeries(archiveId, seriesId).subscribe((res: ExamSeries) => {
        this.series = res;
        this.isLoadingSeries = false;
        this.seriesSubject.next(this.series);
      }, () => {
        this.isLoadingSeries = false;
      });
    }
  }

  cleanSeriesData() {
    this.seriesSubject.next(null);
  }

  getSeries(): BehaviorSubject<ExamSeries> {
    return this.seriesSubject;
  }

  setSeriesId(seriesId: string) {
    this.seriesId = seriesId;
    this.seriesStorage.set(seriesId);
    this.seriesIdSubject.next(this.seriesId);
  }

  getSeriesId(): BehaviorSubject<string> {
    return this.seriesIdSubject;
  }
}
