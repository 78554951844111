import { Component, OnInit } from '@angular/core';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { IconType } from 'src/app/type/icon';
import { UploadMobilePopupComponent } from 'src/app/popup/upload-mobile-popup/upload-mobile-popup.component';

@Component({
  selector: 'app-button-mobile',
  templateUrl: './button-mobile.component.html',
  styleUrls: ['./button-mobile.component.scss']
})
export class ButtonMobileComponent implements OnInit {
  readonly iconPath = IconType.Add;
  constructor(
    private popupService: PopupService,
  ) { }

  ngOnInit() {
  }

  openPopup() {
    this.popupService.open(
      UploadMobilePopupComponent,
      {}
    );
  }

}
