import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { Organization, OrganizationResponse } from 'src/app/type/organization';
import { environment } from 'src/environments/environment';
import { PageRequest } from 'src/app/component/pagination/pagination.component';
import { Router } from '@angular/router';
import { Dayjs } from 'dayjs';

interface TData {
  createdAt: Dayjs;
  updateAt: Dayjs;
  examShareLinkTimeout: number;
  maxUserCount: number;
  organizationId?: number;
  organizationName: string;
  sessionTimeout: number;
  status: boolean;
  smtpHost: string;
  smtpPassword: string;
  smtpPort: number;
  smtpUsername: string;
  workListAeTitle: string;
  workListIp: string;
  workListPort: number;
}

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  tdata: TData[] = [];
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal;
  sort = 'organizationId,DESC';
  constructor(
    private httpService: HttpService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getData();
  }

  edit(item: Organization) {
    this.router.navigate(['/m/organ/edit/' + item.organizationId]);
  }

  getData(page = this.currentPage) {
    const search: PageRequest = {
      page,
      size: this.pageSize,
      sort: this.sort,
    };
    this.httpService.getOrganizations(search)
      .subscribe((data: OrganizationResponse) => {
        this.tdata = data.list.map((item: Organization): TData => ({
          createdAt: item.createdAt,
          updateAt: item.updateAt,
          examShareLinkTimeout: item.examShareLinkTimeout,
          maxUserCount: item.maxUserCount,
          organizationId: item.organizationId,
          organizationName: item.organizationName,
          sessionTimeout: item.sessionTimeout,
          status: item.status,
          smtpHost: item.smtpHost,
          smtpPassword: item.smtpPassword,
          smtpPort: item.smtpPort,
          smtpUsername: item.smtpUsername,
          workListAeTitle: item.workListAeTitle,
          workListIp: item.workListIp,
          workListPort: item.workListPort,
        }));
        this.currentPage = data.page.currentPage;
        this.pageTotal = data.page.pageTotal;
      });
  }

  changePage(page: number) {
    this.getData(page);
  }

  toggleStatus(status: boolean, itemId: number) {
    this.httpService.updateOrganizations({ status: status ? 1 : 0 }, itemId)
      .subscribe((res) => {
        this.getData();
      });
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.getData(environment.firstPage);
  }
}
