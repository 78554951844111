import { Component, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { UserService } from 'src/app/core/service/user/user.service';
import { UserData } from 'src/app/type/user';
import { OrganizationResponse, Organization, Pacs, PacsResponse } from 'src/app/type/organization';
import { Validators, FormBuilder, FormArray, FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';
import { LoadingService } from 'src/app/core/service/loading/loading.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CustomValidator } from 'src/app/core/validator/customValidator';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { DownloadRangePopupComponent } from 'src/app/popup/download-range-popup/download-range-popup.component';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import * as dayjs from 'dayjs';
import { FileData } from '../../component/file-upload-input/file-upload-input.component';
import { EmailPopupComponent } from '../../popup/email-popup/email-popup.component';
import { debounce, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-organization-setting',
  templateUrl: './organization-setting.component.html',
  styleUrls: ['./organization-setting.component.scss']
})
export class OrganizationSettingComponent implements OnDestroy {
  readonly IPRegular = /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))*$/;
  organizForm = this.formBuild.group({
    image: [''],
    organizationName: ['', [Validators.required]],
    sessionTimeout: ['', [Validators.pattern(/^\d*$/), Validators.required]],
    examShareLinkTimeout: ['', [Validators.pattern(/^\d*$/), Validators.required]],
    maxUserCount: ['', [Validators.required]],
    status: [false, [Validators.required]],
    workList: this.formBuild.group({
      workListIp: ['', [Validators.pattern(this.IPRegular)]],
      workListPort: ['', [Validators.max(65535), CustomValidator.number]],
      workListAeTitle: [''],
    }, { validator: CustomValidator.allOrNone }),
    pacsList: this.formBuild.array([]),
    smtpForm: this.formBuild.group({
      smtpHost: ['', [Validators.pattern(this.IPRegular)]],
      smtpPort: ['', [Validators.max(65535), CustomValidator.number]],
      smtpUsername: [''],
      smtpPassword: [''],
      isTLS: [true],
    }, { validator: CustomValidator.requiredWithout(['isTLS']) }),
  });
  smtpShouldShow = false;
  organizationID: number;
  previewImage: string | SafeUrl;
  isSubmit = false;
  image: File;
  userData: UserData;

  get pacsList(): FormArray {
    return this.organizForm.get('pacsList') as FormArray;
  }
  get workList(): FormGroup {
    return this.organizForm.get('workList') as FormGroup;
  }
  get smtpForm(): FormGroup {
    return this.organizForm.get('smtpForm') as FormGroup;
  }

  private userSubject: Subscription;
  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private formBuild: FormBuilder,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private domSanitizer: DomSanitizer,
    private popupService: PopupService,
  ) {
    this.loadingService.setLoading();
    this.userSubject = this.userService.getUserData()
      .pipe(
        debounceTime(1000),
      )
      .subscribe((userData: UserData) => {
        if (userData.organizationId) {
          this.organizationID = userData.organizationId;
          this.userData = userData;
          this.pacsList.clear();
          this.loadingService.setLoading();
          this.getData(this.organizationID);
          this.setImage(this.organizationID, userData.token);
        }
      });
  }

  ngOnDestroy() {
    this.userSubject.unsubscribe();
  }

  getData(organizationId: number = this.organizationID) {
    const search = { organizationId };
    this.httpService.getOrganizations(search)
      .subscribe((res: OrganizationResponse) => {
        const data = res.list[0];
        this.organizForm.patchValue({
          organizationName: data.organizationName,
          sessionTimeout: Math.floor(data.sessionTimeout / 60 / 60),
          // examShareLinkTimeout: data.examShareLinkTimeout,
          maxUserCount: data.maxUserCount,
          status: data.status,
        });
        this.workList.patchValue({
          workListIp: data.workListIp,
          workListPort: data.workListPort,
          workListAeTitle: data.workListAeTitle,
        });
        this.smtpForm.patchValue({
          smtpHost: data.smtpHost,
          smtpPort: data.smtpPort,
          smtpUsername: data.smtpUsername,
          smtpPassword: data.smtpPassword,
          isTLS: data.isTLS,
        });
        this.getPacsData(organizationId);
        this.loadingService.stopLoading();
      });
  }

  save() {
    this.isSubmit = true;
    if (this.organizForm.valid && this.smtpForm.valid && this.workList.valid && this.pacsList.valid) {
      this.loadingService.setLoading();
      const data = Object.assign({}, this.organizForm.value, this.workList.value, this.smtpForm.value);
      data.status = data.status ? 1 : 0;
      data.smtpAuth = data.isTLS ? 'TLS' : 'NONE';
      data.workListPort = data.workListPort || 0;
      data.smtpPort = data.smtpPort || 0;
      this.httpService.updateOrganizations(data, this.organizationID)
        .subscribe((res: Organization) => {
          if (this.image) {
            this.setLogo(this.organizationID);
          } else {
            this.updatePacs();
          }
        });
    }
    if (this.workList.valid && this.workList.value.workListIp) {
      this.sendWorklistToAco();
    }
  }

  setLogo(id: number) {
    const formData = new FormData();
    formData.append('file', this.image);
    this.httpService.updateOrganizationImage(formData, id)
      .subscribe(this.updatePacs.bind(this));
  }

  private updatePacs() {
    const pacsList: Observable<any>[] = this.pacsList.value
      .filter(formItem => formItem.ip)
      .map(formData => {
        if (formData.id) {
          formData.organizationId = this.organizationID;
          return this.httpService.udpatePacs(formData.id, formData);
        } else {
          formData.organizationId = this.organizationID;
          return this.httpService.createPacs(formData);
        }
      });
    if (pacsList.length) {
      combineLatest(pacsList).subscribe((res) => {
        this.showSuccess();
      });
    } else {
      this.showSuccess();
    }
  }

  private getFormValue(...arg: AbstractControl[]) {
    let value = {};
    arg.forEach((item: AbstractControl) => {
      if (!Object.values(item.value).includes('')) {
        value = { ...value, ...item.value };
      }
    });
    return value;
  }

  logoOnError(e: Event) {
    const dom = e.target as HTMLImageElement;
    dom.src = 'assets/images/upload.svg';
  }

  getErrorStatus(column: string, form: FormGroup = this.organizForm): boolean {
    const controls = form.get(column);
    const hasToCheck = this.isSubmit || controls.touched;
    return hasToCheck && !controls.valid;
  }

  getFormArrayErrorStatus(form: FormGroup, column: string): boolean {
    const controls = form.get(column);
    const hasToCheck = this.isSubmit || controls.touched;
    return hasToCheck && !controls.valid;
  }

  addPacs(pacs?: Pacs) {
    this.pacsList.push(this.getPacs(pacs));
  }

  deletePacs(index: number) {
    this.pacsList.removeAt(index);
  }

  setImage(id: number, token: string) {
    const url = environment.apiUrl + 'organizations/' + id + '/logo?token=' + token + '&' + dayjs().unix();
    this.previewImage = this.domSanitizer.bypassSecurityTrustUrl(url as string);
  }

  changeImage(fileData: FileData) {
    this.image = fileData.file;
    if (fileData.preview) {
      this.previewImage = this.domSanitizer.bypassSecurityTrustUrl(fileData.preview.toString());
    } else {
      this.userService.resetLogo();
    }
  }

  revert() {
    this.pacsList.clear();
    this.getData();
  }

  testWorkList() {
    if (this.workList.value.workListIp && this.workList.value.workListPort) {
      this.httpService.checkWorklist(this.workList.value.workListIp, this.workList.value.workListPort)
        .subscribe(() => {
          this.testSuccess();
        });
    } else {
      this.showTestInvalid();
    }
  }

  testPasc(pacsForm: FormGroup) {
    if (pacsForm.value.ip && pacsForm.value.port) {
      this.httpService.checkPacs(pacsForm.value).subscribe(() => {
        this.testSuccess();
      });
    } else {
      this.showTestInvalid();
    }
  }

  showSuccess() {
    const alert: Alert = {
      message: 'Update Success'
    };
    this.alertService.setAlert(alert);
    this.loadingService.stopLoading();
    this.userService.resetLogo();
  }

  testSmtp() {
    if (this.smtpForm.valid && this.smtpForm.value.smtpPort) {
      const popupRef = this.popupService.open(
        EmailPopupComponent,
        {
          send: (from: string, to: string) => {
            const data = {
              password: this.smtpForm.value.smtpPassword,
              port: this.smtpForm.value.smtpPort,
              host: this.smtpForm.value.smtpHost,
              to,
              from,
              username: this.smtpForm.value.smtpUsername,
              auth: this.smtpForm.value.isTLS ? 'TLS' : 'NONE',
            };
            this.loadingService.setLoading();
            this.httpService.organizationSmtpTest(data)
              .subscribe((res) => {
                const alert: Alert = {
                  message: 'Is Sending an email'
                };
                this.alertService.setAlert(alert);
                this.loadingService.stopLoading();
              });
            popupRef.destroy();
          }
        }
      );
    } else {
      const alert: Alert = {
        message: 'Missing required SMTP parameters',
      };
      this.alertService.setAlert(alert);
    }
  }

  private getPacsData(organizationId: number) {
    if (this.pacsList.length > 0) {
      this.pacsList.clear();
    }
    
    this.httpService.getOrganizationPacs(organizationId)
      .subscribe((res: PacsResponse) => {
        const maxPacsCount = 2;
        res.list.forEach((item) => {
            if(this.pacsList.length >= maxPacsCount) {
             return; 
            }

            this.addPacs(item);
        })

        for(let i = maxPacsCount - this.pacsList.length; i > 0 ; i--) {
          this.addPacs();
        }
      });

    this.loadingService.stopLoading();
  }

  getPacs(pacs?: Pacs) {
    return this.formBuild.group({
      id: [pacs ? pacs.id : ''],
      ip: [pacs ? pacs.ip : '', [Validators.pattern(this.IPRegular)]],
      aeTitle: [pacs ? pacs.aeTitle : ''],
      organizationAeTitle: [pacs ? pacs.organizationAETitle : ''],
      port: [pacs ? pacs.port : '', [Validators.max(65535), CustomValidator.number]],
      description: [pacs ? pacs.description : ''],
    }, { validator: CustomValidator.requiredWithout(['description', 'id']) });
  }

  downloadWorklistLog() {
    this.popupService.open(
      DownloadRangePopupComponent,
      {
        url: 'mwl/log',
      }
    );
  }

  downloadSMTPLog() {
    this.popupService.open(
      DownloadRangePopupComponent,
      {
        url: 'organizations/' + this.organizationID + '/smtp/log'
      }
    );
  }

  downloadSCPLog() {
    this.popupService.open(
      DownloadRangePopupComponent,
      {
        url: 'pacs/log',
      }
    );
  }

  private testSuccess() {
    const alert: Alert = {
      message: 'Send Success',
    };
    this.alertService.setAlert(alert);
  }

  private showTestInvalid() {
    const alert: Alert = {
      message: 'IP or Port is incurrect'
    };
    this.alertService.setAlert(alert);
  }

  private sendWorklistToAco() {
    const data = {
      aeTitle: this.workList.value.workListAeTitle,
      description: '',
      ip: this.workList.value.workListIp,
      organizationAeTitle: '',
      port: this.workList.value.workListPort,
    };
    this.httpService.updateWorklist(this.organizationID, data).subscribe(() => {

    });
  }
}
