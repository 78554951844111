import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { environment } from 'src/environments/environment';
import { MessageResponse, Message, MessageType } from 'src/app/type/message';
import { Router } from '@angular/router';

interface TDada extends Message {
  date: string;
  message: string;
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  tData: TDada[];
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal: number;
  constructor(
    private httpService: HttpService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.search();
  }

  detail(item: TDada) {
    const url = (item.type === MessageType.Exam)
      ? '/m/exam/' + item.archiveId + '/' + item.examId
      : '/m/exam/' + item.archiveId + '/' + item.examId + '/' + item.seriesId;
    this.router.navigate([url]);
  }

  search(page = this.currentPage) {
    const search = {
      page,
      size: this.pageSize,
    };
    this.httpService.getMessage(search).subscribe((res: MessageResponse) => {
      this.tData = res.list.map((item: Message): TDada => {
        const type = (item.type === MessageType.Exam) ? MessageType.Exam.text : MessageType.Series.text;
        const id = (item.type === MessageType.Exam)
          ? item.seriesId.substr(-6, 6)
          : item.examId.substr(-6, 6);
        return {
          ...item,
          date: item.createAt.format(environment.dateTime),
          message: `Sean has added a comment to the ${type} ${id}. You may click to read the comment and respond.`,
        };
      });
      this.currentPage = res.page.currentPage;
      this.pageSize = res.page.pageSize;
      this.pageTotal = res.page.pageTotal;
    });
  }
}
