import { Component, OnInit, ComponentRef } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { fadeInOutAnimation } from 'src/app/animation/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInOutAnimation]
})
export class HeaderComponent implements OnInit {
  // title: string;
  buttomTemplate: ComponentRef<any>;
  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd && event.snapshot.children.length === 0) {
        // this.title = event.snapshot.data.title;
        this.buttomTemplate = event.snapshot.data.button;
      }
    });
  }

  ngOnInit() {
  }

}
