import { AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';

export class CustomValidator {
  static equalPassword(control: AbstractControl): ValidationErrors | null {
    if (control.parent && control.parent.controls['password'] && control.parent.controls['password'].value !== control.value) {
      return { validEqualPassword: false };
    }
    return null;
  }

  static equalTo(param: string) {
    return (formControl: FormGroup) => {
      if (formControl.parent) {
        return (formControl.parent.value[param] === formControl.value) ? null : { notSame: true };
      } else {
        return null;
      }
    };
  }

  static allOrNone(control: FormGroup): ValidationErrors | null {
    const empty = Object.keys(control.value).every((key: string) => (typeof control.value[key] === 'boolean') ? true : control.value[key]);
    const full = Object.keys(control.value).every((key: string) => !control.value[key]);
    const isPass = empty || full;
    Object.keys(control.controls).forEach((key: string) => {
      let errors = control.controls[key].errors;
      if (!isPass && !control.controls[key].value) {
        errors = {
          ...errors,
          validAllOrNone: true
        };
      } else if (errors) {
        delete errors.validAllOrNone;
      }
      control.controls[key].setErrors(errors);
    });
    return null;
  }

  static requiredWithout(paramList: string[]) {
    return (formControl: FormGroup) => {
      const formValue = Object.assign({}, formControl.value);
      paramList.forEach((para: string) => {
        delete formValue[para];
      });
      const empty = Object.keys(formValue).every((key: string) => formControl.value[key]);
      const full = Object.keys(formValue).every((key: string) => !formControl.value[key]);
      const isPass = empty || full;
      Object.keys(formControl.controls).forEach((key: string) => {
        let errors = formControl.controls[key].errors;
        if (!isPass && !paramList.includes(key) && !formControl.controls[key].value) {
          errors = {
            ...errors,
            requird: true
          };
          formControl.controls[key].setErrors(errors);
        } else if (errors) {
          delete errors.requird;
          formControl.controls[key].setErrors(errors);
        }
      });
      return null;
    };
  }

  static number(control: AbstractControl) {
    if (control.value && control.value.toString() !== '') {
      const errors = control.errors;
      if (Number.isNaN(Number(control.value))) {
        return { NotANumber: control.value };
      } else {
        return null;
      }
    }
  }
}
