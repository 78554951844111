import * as dayjs from 'dayjs';

export interface BackupRestoreResponse {
  isProcessing: boolean;
  date?: dayjs.Dayjs;
  status?: BackupRestoreStatus;
}

export class BackupRestoreStatus {
  static INIT = new BackupRestoreStatus('INIT');
  static ACO = new BackupRestoreStatus('ACO');
  static DATABASE = new BackupRestoreStatus('DATABASE');
  static LOGO = new BackupRestoreStatus('LOGO');
  static APP = new BackupRestoreStatus('APP');
  static FIRMWARE = new BackupRestoreStatus('FIRMWARE');
  static DEVICE_LOG = new BackupRestoreStatus('DEVICE_LOG');
  static FINISHED = new BackupRestoreStatus('FINISHED');

  static List = [
    BackupRestoreStatus.INIT,
    BackupRestoreStatus.ACO,
    BackupRestoreStatus.DATABASE,
    BackupRestoreStatus.LOGO,
    BackupRestoreStatus.APP,
    BackupRestoreStatus.FIRMWARE,
    BackupRestoreStatus.DEVICE_LOG,
    BackupRestoreStatus.FINISHED,
  ];

  static findByText(text: string): BackupRestoreStatus {
    return BackupRestoreStatus.List.find((item) => item.content === text);
  }

  constructor(
    private content: string,
  ) { }
}
