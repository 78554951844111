// export class ArchiveStatus {
//   static Enable = new ArchiveStatus(1, 'Enable');
//   static Disable = new ArchiveStatus(0, 'Disable');
//   static list = [
//     ArchiveStatus.Enable,
//     ArchiveStatus.Disable,
//   ];

//   static findByCode(code: number | string) {
//     return ArchiveStatus.list.find((item: ArchiveStatus) => item.value === +code);
//   }

//   constructor(readonly value: number, readonly text: string) { }
// }

export type ArchiveId = number;

export interface Archive {
  archiveId: ArchiveId;
  archiveName: string;
  organizationId: number;
  description: string;
  status: ArchiveStatus,
  // createdAt: Dayjs;
  // status: ArchiveStatus,
  // updatedAt: Dayjs;
}

export interface ArchiveResponse {
  list: Archive[];
}

export class ArchiveStatus {
  static Enable = new ArchiveStatus(true, 'Enable');
  static Disable = new ArchiveStatus(false, 'Disable');
  static list = [
    ArchiveStatus.Enable,
    ArchiveStatus.Disable,
  ];

  static findByCode(code: boolean) {
    return ArchiveStatus.list.find((item: ArchiveStatus) => item.value === code);
  }

  constructor(readonly value: boolean, readonly text: string) { }
}
