import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { Archive } from 'src/app/type/archive';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import * as  dayjs from 'dayjs';
import { ExamListResponse, Exam, PacsStoreStatus } from 'src/app/type/exam';
import { Pacs } from 'src/app/type/organization';
import { Router, ActivatedRoute, ActivationEnd, Event } from '@angular/router';
import { ExamService, ExamSeriesItem } from 'src/app/core/service/exam/exam.service';
import { fadeInOutAnimation } from 'src/app/animation/common';
import { SearchHistoryService } from 'src/app/core/service/search-history/search-history.service';
import { ShareExam } from 'src/app/type/share';
import { mergeMap, tap, filter, map } from 'rxjs/operators';
import { ShareLinkPopupComponent, ShareType } from 'src/app/popup/share-link-popup/share-link-popup.component';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { isNgTemplate, HtmlTagDefinition } from '@angular/compiler';
import { UserService } from 'src/app/core/service/user/user.service';
import { arch } from 'os';
import { isUndefined } from 'util';
import { truncateSync } from 'fs';
import { send } from 'process';

interface TData {
  id: string;
  seriesId: string;
  date: string;
  patientId: string;
  name: string;
  sex: string;
  birthday: string;
  numberOfSeries: number;
  PacsSendStatus: PacsSendStatusCode[];
}

enum PacsSendStatusCode {
  NotSend = "X",
  PartialSend = "\\",
  AllSend = "V"
}

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss'],
  animations: [fadeInOutAnimation]
})
export class ExamComponent implements OnInit, OnDestroy {
  searchForm = this.formBuilder.group({
    patientId: [''],
    startTime: [dayjs().subtract(1, 'month').format(environment.date)],
    endTime: [dayjs().format(environment.date)],
  });
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal: number;
  examList: TData[];
  archiveId: number;
  seriesList: ExamSeriesItem[];
  openItem;
  sort = 'date,DESC'; // 預設日期
  hoverItem: string;
  earliestDate: dayjs.Dayjs = dayjs();
  url: string;
  routerSubscription: Subscription;
  readonly apiPath = environment.apiUrl;
  pacsList : Pacs[] = [];
  getSelectArchive: Subscription;
  getSeriesMap: Subscription;
  constructor(
    private httpService: HttpService,
    private formBuilder: FormBuilder,
    private router: Router,
    private examService: ExamService,
    private searchHistoryService: SearchHistoryService,
    private popupService: PopupService,
    private userService: UserService
  ) {
    this.getUrl();
  }

  ngOnInit() {
    this.getSelectArchive = this.examService.getSelectArchive()
      .pipe(
        filter(archive => !!archive),
        tap((archive: Archive) => { this.archiveId = archive.archiveId; }),
        mergeMap(() => this.httpService.getExamEarliestDate(this.archiveId))
      )
      .subscribe((earliestDate: dayjs.Dayjs) => {
        const searchHistory = this.searchHistoryService.getParam();
        this.earliestDate = earliestDate;
        if (searchHistory && searchHistory.archiveId === this.archiveId) {
          this.searchForm.patchValue({
            patientId: searchHistory.patientId,
            startTime: dayjs(searchHistory.fromDate).format(environment.date),
            endTime: dayjs(searchHistory.toDate).format(environment.date),
          });
          this.sendSearch(this.archiveId, searchHistory);
          const openItem = this.searchHistoryService.getHoverItem();
          if (openItem) {
            this.querySeries(openItem);
          }
        } else {
          this.searchForm.patchValue({
            startTime: dayjs(earliestDate).format(environment.date)
          });
          this.openItem = null;
          this.search();
        }
      });
    this.examService.getSeriesList().subscribe((seriesList) => {
      this.seriesList = seriesList;
    });

    this.userService.getUserData().subscribe((userData)=> {
      this.httpService.getOrganizationPacs(userData.organizationId).subscribe(
        (pacsList) => {
          this.pacsList = pacsList.list;
        }
      );
    });
  }

  ngOnDestroy() {
    this.getSelectArchive.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  getErrorStatus(column: string): boolean {
    const controls = this.searchForm.get(column);
    return (this.searchForm || controls.touched) && !controls.valid;
  }

  search(page = environment.firstPage) {
    this.currentPage = page;
    if (this.searchForm.valid) {
      const data = {
        page,
        size: this.pageSize,
        patientId: this.searchForm.value.patientId,
        patientName: this.searchForm.value.patientId,
        fromDate: dayjs(this.searchForm.value.startTime).format(environment.dateTime),
        toDate: dayjs(this.searchForm.value.endTime).set('hour', 23).set('minute', 59).set('second', 59).format(environment.dateTime),
        sort: this.sort,
      };
      this.sendSearch(this.archiveId, data);
    }
  }

  keyDown(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  cleanPatient() {
    this.searchForm.patchValue({ patientId: '' });
    this.search();
  }

  private sendSearch(archiveId: number, data) {
    if (archiveId) {
      this.httpService.getExamList(archiveId, data).subscribe((res: ExamListResponse) => {
        this.examList = res.list.map((item: Exam) : TData => {
          return {
                id: item.id,
                patientId: item.patient.id,
                name: item.patient.name,
                seriesId: item.id,
                sex: item.patient.sex,
                numberOfSeries: item.seriesList.length,
                birthday: item.patient.birthday && item.patient.birthday.format(environment.date),
                date: item.date.format(environment.dateTime),
                PacsSendStatus: this.pacsList.map( (pacs : Pacs) : PacsSendStatusCode => {
                    return this.checkExamPacsStatus(item, pacs);
                }),
              };
        });

        this.currentPage = res.page.currentPage;
        this.pageTotal = res.page.pageTotal;
        data = {
          ...data,
          archiveId
        };
        this.searchHistoryService.setHistory(data);
      });
    }
  }

  private checkExamPacsStatus(item: Exam, pacs: Pacs) : PacsSendStatusCode {
    let sendCount = 0;

    for(var series of item.seriesList) {
      for(var status of series.pacsStoreStatus) {
        if(this.samePacsDestination(pacs, status) && status.stored) {
          sendCount++;
        }
      }
    }

    if(sendCount == item.seriesList.length) {
      return PacsSendStatusCode.AllSend;
    }

    if(sendCount == 0) {
      return PacsSendStatusCode.NotSend;
    }

    return PacsSendStatusCode.PartialSend;
  }

  private samePacsDestination(lhs: Pacs, rhs: PacsStoreStatus) {
    let same = true;
    same = same && (lhs.ip == rhs.ip);
    same = same && (lhs.port == rhs.port);
    return same;
  }
  
  allTime() {
    this.searchForm.patchValue({
      startTime: this.earliestDate.format(environment.date),
      endTime: dayjs().format(environment.date),
    });
    this.search();
  }

  aMonth() {
    this.searchForm.patchValue({
      startTime: dayjs().subtract(1, 'month').format(environment.date),
      endTime: dayjs().format(environment.date),
    });
    this.search();
  }

  aWeek() {
    this.searchForm.patchValue({
      startTime: dayjs().subtract(1, 'week').format(environment.date),
      endTime: dayjs().format(environment.date),
    });
    this.search();
  }

  today() {
    this.searchForm.patchValue({
      startTime: dayjs().format(environment.date),
      endTime: dayjs().format(environment.date),
    });
    this.search();
  }

  changePage(page: number) {
    this.search(page);
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.search(environment.firstPage);
  }

  isActive(range: 'today' | 'aWeek' | 'aMonth' | 'allTime') {
    let isActive = false;
    const startTime = this.searchForm.value.startTime;
    const endTime = this.searchForm.value.endTime;
    const today = dayjs().format(environment.date);
    switch (range) {
      case 'today':
        isActive = (startTime === dayjs().format(environment.date) && endTime === today);
        break;
      case 'aWeek':
        isActive = (startTime === dayjs().subtract(1, 'week').format(environment.date) && endTime === today);
        break;
      case 'aMonth':
        isActive = (startTime === dayjs().subtract(1, 'month').format(environment.date) && endTime === today);
        break;
      case 'allTime':
        isActive = (startTime === this.earliestDate.format(environment.date) && endTime === today);
        break;
    }
    return isActive;
  }

  toggleSub(exam: TData, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    if (exam.numberOfSeries > 0) {
      this.searchHistoryService.setHoverItem(exam.id);
      this.seriesList = null;
      this.querySeries(exam.id);
    }
  }

  private querySeries(examId: string) {
    this.openItem = (this.openItem === examId) ? '' : examId;
    this.examService.querySeriesList(this.archiveId, examId);
  }

  hasSent(item: TData) {
    for (var status of item.PacsSendStatus) {
      if(status != PacsSendStatusCode.AllSend) {
        return false;
      }
    }

    return true;
  }

  toDetail(item: TData) {
    this.router.navigate([this.url + this.archiveId + '/' + item.id]);
  }

  toThisExam(archiveId: string, itemId: string, seriesId: string) {
    this.examService.setSeriesId(seriesId);
    this.router.navigateByUrl(this.url + archiveId + '/' + itemId + '/seriesId');
  }

  sendExamToPacs(archiveId: number, item: TData) {
    // just trigger send to pacs api and let the backend do all the this.
    this.httpService.sendExamToPacs(archiveId, item.id).subscribe(() => {});
  }

  shareExam(examId: string) {
    this.httpService.getShareExamKey(this.archiveId, examId).subscribe((res: ShareExam) => {
      this.popupService.open(
        ShareLinkPopupComponent,
        {
          link: location.protocol + '//' + location.hostname + '/s/' + res.key,
          type: ShareType.Exam,
        }
      );
    });
  }

  private getUrl() {
    this.routerSubscription = this.router.events.pipe(
      filter((event: Event) => event instanceof ActivationEnd && event.snapshot.children.length === 0),
      map((event: ActivatedRoute) => event.snapshot.data.url),
    ).subscribe((url: string) => {
      this.url = url;
    });
  }
}
