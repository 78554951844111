import { Component, OnInit, HostBinding } from '@angular/core';
import { LoadingService } from 'src/app/core/service/loading/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  isShow: boolean;
  constructor(
    private subject: LoadingService,
  ) { }

  @HostBinding('style.display') get display() {
    return this.isShow ? 'flex' : 'none';
  }

  ngOnInit() {
    this.subject.getLoading().subscribe((show: boolean) => {
      this.isShow = show;
    });
  }

}
