import { Dayjs } from 'dayjs';
import { Page } from '../component/pagination/pagination.component';

export interface MobileApp {
  appId: number;
  createdAt: Dayjs;
  memo: string;
  status: MobileAppStatus;
  updatedAt: Dayjs;
  version: number;
  versionName: string;
}

export interface MobileAppResponse {
  list: MobileApp[];
  page: Page;
}

export class MobileAppStatus {
  static Enable = new MobileAppStatus(1, 'Enable');
  static Disable = new MobileAppStatus(0, 'Disable');
  static list = [
    MobileAppStatus.Enable,
    MobileAppStatus.Disable,
  ];

  static findByCode(code: number | string) {
    return MobileAppStatus.list.find((item: MobileAppStatus) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}
