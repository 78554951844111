import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-organiz',
  templateUrl: './button-organiz.component.html',
  styleUrls: ['./button-organiz.component.scss']
})
export class ButtonOrganizComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
