import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface Page {
  currentPage: number;
  pageSize: number;
  pageTotal: number;
}

export interface PageRequest {
  page: number;
  size: number;
  sort?: string; // =CreatedAt,DESC  資料庫欄位,排序方向
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Output() changePage = new EventEmitter<number>();
  @Input() currentPage = environment.firstPage;
  @Input() pageSize = environment.pageSize;
  @Input() pageTotal = 1;
  offset = 1 - environment.firstPage;
  readonly showRange = new Array(environment.showRange);
  constructor() {}

  ngOnInit(){
    this.offset = 1 - this.currentPage;
  }

  toPage(disable: boolean, num: number) {
    if (!disable) {
      this.changePage.emit(num);
    }
  }

}
