import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { Radio } from 'src/app/component/radio-input/radio-input.component';
import { FeedbackType } from 'src/app/type/feedback';
import { ActivatedRoute } from '@angular/router';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';

interface QueryParams {
  token: string;
  sn: string;
}

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss']
})
export class SuggestionComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
  ) { }

  readonly optionList: Radio[] = FeedbackType.list.map((type: FeedbackType) => ({
    text: type.text,
    value: type,
  }));
  form = this.formBuilder.group({
    type: [this.optionList[0]],
    content: ['', Validators.required],
  });
  token: string;
  sn: string;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: QueryParams) => {
      this.token = params.token;
      this.sn = params.sn;
    });
  }

  send() {
    if (this.form.valid) {
      const data = {
        content: this.form.value.content,
        type: this.form.value.type.value,
        serialNumber: this.sn,
      };
      this.httpService.sendFeedbackWithToken(data, this.token).subscribe(() => {
        const alert: Alert = {
          message: '成功送出',
        };
        this.alertService.setAlert(alert);
        this.form.reset();
        this.form.controls.type.patchValue(this.optionList[0]);
      });
    }
  }

  close() {
    window.opener = null;
    window.open('', '_self');
    window.close();
  }
}
