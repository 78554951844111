import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/http/http.service';
import { MobileAppResponse, MobileApp, MobileAppStatus } from 'src/app/type/mobileApp';
import { PageRequest } from 'src/app/component/pagination/pagination.component';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';
import { UploadMobilePopupComponent } from 'src/app/popup/upload-mobile-popup/upload-mobile-popup.component';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { MemoPopupComponent, MemoType } from 'src/app/popup/memo-popup/memo-popup.component';

interface TData extends MobileApp {
  date: string;
}

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss']
})
export class MobileAppComponent implements OnInit {
  tData: TData[];
  currentPage = environment.firstPage;
  pageSize = environment.pageSize;
  pageTotal;
  sort = 'version,DESC';
  MobileAppStatus = MobileAppStatus;
  constructor(
    private httpService: HttpService,
    private reloadService: ReloadServiceService,
    private popupService: PopupService,
  ) {
    reloadService.reloadService.subscribe(() => {
      this.getData();
    });
  }

  ngOnInit() {
    this.getData();
  }

  getData(page = this.currentPage) {
    const search: PageRequest = {
      page,
      size: this.pageSize,
      sort: this.sort,
    };
    this.httpService.getMobileApp(search)
      .subscribe((res: MobileAppResponse) => {
        this.tData = res.list.map((listItem: MobileApp): TData => ({
          ...listItem,
          date: listItem.updatedAt.format(environment.dateTime),
        }));
        this.currentPage = res.page.currentPage;
        this.pageTotal = res.page.pageTotal;
      });
  }

  openEditPopup(item: TData) {
    this.popupService.open(
      UploadMobilePopupComponent,
      {
        mobelApp: item,
        isEdit: true,
      }
    );
  }

  toggleStatus(status: boolean, itemId: number) {
    this.httpService.updateMobileApp(itemId, { status: status ? 1 : 0 }).subscribe(() => {
      this.getData();
    });
  }

  openMemoPopup(item: TData) {
    this.popupService.open(
      MemoPopupComponent,
      {
        title: MemoType.Memo,
        message: item.memo,
      }
    );
  }

  sortBy(column: string) {
    this.sort = (this.sort === column + ',DESC') ? column + ',ASC' : column + ',DESC';
    this.getData(environment.firstPage);
  }
}
