import { Page } from '../component/pagination/pagination.component';
import { Dayjs } from 'dayjs';

export interface UserData {
  level?: UserLevel;
  organizationId?: number;
  status?: UserStatus;
  token?: string;
  userId?: number;
  username: string;
  displayName: string;
  lastUrl?: string;
  sessionTimeout: number;
  examShareLinkTimeout: number;
}

export interface User {
  appVersion: number;
  createdAt: Dayjs;
  displayName: string;
  email: string;
  level: UserLevel;
  loggedInAt?: Dayjs;
  organizationId: number;
  status: UserStatus;
  updatedAt: Dayjs;
  userId: number;
  username: string;
}

export interface UserResponse {
  list: User[];
  page: Page;
}

export class UserStatus {
  static Enable = new UserStatus(1, 'Enable');
  static Disable = new UserStatus(0, 'Disable');
  static list = [
    UserStatus.Enable,
    UserStatus.Disable,
  ];

  static findByCode(code: number | string) {
    return UserStatus.list.find((item: UserStatus) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}

export class UserLevel {
  static SuperUser = new UserLevel(2, 'SuperUser');
  static OrganizationAdmin = new UserLevel(1, 'OrganizationAdmin');
  static Normal = new UserLevel(0, 'Normal');
  static list = [
    UserLevel.SuperUser,
    UserLevel.OrganizationAdmin,
    UserLevel.Normal,
  ];

  static findByCode(code: number | string) {
    return UserLevel.list.find((item: UserLevel) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}
