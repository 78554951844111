import { Component, OnInit, HostBinding, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CheckboxComponent implements ControlValueAccessor {
  myValue: boolean;
  onChange: (value) => {};
  onTouched: (e) => {};
  @HostBinding('class.check')
  get isCheck() {
    return this.myValue;
  }

  @HostListener('click', ['$event']) toggle(e: MouseEvent) {
    const dom = e.target as HTMLElement;
    if (!dom.attributes['disable']) {
      this.value = !this.myValue;
    }
  }

  get value() {
    return this.myValue;
  }

  set value(value: boolean) {
    this.myValue = value;
    this.notifyValueChange(value);
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  constructor() { }

  writeValue(value: any): void {
    this.myValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
