import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/service/user/user.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  lastUrl: string;
  version = environment.version;
  constructor(
    private formBuild: FormBuilder,
    private userService: UserService,
    private router: Router,
  ) { }

  loginForm = this.formBuild.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
    remember: [true],
  });
  isSubmit = false;
  ngOnInit() {
    const username = sessionStorage.getItem('username') || '';
    this.loginForm.patchValue({
      username
    });
  }

  login() {
    this.isSubmit = true;
    this.loginForm.get('username').setErrors(null);
    this.loginForm.get('password').setErrors(null);
    if (this.loginForm.valid) {
      this.userService.login(this.loginForm.value)
        .subscribe(() => {
          this.router.navigate(['/m']);
        }, (res) => {
          if (res.error.error === 21) {
            this.loginForm.get('username').setErrors({ error: true });
            this.loginForm.get('username').markAsTouched();
            this.loginForm.get('password').setErrors({ error: true });
            this.loginForm.get('password').markAsTouched();
          } else {
            throw res;
          }
        });
    }
  }

  getError(column) {
    const controls = this.loginForm.get(column);
    const hasToCheck = this.isSubmit && controls.touched;
    return hasToCheck && !controls.valid;
  }

  setRemember() {
    this.loginForm.patchValue({
      remember: !this.loginForm.value.remember
    });
  }
}
