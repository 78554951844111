import { Dayjs } from 'dayjs';
import { Page } from '../component/pagination/pagination.component';
import { ArchiveId } from './archive';

export interface Message {
  archiveId: ArchiveId;
  createAt: Dayjs;
  updateAt: Dayjs;
  examId: string;
  seriesId: string;
  id: number;
  status: MessageStatus;
  type: MessageType;
  userId: number;
  username: string;
  message: string;
}

export interface MessageResponse {
  list: Message[];
  page: Page;
}

export class MessageType {
  static Exam = new MessageType(0, 'exam');
  static Series = new MessageType(1, 'series');

  static list = [
    MessageType.Exam,
    MessageType.Series,
  ];

  static findByCode(code: number | string) {
    return MessageType.list.find((item: MessageType) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}

export class MessageStatus {
  static Unread = new MessageStatus(0, 'Unread');
  static Read = new MessageStatus(1, 'Read');
  static list = [
    MessageStatus.Unread,
    MessageStatus.Read,
  ];

  static findByCode(code: number | string) {
    return MessageStatus.list.find((item: MessageStatus) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}
