import { Component, OnInit, Input } from '@angular/core';
import { SelectOption } from 'src/app/component/select/select.component';
import { UserStatus, User, UserResponse } from 'src/app/type/user';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { ArchiveResponse, Archive } from 'src/app/type/archive';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { combineLatest } from 'rxjs';
import { Acl } from 'src/app/type/acl';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';
import { AlertService, Alert } from 'src/app/core/service/alert/alert.service';
import { LoadingService } from '../../core/service/loading/loading.service';
import { UserService } from '../../core/service/user/user.service';

@Component({
  selector: 'app-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.scss']
})
export class UserPopupComponent implements OnInit, Popup {
  @Input() data;
  readonly statusOption: SelectOption[] = [
    {
      text: UserStatus.Enable.text,
      value: UserStatus.Enable.value,
    },
    {
      text: UserStatus.Disable.text,
      value: UserStatus.Disable.value,
    },
  ];
  userForm = this.formBuilder.group({
    displayName: ['', [Validators.required]],
    username: ['', [Validators.required]],
    email: ['', [Validators.email]],
    password: [''],
    status: [this.statusOption[0], [Validators.required]],
    aclFrom: this.formBuilder.array([]),
  });
  isSubmit = false;
  isDisable = true;
  id: number;
  get aclFormArray() {
    return (this.userForm.get('aclFrom') as FormArray).controls;
  }
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private httpService: HttpService,
    private reloadService: ReloadServiceService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.id = this.data.id;
    if (this.id) {
      this.getUserData(this.id);
    } else {
      this.getArchive();
      this.userForm.get('password').setValidators(Validators.required);
    }
    this.userService.getUserData().subscribe(userData => {
      this.isDisable = userData.userId === this.id;
    });
  }

  send() {
    this.isSubmit = true;
    if (this.userForm.valid) {
      this.loadingService.setLoading();
      if (this.id) {
        this.save();
      } else {
        this.create();
      }
    }
  }

  private create() {
    const data = this.userForm.value;
    data.status = data.status.value;
    this.httpService.createUser(data)
      .subscribe((res: User) => {
        this.sendAcl(res.userId);
      });
  }

  private save() {
    const value = this.userForm.value;
    if (!this.userForm.value.password) {
      delete value.password;
    }
    value.status = value.status.value;
    this.httpService.updateUser(value, this.id)
      .subscribe((res) => {
        this.sendAcl(this.id);
      });
  }

  getErrorStatus(column: string): boolean {
    const controls = this.userForm.get(column);
    return (this.isSubmit || controls.touched) && !controls.valid;
  }


  getArchive() {
    this.httpService.getArchive({ all: true }).subscribe((archiveResponse: ArchiveResponse) => {
      archiveResponse.list.forEach(this.createFormGroup.bind(this));
    });
  }

  private getUserData(userId: number) {
    this.httpService.getUserList({ userId })
      .subscribe((res: UserResponse) => {
        const userInfo = res.list[0];
        const status = this.statusOption.find(statusItem => userInfo.status.value === statusItem.value);
        this.userForm.patchValue({
          displayName: userInfo.displayName,
          username: userInfo.username,
          email: userInfo.email,
          status,
        });
        this.getAcl(this.id);
      });
  }

  private getAcl(userId: number) {
    const getAcl = this.httpService.getAcl(userId);
    const getArchive = this.httpService.getArchive({ all: true });
    combineLatest([getAcl, getArchive]).subscribe(([aclResponse, archiveResponse]) => {
      archiveResponse.list.forEach((archiveItem: Archive) => {
        const acl = aclResponse.list.find((item) => item.archiveId === archiveItem.archiveId);
        this.createFormGroup(archiveItem, acl);
      });
    });
  }

  private createFormGroup(archive: Archive, acl?: Acl) {
    const formGroup = this.formBuilder.group({
      archiveId: archive.archiveId,
      archiveName: archive.archiveName,
      canRead: !!acl && !!acl.canRead,
      canWrite: !!acl && !!acl.canWrite,
    });
    const aclForm = this.userForm.controls.aclFrom as FormArray;
    aclForm.push(formGroup);
  }

  private sendAcl(userId: number) {
    const aclForm = this.userForm.controls.aclFrom as FormArray;
    this.httpService.createAcl(aclForm.value, userId)
      .subscribe((res) => {
        this.reloadService.setReload();
        this.loadingService.stopLoading();
        this.close();
      });
  }

  close() {
    this.data.close();
  }

  resend() {
    this.httpService.resendEmail(this.id).subscribe((password) => {
      const data: Alert = {
        title: 'Resend Success',
        message: 'New Password : ' + password
      };
      this.alertService.setAlert(data);
    });
  }
}
