import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpService } from 'src/app/core/http/http.service';
import { ExamOverlay, ExamResponse } from 'src/app/type/exam';
import { ExamSeriesItem } from 'src/app/core/service/exam/exam.service';

interface Params {
  key: string;
  seriesId: string;
}

@Component({
  selector: 'app-share-content',
  templateUrl: './share-content.component.html',
  styleUrls: ['./share-content.component.scss'],
})
export class ShareContentComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
  ) { }
  seriesList: ExamSeriesItem[] = [];
  showSeries: string;
  seriesType: string;
  overlayList: string[];
  key: string;
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.key) {
        this.httpService.getShareExam(params.key).subscribe((response: ExamResponse) => {
          this.seriesList = response.seriesList
            .filter(item => item.seriesId === params.seriesId)
            .map((item): ExamSeriesItem => ({
              ...item,
              img: environment.apiUrl + 'shared/exams/' + params.key + '/overlays/' + item.thumbnail
            }));
          this.key = params.key;
          this.getOverlay(params.key, params.seriesId);
        });
      }
    });
  }

  getOverlay(key: string, seriesId: string) {
    this.showSeries = seriesId;
    this.httpService.getShareExamOverlay(key, seriesId).subscribe((res) => {
      this.seriesType = res.imageType;
      this.overlayList = res.overlayList
        .map((item: ExamOverlay) => environment.apiUrl + 'shared/exams/' + key + '/overlays/' + item.path);
      this.overlayList.unshift(environment.apiUrl + 'shared/exams/' + key + '/overlays/' + res.seriesPath);
    });
  }
}
