import { Component, OnInit } from '@angular/core';
import { PopupService } from 'src/app/component/popup-window/popup.service';
import { ModelCreatePopupComponent } from 'src/app/popup/model-create-popup/model-create-popup.component';

@Component({
  selector: 'app-create-firmware-button',
  templateUrl: './create-firmware-button.component.html',
  styleUrls: ['./create-firmware-button.component.scss']
})
export class CreateFirmwareButtonComponent implements OnInit {

  constructor(
    private popupService: PopupService,
  ) { }

  ngOnInit() {
  }

  openCreatePopup() {
    this.popupService.open(
      ModelCreatePopupComponent, {}
    );
  }
}
