import { Component, Input, OnInit } from '@angular/core';
import { Popup } from 'src/app/component/popup-window/popup.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { Archive, ArchiveStatus } from 'src/app/type/archive';
import { ReloadServiceService } from 'src/app/core/service/reload-service/reload-service.service';
import { SelectOption } from '../../component/select/select.component';

@Component({
  selector: 'app-archive-create-popup',
  templateUrl: './archive-create-popup.component.html',
  styleUrls: ['./archive-create-popup.component.scss']
})
export class ArchiveCreatePopupComponent implements OnInit, Popup {

  @Input() data;
  constructor(
    private formBuild: FormBuilder,
    private httpService: HttpService,
    private reloadService: ReloadServiceService,
  ) { }

  isSubmit = false;
  readonly statusOption: SelectOption[] = [
    { text: ArchiveStatus.Enable.text, value: ArchiveStatus.Enable.value },
    { text: ArchiveStatus.Disable.text, value: ArchiveStatus.Disable.value },
  ];
  archiveForm = this.formBuild.group({
    archiveName: ['', Validators.required],
    description: [''],
    status: [this.statusOption[0], Validators.required],
  });

  id: number;
  isEdit: boolean;

  ngOnInit() {
    this.id = this.data.id;
    this.isEdit = this.data.edit;
    if (this.id) {
      this.getArchive();
    }
  }

  send() {
    this.isSubmit = true;
    if (this.archiveForm.valid) {
      if (!this.id) {
        this.create();
      } else {
        this.edit();
      }
    }
  }

  create() {
    const value = this.archiveForm.value;
    value.status = value.status.value;
    this.httpService.createArchive(value)
      .subscribe((res) => {
        this.reloadService.setReload();
        this.close();
      });
  }

  edit() {
    const value = this.archiveForm.value;
    value.status = value.status.value;
    this.httpService.updateArchive(value, this.id)
      .subscribe((res) => {
        this.reloadService.setReload();
        this.close();
      });
  }

  getErrorStatus(column: string): boolean {
    const controls = this.archiveForm.get(column);
    return (this.isSubmit || controls.touched) && !controls.valid;
  }

  close() {
    this.data.close();
  }

  getArchive() {
    this.httpService.getArchiveById(this.id).subscribe((res: Archive) => {
      const status = this.statusOption.find(option => option.value === res.status.value);
      this.archiveForm.patchValue({
        archiveName: res.archiveName,
        description: res.description,
        status,
      });
    });
  }
}
