import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SwitchButtonComponent),
  multi: true
};

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss'],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR],
})
export class SwitchButtonComponent implements ControlValueAccessor {
  myValue: boolean;
  onChange: (value: boolean) => {};
  onTouched: (e) => {};
  @Input() disable = false;
  @Output() change = new EventEmitter<boolean>();
  constructor() { }

  get value() {
    return this.myValue;
  }

  set value(value: boolean) {
    if (this.disable === false) {
      this.myValue = value;
      this.notifyValueChange(value);
    }
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
      this.change.emit(value);
    }
  }

  toggle() {
    this.value = !this.value;
  }

  writeValue(value: any): void {
    this.myValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
