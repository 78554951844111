import { Component, forwardRef, HostListener, Input, OnChanges, SimpleChanges, HostBinding, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, SelectControlValueAccessor } from '@angular/forms';

export interface SelectOption {
  text: string;
  value: any;
}

export const SELECT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true
};

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [SELECT_CONTROL_VALUE_ACCESSOR]
})
export class SelectComponent extends SelectControlValueAccessor implements OnChanges {
  static EmptyOption = [{ text: 'Empty', value: '' }];
  @Input() optionList: SelectOption[];
  @Input() error?: string;
  @Output() change = new EventEmitter();
  onChange: (value) => void;
  onTouched: () => void;
  isOpen = false;
  value: SelectOption;

  @HostListener('click', ['$event']) onblur(e: MouseEvent) {
    this.onTouched();
  }
  @HostListener('document:click', ['$event']) checkIsOpen() {
    if (this.isOpen) {
      this.isOpen = false;
    }
  }
  @HostBinding('class.error')
  get getError() {
    return this.error;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.optionList && changes.optionList.currentValue && changes.optionList.currentValue.length) {
      this.value = this.optionList.find((item: SelectOption) => item.value === this.value);
    }
  }

  openOption(event: MouseEvent) {
    event.stopPropagation();
    this.isOpen = true;
  }

  changeSelect(event: MouseEvent, option: SelectOption) {
    event.stopPropagation();
    this.writeValue(option.value);
    this.onChange(option.value);
    this.change.emit(option);
    this.isOpen = false;
  }

  writeValue(value: any): void {
    if (this.optionList.length) {
      this.value = this.optionList.find((item: SelectOption) => item.value === value);
    } else {
      this.value = value;
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
