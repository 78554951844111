import { Component, OnInit } from '@angular/core';
import { Archive } from 'src/app/type/archive';
import { ExamService } from 'src/app/core/service/exam/exam.service';
import { SearchHistoryService } from 'src/app/core/service/search-history/search-history.service';
import { RouterConfig } from 'src/app/router/router-config';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-archive-menu',
  templateUrl: './archive-menu.component.html',
  styleUrls: ['./archive-menu.component.scss']
})
export class ArchiveMenuComponent implements OnInit {
  constructor(
    private examService: ExamService,
    private searchHistoryService: SearchHistoryService,
    private router: Router,
  ) { }
  archiveList: Archive[];
  selectArchive: Archive;
  routerConfig = RouterConfig;
  isOpenArchive = false;

  ngOnInit() {
    this.examService.getArchiveList(false)
      .subscribe((archiveList: Archive[]) => {
        this.archiveList = archiveList;
      });
    this.examService.getSelectArchive()
      .subscribe((selectArchive: Archive) => {
        this.selectArchive = selectArchive;
      });
  }

  changeArchive(archive: Archive) {
    this.examService.setSelectArchive(archive);
  }

  toggleArchive() {
    this.isOpenArchive = !this.isOpenArchive;
    this.examService.setSelectArchive(undefined);
  }
}
