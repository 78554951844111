import { Component, } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { routeAnimation } from '../../../router/router-animation';
import { fadeInOutAnimation } from 'src/app/animation/common';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    routeAnimation,
    fadeInOutAnimation
  ]
})
export class MainLayoutComponent {

  // router跳转动画所需参数
  routerState = true;
  routerStateCode = 'active';
  lastUrl: string;
  showCustomMenu = false;
  customMenu;
  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/login' && this.lastUrl !== '/login') {
          this.setRouterState();
        }
        this.lastUrl = event.url;
      } else if (event instanceof ActivationEnd && event.snapshot.children.length === 0) {
        // 判斷是否為子路由 避免取到MainLayoutComponent)
        if (event.snapshot.data.menu) {
          this.customMenu = event.snapshot.data.menu;
          this.showCustomMenu = true;
        } else {
          this.customMenu = undefined;
          this.showCustomMenu = false;
        }
      }
    });
  }

  setRouterState() {
    this.routerState = !this.routerState;
    this.routerStateCode = this.routerState ? 'active' : 'inactive';
  }
}
