import { Component, OnInit, Input } from '@angular/core';
import { Popup } from '../../component/popup-window/popup.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.scss']
})
export class EmailPopupComponent implements OnInit, Popup {

  constructor(
    private formBuilder: FormBuilder,
  ) { }
  @Input() data;
  form = this.formBuilder.group({
    from: ['', [Validators.required, Validators.email]],
    to: ['', [Validators.required, Validators.email]],
  });

  ngOnInit() {
  }

  send() {
    if (this.form.valid) {
      this.data.send(this.form.value.from, this.form.value.to);
    }
  }

  close() {
    this.data.close();
  }
}
