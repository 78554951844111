import { Page } from '../component/pagination/pagination.component';
import { Dayjs } from 'dayjs';
import { User } from './user';

export interface Feedback {
  content: string;
  createdAt: Dayjs;
  id: number;
  type: FeedbackType;
  user: User;
  userId: number;
  serialNumber?: string;
  organizationName?: string;
}

export interface FeedbackResponse {
  list: Feedback[];
  page: Page;
}

export class FeedbackType {
  static Feedback = new FeedbackType(0, 'Feedback');
  static BugReport = new FeedbackType(1, 'Report bug');
  static HelpRequest = new FeedbackType(2, 'Request help');
  static Other = new FeedbackType(3, 'Other');
  static list = [
    FeedbackType.Feedback,
    FeedbackType.BugReport,
    FeedbackType.HelpRequest,
    FeedbackType.Other,
  ];

  static findByCode(code: number | string) {
    return FeedbackType.list.find((item: FeedbackType) => item.value === +code);
  }

  constructor(readonly value: number, readonly text: string) { }
}
