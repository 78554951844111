import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelStatusService {

  private listSubject = new Subject();
  private currentModel = new BehaviorSubject<number | undefined>(undefined);
  private firmwareListSubject = new Subject();
  hasReloadFirmware = this.firmwareListSubject.asObservable();
  constructor() { }

  hasReloadList(): Observable<any> {
    return this.listSubject.asObservable();
  }

  setReloadList() {
    this.listSubject.next();
  }

  getCurrentModelId(): Observable<number> {
    return this.currentModel.asObservable();
  }

  setCurrentModelId(modelId?: number) {
    this.currentModel.next(modelId);
  }

  setFirmwareReload() {
    this.firmwareListSubject.next();
  }
}
