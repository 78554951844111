import { Component, OnInit, Input, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioInputComponent),
  multi: true
};

export interface Radio {
  text: string;
  value: any;
}

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioInputComponent implements ControlValueAccessor {

  @Input() multiple: boolean;
  @Input() optionList: Radio[];
  @Input() optionStyle;
  @Input() disabled: boolean;

  value: Radio;
  onChange: (value: Radio) => void;
  onTouch: () => void;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  select(value: Radio) {
    if (value && !this.disabled) {
      this.value = value;
      this.onChange(value);
      this.changeDetectorRef.markForCheck();
    }
  }

  writeValue(obj: Radio): void {
    if (obj) {
      this.value = obj;
      this.changeDetectorRef.markForCheck();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
