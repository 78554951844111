import { Component, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextareaComponent),
  multi: true
};

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaComponent implements ControlValueAccessor {
  @Input() placeholder?: string;
  @Input() cols?: number;
  @Input() rows?: number;
  @Input() disabled?: boolean;
  onChange: (value: string) => void;
  onTouch: () => void;
  myValue: string;
  set value(value: string) {
    this.myValue = value;
    if (this.onChange) {
      this.onChange(this.myValue);
    }
    this.changeDetectorRef.markForCheck();
  }

  get value(): string {
    return this.myValue;
  }
  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  writeValue(obj: any): void {
    this.myValue = obj;
    this.changeDetectorRef.markForCheck();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

}
