import { Component, OnInit } from '@angular/core';
import { ExamService } from 'src/app/core/service/exam/exam.service';
import { Archive } from 'src/app/type/archive';
import { SearchHistoryService } from 'src/app/core/service/search-history/search-history.service';

@Component({
  selector: 'app-exam-menu',
  templateUrl: './exam-menu.component.html',
  styleUrls: ['./exam-menu.component.scss']
})
export class ExamMenuComponent implements OnInit {
  archiveList: Archive[];
  selectArchive: Archive;
  constructor(
    private examService: ExamService,
    private searchHistoryService: SearchHistoryService,
  ) { }

  ngOnInit() {
    const searchHistory = this.searchHistoryService.getParam();
    if (searchHistory && searchHistory.archiveId) {
      this.examService.setSelectArchiveById(searchHistory.archiveId);
    }
    this.examService.getArchiveList(true)
      .subscribe((archiveList: Archive[]) => {
        this.archiveList = archiveList;
      });
    this.examService.getSelectArchive()
      .subscribe((selectArchive: Archive) => {
        this.selectArchive = selectArchive;
      });
  }

  changeArchive(archive: Archive) {
    this.examService.setSelectArchive(archive);
  }

}
