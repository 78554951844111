import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExamService, PatientInfo } from 'src/app/core/service/exam/exam.service';
import { ExamSeries, ExamProbe } from 'src/app/type/exam';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-exam-detail-menu',
  templateUrl: './exam-detail-menu.component.html',
  styleUrls: ['./exam-detail-menu.component.scss']
})
export class ExamDetailMenuComponent implements OnInit, OnDestroy {
  patientInfo: PatientInfo;
  seriesDate = '';
  physician = '';
  probe: ExamProbe;

  private getPatientSubscription: Subscription;
  private getSeriesSubscription: Subscription;
  constructor(
    private examService: ExamService,
  ) {
    this.getPatientSubscription = examService.getPatient().subscribe((patientInfo: PatientInfo) => {
      this.patientInfo = patientInfo;
      this.probe = patientInfo.probe;
    });
    this.getSeriesSubscription = examService.getSeries().subscribe((res: ExamSeries) => {
      this.seriesDate = res ? res.seriesDateTime.format(environment.dateTime) : '-';
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.getPatientSubscription.unsubscribe();
    this.getSeriesSubscription.unsubscribe();
  }
}
